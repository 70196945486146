<template>
  <Teleport to="#app">
    <!--The fp modal class doesn't apply any styling, but is used to identify the modal and add/remove the no scroll styling on the body depending on it's presence-->
    <div class="fp-modal">
      <div
        :class="active ? 'tw-bg-modalBackdrop' : 'tw-bg-transparent'"
        class="tw-fixed tw-z-[960] tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-backface-hidden tw-transition-colors tw-padding-4 tw-overflow-x-hidden tw-overflow-y-auto"
        @click.self="close"
      >
        <div
          class="tw-relative tw-width-auto tw-m-4 tw-flex tw-items-center tw-justify-center tw-min-h-[calc(100%-2rem)] tw-pointer-events-none"
        >
          <FocusTrap :active="active">
            <div
              :class="[
                active ? 'tw-translate-y-0 tw-opacity-100' : 'tw-translate-y-[-2rem] tw-opacity-0',
                size === 'small' ? 'tw-w-[480px]' : 'tw-w-[800px]',
              ]"
              role="dialog"
              :aria-modal="true"
              :tabindex="-1"
              class="tw-pointer-events-auto tw-transition-all tw-relative tw-flex tw-flex-col tw-p-6 tw-rounded-2xl tw-bg-light"
            >
              <FpHeadingWithIconAndSubline
                v-if="title"
                class="tw-mb-4"
                icon="contract"
              >
                {{ title }}
              </FpHeadingWithIconAndSubline>
              <div class="tw-flex-grow">
                <slot name="body-and-footer">
                  <div>
                    <slot />
                  </div>

                  <div class="tw-mt-6">
                    <slot name="footer">
                      <div class="tw-flex tw-justify-end">
                        <FpButton
                          accent="grey"
                          @click="close"
                        >
                          {{ $t('global.close') }}
                        </FpButton>
                      </div>
                    </slot>
                  </div>
                </slot>
              </div>
            </div>
          </FocusTrap>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { FocusTrap } from 'focus-trap-vue'
import { onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue'
import FpButton from '@/components/FpButton.vue'
import FpHeadingWithIconAndSubline from '@/components/FpHeadingWithIconAndSubline.vue'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large'
    title?: string
    persistent?: boolean
  }>(),
  {
    size: 'small',
    title: undefined,
  },
)

const emit = defineEmits<{
  close: []
}>()

const active = ref(false)

const body = document.querySelector('body')
body?.classList.add('no-scroll')
setTimeout(() => {
  active.value = true
}, 1)

const closeOnEscape = ($event: KeyboardEvent) => {
  if ($event.key === 'Escape' && !props.persistent) {
    close()
  }
}

onMounted(() => {
  document.addEventListener('keyup', closeOnEscape)
})

onBeforeUnmount(() => {
  document.removeEventListener('keyup', closeOnEscape)
})

onUnmounted(() => {
  if (!document.querySelector('.fp-modal')) {
    const body = document.querySelector('body')
    body?.classList.remove('no-scroll')
  }

  active.value = false
})
const close = () => {
  if (!props.persistent) {
    active.value = false
    setTimeout(() => {
      emit('close')
    }, 150)
  }
}
</script>
