<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    class="svg"
    viewBox="0 0 24 28"
  >
    <g>
      <g id="Group_890_00000130623461553145827230000006726599900917322422_">
        <g
          id="Group_888_00000012468025772039802750000016357860356242267564_"
          transform="translate(0 6.248)"
        >
          <path
            id="Path_4661_00000085230750002745982480000012219377833841152920_"
            fill="none"
            stroke="#1D1D1B"
            stroke-miterlimit="10"
            class="svg-1"
            d="M8.1-0.1L1.7,1.3l7,19.9
				c0,0,11.1-1,12.1-3.7c0-0.1,0.1-0.3,0.1-0.5"
          />
        </g>
        <g
          id="Group_889_00000096739900177364401150000000425158327222855608_"
          transform="translate(7.499)"
        >
          <path
            id="Path_4662_00000152970822287781801920000017140976079410975118_"
            class="svg-1"
            fill="none"
            stroke="#1D1D1B"
            stroke-miterlimit="10"
            d="M1.4,0.5l-2.7,18.9
				c0,0,9.5,0.7,14.2,3.2c0.2,0.1,0.5,0.2,0.5,0.7v-1.1c0-1.3,0.1-17,0-18c0-0.8,0.1-1.5-1.4-2.2C9,0.5,1.4,0.5,1.4,0.5z"
          />
        </g>
      </g>
      <g>
        <line
          id="Line_146_00000062157334687029735310000011059671429851509166_"
          class="svg-1"
          x1="14.2"
          y1="16.4"
          x2="14.2"
          y2="6.5"
          fill="none"
          stroke="#1D1D1B"
          stroke-miterlimit="10"
        />
        <polyline
          class="svg-1"
          points="10.2,9.6 14.2,6.3 18.2,9.6"
          fill="none"
          stroke="#1D1D1B"
          stroke-miterlimit="10"
        />
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts"></script>
<style lang="scss">
.st0 {
  fill: none;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
</style>
