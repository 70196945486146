<template>
  <div>
    <div
      class="fp-frontpage-wrap"
      style="background-color: rgb(255, 255, 255)"
    >
      <FpContainer class="tw-flex tw-flex-col tw-items-center md:tw-flex-row tw-pb-14 tw-gap-4">
        <div class="tw-w-full md:tw-w-1/2">
          <h2 class="fp-frontpage__option-headline tw-mt-14 tw-mb-5">
            {{ $t('frontpage.hero.heading') }}
          </h2>
          <p class="fp-frontpage__option-text">
            {{ $t('frontpage.hero.description') }}
          </p>
        </div>
        <div class="tw-w-full md:tw-w-1/2">
          <img
            class="fp-twotextleft__img-img"
            src="../img/frontpage-hero.png"
            alt=""
          />
        </div>
      </FpContainer>
    </div>

    <div
      class="fp-frontpage-wrap"
      style="background-color: rgb(245, 245, 245)"
    >
      <FpContainer class="tw-flex tw-flex-col tw-items-center lg:tw-flex-row tw-pb-14 tw-gap-4">
        <div class="fp-frontpage__option">
          <div class="fp-frontpage__list">
            <h3 class="fp-frontpage__list-headline">
              {{ $t('frontpage.howDoesItWork.heading') }}
            </h3>
            <div class="tw-flex tw-flex-col tw-items-center">
              <ColoredStrokeStep
                v-for="(step, index) in howDoesItWorkSteps"
                :key="index"
                :index="index"
                :color="step.color"
                :heading="step.heading"
                :body="step.body"
              >
                <template #icon>
                  <Frontpage2 v-if="index === 0" />
                  <Frontpage1 v-if="index === 1 || index === 3" />
                  <Frontpage3 v-if="index === 2" />
                  <Frontpage4 v-if="index === 4" />
                </template>
              </ColoredStrokeStep>
            </div>
          </div>
        </div>
        <div class="fp-frontpage__option">
          <div class="embed-container">
            <iframe
              src="https://player.vimeo.com/video/799446053?dnt=1"
              frameborder="0"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            />
          </div>
        </div>
      </FpContainer>
    </div>

    <div class="tw-w-full tw-bg-light">
      <FpContainer class="tw-flex tw-flex-col tw-items-center lg:tw-flex-row tw-pb-36 lg:tw-pb-14 tw-gap-4">
        <div class="tw-full lg:tw-w-1/2 tw-my-36">
          <h2 class="fp-frontpage__option-headline tw-pt-0 tw-mb-5">
            {{ $t('frontpage.tryIt.heading') }}
          </h2>
          <p class="fp-frontpage__option-text">
            {{ $t('frontpage.tryIt.description') }}
          </p>
          <FpButton
            class="tw-mt-5"
            @click="() => goToRegister()"
          >
            {{ $t('frontpage.tryIt.button') }}
          </FpButton>
        </div>
        <div class="tw-w-full lg:tw-w-1/2 tw-h-full">
          <div class="tw-flex tw-items-center tw-justify-center tw-h-full tw-relative">
            <img
              v-if="locale === 'da'"
              class="tw-w-[80%] tw-z-10"
              src="../img/try-it-image-da.jpg"
              alt=""
            />
            <img
              v-if="locale === 'en'"
              class="tw-w-[80%] tw-z-10"
              src="../img/try-it-image-en.jpg"
              alt=""
            />
            <div class="tw-absolute tw-h-full tw-w-full tw-left-0 tw-top-0 tw-flex tw-items-center tw-justify-center">
              <TryItImageBackground class="tw-w-[65%]" />
            </div>
          </div>
        </div>
      </FpContainer>
    </div>

    <div class="fp-treetext__wrap">
      <p class="fp-treetext__headline">
        {{ $t('frontpage.faqSection.heading') }}
      </p>
      <div
        class="tw-flex tw-flex-col tw-items-center md:tw-items-stretch md:tw-flex-row tw-gap-[15px] tw-pt-5 tw-pb-16"
      >
        <div
          v-for="question in 3"
          :key="question"
          class="tw-bg-light tw-pb-6 tw-flex-1"
        >
          <component :is="question === 1 ? Leaf1 : question === 2 ? Leaf2 : Leaf3" />
          <div class="tw-px-7">
            <h3 class="tw-text-2xl tw-mb-2">
              {{ $t(`frontpage.faqSection.questions.${question}.question`) }}
            </h3>
            <p>
              {{ $t(`frontpage.faqSection.questions.${question}.answer`) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <PriceSection />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ColoredStrokeStep from '@/components/ColoredStrokeStep.vue'
import type { ColoredStrokeStepProps } from '@/components/ColoredStrokeStep.vue'
import FpButton from '@/components/FpButton.vue'
import FpContainer from '@/components/FpContainer.vue'
import PriceSection from '@/components/PriceSection.vue'
import { goToRegister } from '@/services/OAuthService'
import Frontpage1 from '@/svg/Frontpage1.vue'
import Frontpage2 from '@/svg/Frontpage2.vue'
import Frontpage3 from '@/svg/Frontpage3.vue'
import Frontpage4 from '@/svg/Frontpage4.vue'
import Leaf1 from '@/svg/Leaf1.vue'
import Leaf2 from '@/svg/Leaf2.vue'
import Leaf3 from '@/svg/Leaf3.vue'
import TryItImageBackground from '@/svg/TryItImageBackground.vue'

const { t, locale } = useI18n()

const howDoesItWorkSteps = computed<Omit<ColoredStrokeStepProps, 'index'>[]>(() => {
  return [
    {
      heading: t('frontpage.howDoesItWork.steps.1.heading'),
      body: t('frontpage.howDoesItWork.steps.1.body'),
      color: 'green',
    },
    {
      heading: t('frontpage.howDoesItWork.steps.2.heading'),
      body: t('frontpage.howDoesItWork.steps.2.body'),
      color: 'blue',
    },
    {
      heading: t('frontpage.howDoesItWork.steps.3.heading'),
      body: t('frontpage.howDoesItWork.steps.3.body'),
      color: 'blue',
    },
    {
      heading: t('frontpage.howDoesItWork.steps.4.heading'),
      body: t('frontpage.howDoesItWork.steps.4.body'),
      color: 'blue',
    },
    {
      heading: t('frontpage.howDoesItWork.steps.5.heading'),
      body: t('frontpage.howDoesItWork.steps.5.body'),
      color: 'green',
    },
  ]
})
</script>

<style lang="scss">
@import '@/scss/theme.scss';

.fp-frontpage {
  position: relative;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &-wrap {
    width: 100%;
    height: auto;
  }

  &__list {
    padding-bottom: 40px;

    &-headline {
      position: relative;
      width: 440px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      padding-top: 30px;
      padding-bottom: 10px;
      font-size: 30px;
      font-weight: 400;
      line-height: 50px;
      text-transform: uppercase;
      color: $dark;
    }
  }

  &__option {
    position: relative;
    height: auto;
    width: 100%;
    min-height: 400px;
    overflow: hidden;

    .embed-container {
      height: 80%;
      overflow: hidden;
      max-width: 100%;
      margin-top: 10%;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .svg {
      position: absolute;
      top: 50%;
      right: 50%;
      width: 400px;
      margin-right: -250px;
      margin-top: -210px;
    }

    &-headline {
      font-size: 30px;
      font-weight: 300;
      line-height: 30px;
      text-transform: uppercase;
      color: $dark;
    }

    &-text {
      line-height: 30px;
      font-weight: 300;
      max-width: 480px;
      white-space: pre-wrap;
    }

    &-btn {
      display: inline-block;
      background-color: #457fee;
      color: #ffffff;
      margin-left: 80px;
      margin-top: 26px;
      font-weight: 500;
    }

    &-btn:hover {
      background-color: #4a85f6;
    }
  }
}

.fp-frontpage.fp-frontpage-r {
  .svg {
    right: auto !important;
    left: 50%;
    margin-right: auto !important;
    margin-left: -250px;
  }
}

@media only screen and (min-width: 960px) {
  .fp-wrapper .fp-frontpage {
    flex-wrap: nowrap;

    &__option {
      width: 50%;
      min-height: 600px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .fp-twotextleft {
    flex-wrap: wrap;

    &__option {
      width: 100%;
    }

    &__img {
      width: 100%;
    }
  }

  .fp-twotextleft__img-div {
    min-height: 0;
  }

  .fp-frontpage__comp {
    width: 400px;
    height: 340px;
    margin-top: -180px;
    margin-left: -290px;
  }

  .fp-frontpage__letter {
    width: 500px;
    height: 250px;
    margin-top: -80px;
    margin-left: -210px;
  }

  .fp-frontpage__option-headline {
    font-size: 26px;
    font-weight: 300;
    line-height: 30px;
  }

  .fp-frontpage__option-text {
    line-height: 30px;
    font-weight: 300;
  }

  .fp-dashboard {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fp-treetext {
    &__option {
      width: 100%;
    }
  }

  .fp-twotextequal {
    flex-wrap: wrap;

    &__option {
      width: 100%;
    }

    &__img {
      width: 100%;
    }
  }
}

.fp-treetext {
  position: relative;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 60px;

  .svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  &__wrap {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5f5f5;
  }

  &__headline {
    line-height: 30px;
    font-size: 30px;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px;
    text-transform: uppercase;
  }

  &__option {
    position: relative;
    width: 33.33%;
    height: auto;

    &-headline {
      font-size: 20px;
      font-weight: 300;
      line-height: 42px;
      padding-left: 30px;
      padding-right: 30px;
      text-transform: uppercase;
      padding-top: 10px;
      color: $dark;
      white-space: pre-wrap;
    }

    &-text {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      color: $dark;
      white-space: pre-wrap;
    }
  }
}

.fp-treetext__option {
  padding-bottom: 30px;
  background-color: #ffffff;

  &-headline {
  }
}
</style>
