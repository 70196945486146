import { i18n } from '@/lang'

const getFormatter = (
  locales: string | string[] | undefined,
  fractionDigits: number,
  options: Intl.NumberFormatOptions = {},
) => {
  return new Intl.NumberFormat(locales, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    ...options,
  })
}

const defaultLocale = i18n.global.locale.value.replace('_', '-')

// Store formatters as they are expensive to create
const numberFormatters: Record<string, Intl.NumberFormat> = {}
const currencyFormatters: Record<string, Intl.NumberFormat> = {}
export const numberFormatter = (fractionDigits: number = 2, locale: string = defaultLocale) => {
  const identifier = `${fractionDigits}_${locale}`
  if (!numberFormatters[identifier]) {
    // Create a new formatter
    numberFormatters[identifier] = getFormatter(locale, fractionDigits)
  }
  return numberFormatters[identifier]
}

export const currencyFormatter = (
  currency: string,
  options: {
    currencyDisplay?: string
  } = {},
  locale: string = defaultLocale,
) => {
  // Check if we already have a formatter for this currency
  const identifier = `${currency}_${locale}`
  if (!currencyFormatters[identifier]) {
    // Create a new formatter
    currencyFormatters[identifier] = getFormatter(locale, 2, {
      style: 'currency',
      currency,
      ...options,
    })
  }
  return currencyFormatters[identifier]
}
