<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="6.06 9.12 32.36 27.82"
  >
    <g>
      <g>
        <g>
          <polyline
            class="st0"
            points="27.52187,15.94138 27.52187,9.62176 11.49895,9.62176 6.5625,14.59375 6.5625,31.44078      6.5625,33.44656 27.52187,33.44656 27.52187,30.04042    "
          />
          <polyline
            class="st1"
            points="11.49895,9.62176 11.49895,14.59375 6.5625,14.59375    "
          />
        </g>
        <g>
          <polyline
            class="st2"
            points="27.52187,15.65131 27.52187,9.62176 11.49895,9.62176 6.5625,14.59375 6.5625,31.44078      6.5625,33.44656 27.52187,33.44656 27.52187,30.04042    "
          />
          <polyline
            class="st2"
            points="11.49895,9.62176 11.49895,14.59375 6.5625,14.59375    "
          />
        </g>
      </g>
      <g>
        <g>
          <polyline
            class="st0"
            points="30.5625,15.27975 30.5625,12.5 27.52187,12.5    "
          />
          <polyline
            class="st0"
            points="9.54167,33.88919 9.54167,36.4375 30.5625,36.4375 30.5625,30.45562    "
          />
        </g>
        <g>
          <polyline
            class="st2"
            points="30.5625,15.27975 30.5625,12.5 27.52187,12.5    "
          />
          <polyline
            class="st2"
            points="9.54167,33.88919 9.54167,36.4375 30.5625,36.4375 30.5625,30.45562    "
          />
        </g>
      </g>
      <line
        class="st3"
        x1="10.02083"
        y1="17.42743"
        x2="21.88662"
        y2="17.42743"
      />
      <line
        class="st3"
        x1="10.02083"
        y1="19.54167"
        x2="18.97917"
        y2="19.54167"
      />
      <line
        class="st3"
        x1="10.02083"
        y1="21.47917"
        x2="20.97917"
        y2="21.47917"
      />
      <line
        class="st3"
        x1="10.02083"
        y1="23.47917"
        x2="20.01041"
        y2="23.47917"
      />
      <line
        class="st3"
        x1="10.02083"
        y1="25.47917"
        x2="18.97917"
        y2="25.47917"
      />
      <line
        class="st3"
        x1="10.02083"
        y1="27.5"
        x2="15.01562"
        y2="27.5"
      />
      <g>
        <circle
          class="st3"
          cx="30.33005"
          cy="22.86768"
          r="7.58794"
        />
        <g>
          <line
            class="st3"
            x1="34.35385"
            y1="18.84389"
            x2="26.30625"
            y2="26.89148"
          />
          <line
            class="st3"
            x1="26.30625"
            y1="18.84389"
            x2="34.35385"
            y2="26.89148"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.59896;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.60114;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}
</style>
