<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #cbddeb"
        />
        <path
          d="M23.2 21.9c0 2.1-1.4 3.8-3.2 3.8s-3.2-1.7-3.2-3.8.7-3.9 3.2-3.9c2.4 0 3.2 1.8 3.2 3.9z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M13.6 39.4V32c0-2.2-.2-3.8 1-4.9 1-.8 4.1-1.6 5.4-1.6M23.5 37.9l.3-6.8M16.6 37.9l-.4-6.8M20 25.7c1.3 0 4.4.8 5.4 1.6 1.3 1 1 2.6 1 4.9v7.4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <ellipse
          cx="11.8"
          cy="22"
          rx="2.5"
          ry="3"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="m7 36.8-.3-6.6c0-1.8-.2-3.1.8-3.9.8-.6 3.2-1.3 4.3-1.3"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M11.7 25c1.1 0 3.5.6 4.3 1.3l.2.2"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <ellipse
          cx="28.3"
          cy="22"
          rx="2.5"
          ry="3"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M23.7 26.4c.8-.6 3.5-1.4 4.5-1.4M28.2 25c1.1 0 3.5.6 4.3 1.3 1 .8.8 2.1.8 3.9l-.3 6.6M17.2 19.8c-3.6-1.2-6.1-4.5-6.1-8.5 0-4.9 4-9 9-9s9 4 9 9c0 4-2.6 7.3-6.1 8.5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="20"
          cy="11.3"
          r="2.4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M20 8.9c-1.3 0-2.4 1.1-2.4 2.4M20 13.6c1.3 0 2.4-1.1 2.4-2.4M20 18c3.7 0 6.8-3 6.8-6.8M20 4.5c-3.7 0-6.8 3-6.8 6.8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M20 15.8c2.5 0 4.6-2 4.6-4.6M20 6.7c-2.5 0-4.6 2-4.6 4.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
