<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #cbddeb"
        />
        <path
          d="m35.6 12.5-21.1 3c-.1 1.4-1 2.6-2.3 3.1l1 7.3c1.6.1 2.9 1.2 3.3 2.8l20.9-2.9c-.1-1.6.9-3 2.3-3.6l-1-7.4c-1.5-.2-2.6-1.1-3.1-2.3zM20.8 14.5l.3 2"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="m21.5 19.6.7 4.6"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 0.8;
            stroke-linecap: round;
            stroke-miterlimit: 10;
            stroke-dasharray: 3.1194, 3.1194;
          "
        />
        <path
          d="m22.4 25.8.3 2"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="M23.5 15.8h9.9v4.1h-9.9z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
          transform="rotate(-7.987 28.518 17.823)"
        />
        <path
          d="m24.1 22.6 8.4-1.2M24.5 24.7l6.2-.9"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
        <path
          d="M29.5 13.3 21 .2h-2L9.4 14.5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="m33.7 12.8 1.2-6.3-1.5-1.4-17.1 4.5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M21.6 9.3 5.4 6.5 3.9 8l3.9 17M1.5 36.5v-4h37V37"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M14.1 32.5c-2.3-1.2-4.3-3.1-5.6-5.6-3-6.1-.5-13.5 5.6-16.5 5.2-2.5 11.2-1.2 14.8 3M25.3 32.5c2.3-1.2 4.1-2.9 5.2-5l.4-.8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
