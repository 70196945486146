<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1.96 8.96 41.45 27.09"
  >
    <g>
      <path
        class="st0"
        d="M14.77162,26.6657l15.51052,5.2716c0.55472,0.19209,1.13337-0.21989,1.13337-0.80693V10.31008   c0-0.58704-0.57864-0.99902-1.13337-0.80693L14.77162,14.5V26.6657z"
      />
      <path
        class="st0"
        d="M6.35191,26.51739h8.41971V14.5H6.35191c-0.43491,0-0.78748,0.62731-0.78748,1.06222v10.1677   C5.56443,26.16483,5.917,26.51739,6.35191,26.51739z"
      />
      <path
        class="st0"
        d="M2.71875,23.62407h2.84568v-5.8638H2.71875c-0.14269,0-0.25836,0.11567-0.25836,0.25836v5.34708   C2.46039,23.5084,2.57606,23.62407,2.71875,23.62407z"
      />
      <path
        class="st0"
        d="M12.26017,26.51737l1.19176,6.78395c0.18304,1.04037-0.51225,2.03124-1.55261,2.21428   c-0.11207,0.01961-0.22367,0.02895-0.3334,0.02895c-0.91102,0-1.71838-0.6528-1.88135-1.58203L8.3682,26.51744"
      />
      <g>
        <line
          class="st0"
          x1="8.62492"
          y1="23.4375"
          x2="11.68541"
          y2="23.4375"
        />
        <line
          class="st0"
          x1="8.62492"
          y1="20.48358"
          x2="11.68541"
          y2="20.48358"
        />
        <line
          class="st0"
          x1="8.62492"
          y1="17.5625"
          x2="11.68541"
          y2="17.5625"
        />
      </g>
      <path
        class="st0"
        d="M31.41607,14.73804c2.1617,1.07882,3.64643,3.3122,3.64643,5.89235c0,2.57948-1.48396,4.81237-3.64474,5.89151   "
      />
      <line
        class="st1"
        x1="38.27824"
        y1="20.54167"
        x2="42.90755"
        y2="20.54167"
      />
      <line
        class="st1"
        x1="37.93153"
        y1="25.05152"
        x2="42.50385"
        y2="25.77571"
      />
      <line
        class="st1"
        x1="37.93153"
        y1="16.24062"
        x2="42.50385"
        y2="15.51643"
      />
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
</style>
