<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2.01 9.82 40.82 25.15"
  >
    <g>
      <path
        class="st0"
        d="M33.66965,34.46875l0.22952-1.12214c0.004-0.02334-0.00373-0.04716-0.02069-0.06369l-2.55637-2.49184   c-0.0427-0.04163-0.01914-0.11415,0.03988-0.12273l3.53281-0.51335c0.02344-0.00341,0.0437-0.01813,0.05418-0.03936   l1.57992-3.20127c0.02639-0.05348,0.10265-0.05348,0.12904,0l1.57993,3.20127c0.01048,0.02123,0.03074,0.03595,0.05418,0.03936   l3.53281,0.51335c0.05902,0.00858,0.08258,0.0811,0.03988,0.12273l-2.55637,2.49184   c-0.01696,0.01653-0.0247,0.04034-0.02069,0.06369l0.22952,1.12216"
      />
      <polygon
        class="st1"
        points="42.32849,34.46875 30.81305,34.46875 31.48052,21.21319 41.66101,21.21319  "
      />
      <path
        class="st1"
        d="M32.67686,21.0963c0-2.22399,1.8029-4.02689,4.02689-4.02689c2.22399,0,4.02689,1.8029,4.02689,4.02689"
      />
      <g>
        <g>
          <g>
            <line
              class="st2"
              x1="12.60666"
              y1="24.09183"
              x2="12.60666"
              y2="16.7586"
            />
            <polyline
              class="st2"
              points="28.40625,16.7586 28.40625,34.44013 13.44944,34.44013     "
            />
            <path
              class="st3"
              d="M28.40625,19.98773l3.4068-3.83335l-4.87341-4.4871l-3.56591-1.35207      c0,1.69861-1.37699,3.0756-3.0756,3.0756c-1.69861,0-3.0756-1.37699-3.0756-3.0756l0,0l-3.74421,1.35207l-4.87341,4.4871      l3.83335,3.83335"
            />
          </g>
        </g>
      </g>
      <path
        class="st1"
        d="M20.35818,16.79901l1.5916,3.22493c0.01056,0.02139,0.03097,0.03622,0.05457,0.03965l3.55892,0.51714   c0.05945,0.00864,0.08319,0.0817,0.04017,0.12363l-2.57526,2.51026c-0.01708,0.01665-0.02488,0.04064-0.02085,0.06416   l0.60793,3.54454c0.01016,0.05921-0.05199,0.10437-0.10517,0.07641l-3.18319-1.6735c-0.02112-0.0111-0.04634-0.0111-0.06746,0   l-3.18319,1.6735c-0.05317,0.02796-0.11533-0.0172-0.10517-0.07641l0.60793-3.54454   c0.00403-0.02351-0.00376-0.0475-0.02085-0.06416l-2.57526-2.51026c-0.04302-0.04193-0.01928-0.115,0.04017-0.12363   l3.55892-0.51714c0.02361-0.00343,0.04402-0.01826,0.05457-0.03965l1.5916-3.22493   C20.25477,16.74513,20.33159,16.74513,20.35818,16.79901z"
      />
      <g>
        <path
          class="st4"
          d="M6.5,34.44013h6.94944c0.61539,0,1.11427-0.28283,1.11427-0.89822v-9.45008H5.38573v9.45008    C5.38573,34.15731,5.88461,34.44013,6.5,34.44013z"
        />
        <path
          class="st4"
          d="M5.38573,25.70739H4.1492c-0.90634,0-1.64107,0.6854-1.64107,1.53089v2.95305    c0,0.84549,0.73473,1.53089,1.64107,1.53089h1.23653"
        />
      </g>
      <path
        class="st5"
        d="M9.81101,25.89603l-0.74542,1.5104c-0.00494,0.01002-0.0145,0.01696-0.02556,0.01857l-1.66682,0.2422   c-0.02784,0.00405-0.03896,0.03827-0.01881,0.05791l1.20612,1.17568c0.008,0.0078,0.01165,0.01904,0.00976,0.03005   l-0.28473,1.66008c-0.00476,0.02773,0.02435,0.04888,0.04926,0.03579l1.49085-0.78378c0.00989-0.0052,0.0217-0.0052,0.0316,0   l1.49085,0.78378c0.02491,0.01309,0.05401-0.00805,0.04926-0.03579l-0.28473-1.66008   c-0.00189-0.01101,0.00176-0.02225,0.00976-0.03005l1.20612-1.17568c0.02015-0.01964,0.00903-0.05386-0.01881-0.05791   l-1.66682-0.2422c-0.01106-0.00161-0.02062-0.00855-0.02556-0.01857l-0.74542-1.5104   C9.85944,25.8708,9.82346,25.8708,9.81101,25.89603z"
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st4 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.st5 {
  stroke: none;
  fill: currentColor;
}
</style>
