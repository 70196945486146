<template>
  <label class="fp-label">
    <slot />
  </label>
</template>

<style lang="scss" scoped>
.fp-label {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #000000;
  line-height: 24px;
}
</style>
