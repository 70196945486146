<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 376 140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style="fill: #e8d2c7"
      d="M257.01,26.18c0,0-26.79-11.2-39.93-16.85 L195,0h-57.98c-5.53,6.38-10.46,13.25-14.73,20.53c32.24,22.5,59.32,40.4,100.06,63.8C221.79,67.77,257.01,26.18,257.01,26.18z"
    />
    <path
      style="fill: E8D2C7"
      d="M 7.38 57.96 C 20.23 57.45 33.1 57.54 45.95 58.25 L 45.95 58.25 C 33.11 57.54 20.24 57.45 7.38 57.96 Z"
    />
    <path
      style="fill: #5478bc"
      d="M 45.95 58.25 C 45.95 58.25 48.54 50.02 46.85 36.77 C 46.67 35.32 46.44 33.88 46.18 32.44 C 45.92 31 45.61 29.58 45.27 28.16 C 44.24 23.73 42.71 19.42 40.7 15.34 C 40.63 15.2 22.83 21.96 0.02 30.7 L 0.02 58.32 C 2.48 58.18 4.93 58.06 7.39 57.97 C 20.24 57.45 33.11 57.54 45.95 58.25 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 45.26 28.15 C 45.6 29.57 45.91 31 46.17 32.43 C 46.43 33.87 46.66 35.31 46.84 36.76 C 48.53 50.01 45.94 58.24 45.94 58.24 C 45.94 58.24 45.94 58.24 45.94 58.24 L 45.94 58.24 C 33.1 57.53 20.23 57.44 7.37 57.95 C 4.91 58.05 2.46 58.16 0 58.3 L 0 115.82 L 73.42 71.6 L 45.26 28.15 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 46.18 32.43 C 46.44 33.87 46.67 35.31 46.85 36.76 C 46.67 35.31 46.44 33.87 46.18 32.43 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 45.95 58.25 C 45.95 58.25 45.95 58.25 45.95 58.25 C 45.95 58.25 48.54 50.02 46.85 36.77 C 48.54 50.01 45.95 58.25 45.95 58.25 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 0.01 58.31 C 2.47 58.17 4.92 58.05 7.38 57.96 C 4.93 58.06 2.47 58.17 0.01 58.31 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 375.98 19.47 L 345.25 18.7 C 345.25 18.7 346.16 42.7 344.85 50.57 C 343.55 58.36 343.81 57.82 343.81 57.81 L 343.81 57.81 C 350.93 47.46 358.36 36.93 369.7 32.76 C 369.7 32.76 372.12 35.12 375.97 38.83"
    />
    <path
      style="fill: #285a8c"
      d="M375.98,38.82c-3.85-3.7-6.27-6.07-6.27-6.07c-11.34,4.17-18.77,14.7-25.89,25.05l0,0c0,0,0,0,0,0 s0.34,0.12,0.96,0.36l31.2,11.52L375.98,38.82z"
    />
  </svg>
</template>
