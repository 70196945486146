<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #e7ad52"
    />
    <path
      d="M30.5 34.5h-21c-.465 0-1-.535-1-1v-13c0-.465.535-1 1-1h21c.465 0 1 .535 1 1v13c0 .465-.535 1-1 1zM31.5 24.5l7-4c.3-.17 1-.296 1 0v13c0 .296-.7.17-1 0l-7-3M20.509 16.412c0 1.725-1.398 3.088-3.122 3.088s-3.123-1.363-3.123-3.088a3.122 3.122 0 1 1 6.245 0z"
      class="st2"
    />
    <path
      d="M30.026 14.776c0 2.628-2.13 4.724-4.758 4.724s-4.76-2.096-4.76-4.724a4.759 4.759 0 0 1 9.518 0zM11.5 22.5h7v4h-7z"
      class="st2"
    />
  </svg>
</template>
<style lang="scss" scoped>
.st0 {
  fill: #f0d87b;
}

.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
