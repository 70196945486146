import type { NavigationGuard } from 'vue-router'
import { useAuthStore } from '@/stores/auth-store'

export const authRouterMiddleware: NavigationGuard = async (to, from, next) => {
  const authStore = useAuthStore()
  if (to.matched.some((route) => route.meta.auth === 'logged_in')) {
    if (!authStore.isLoggedIn) {
      return next('/')
    }
    if (authStore.isLoggedIn && !authStore.user) {
      try {
        await authStore.fetchLoggedInUser()
        return next()
      } catch (error) {
        authStore.handleExpiredToken()
        return next('/')
      }
    } else {
      return next()
    }
  }
  if (to.matched.some((route) => route.meta.auth === 'not_logged_in')) {
    if (authStore.isLoggedIn) {
      return next('/dashboard')
    }
    return next()
  }
  return next()
}

export default authRouterMiddleware
