<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #a3d3c2"
        />
        <ellipse
          cx="13.7"
          cy="14.2"
          rx="4.2"
          ry="5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M4.5 38V27.5c0-2.9.3-4.7 2-6 1.3-1 5.5-2.4 7.2-2.4M17.5 35.5l1-9M9.5 35.5l-1-9M13.7 19.1c1.8 0 5.6 1.4 6.8 2.4 1.7 1.3 2 3.1 2 6V38"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M25.5 13.5v24c0 .8 0 1.5 1.5 1.5s1.5-.7 1.5-1.5V24M32.2 5.3h-3.4M32.5 3.5c0-.9-1.1-2-2-2s-2 1.1-2 2V24M28.8 15h-3.3M32.2 13.2h-3.4M28.8 17.3h-3.3M32.5 3.5V19"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linejoin: round; stroke-miterlimit: 10"
        />
        <path
          d="M32.5 18.5c0 2.4 1.2 4.6 3 6h-8c1.8-1.4 3-3.6 3-6M24 13.5h3"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linejoin: round; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
