<template>
  <div
    class="tw-py-10 tw-flex tw-justify-center tw-relative tw-bg-bgGrey"
    style="height: calc(100vh - 60px)"
  >
    <FpSpinner />
  </div>
</template>

<script lang="ts" setup>
import FpSpinner from '@/components/FpSpinner.vue'
import { goToLogin } from '@/services/OAuthService'

const props = defineProps<{
  token: string
}>()

goToLogin(undefined, props.token)
</script>
