<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="5.91 9.04 33.18 26.92"
  >
    <g>
      <g>
        <polyline
          class="st0"
          points="27.55389,31.61143 27.55389,35.46192 6.40982,35.46192 6.40982,11.5 11.30154,11.5   "
        />
        <polyline
          class="st0"
          points="23.08503,11.49999 27.55389,11.49999 27.55389,17.30446   "
        />
        <polygon
          class="st0"
          points="19.46875,11.49999 19.46875,9.53808 15.4375,9.53808 15.4375,11.49999 11.53125,11.49999     11.53125,14.5625 23.53125,14.5625 23.53125,11.49999   "
        />
        <line
          class="st0"
          x1="11.0625"
          y1="17.5625"
          x2="23.6716"
          y2="17.5625"
        />
        <line
          class="st0"
          x1="11.0625"
          y1="21.5"
          x2="20.07141"
          y2="21.5"
        />
        <line
          class="st0"
          x1="11.0625"
          y1="25.5"
          x2="20.6851"
          y2="25.5"
        />
        <line
          class="st0"
          x1="11.0625"
          y1="29.40625"
          x2="22.17612"
          y2="29.40625"
        />
      </g>
      <path
        class="st0"
        d="M20.81299,24.77168"
      />
      <g>
        <g>
          <circle
            class="st0"
            cx="30.96965"
            cy="24.60259"
            r="7.62053"
          />
        </g>
        <polyline
          class="st0"
          points="30.96965,19.16825 30.96965,24.60259 27.55389,24.60259   "
        />
        <path
          class="st0"
          d="M36.83196,24.60259"
        />
        <path
          class="st0"
          d="M38.59018,24.60259"
        />
        <path
          class="st0"
          d="M25.10734,24.60259"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: none;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
</style>
