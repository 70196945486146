<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#285A8B"
      d="M 40.686 7.06 L 8.416 14.19 L 21.016 49.57 C 21.016 49.57 40.276 47.85 42.026 43.07 C 42.106 42.9 42.146 42.72 42.166 42.53 C 42.216 40.48 38.856 37.76 38.856 37.76 L 40.686 7.06 Z"
    />
    <path
      fill="#457FEE"
      d="M 21.376 0.46 L 16.336 36.07 C 16.336 36.07 33.276 37.12 41.456 41.5 C 42.046 41.82 42.166 42.07 42.166 42.53 L 42.166 41.2 C 42.166 38.89 42.226 8.63 42.166 6.83 C 42.116 5.37 42.346 4.2 39.766 2.95 C 34.556 0.43 21.376 0.46 21.376 0.46 Z"
    />
    <path
      fill="#FFFFFF"
      d="M 39.906 20.99 C 39.726 20.74 39.236 20.17 38.576 20.21 C 36.166 20.35 33.736 20.54 31.316 20.76 C 31.516 18.45 31.726 16.14 31.966 13.84 C 31.976 13.71 31.926 13.6 31.816 13.52 C 31.476 13.28 30.486 13.35 29.966 13.59 C 29.576 13.76 29.476 14 29.456 14.16 C 29.226 16.43 29.016 18.71 28.816 21 C 26.266 21.26 23.726 21.55 21.206 21.89 C 21.026 21.91 20.896 22.01 20.826 22.16 C 20.656 22.57 21.106 23.2 21.106 23.2 C 21.246 23.39 21.736 23.99 22.336 23.99 C 22.376 23.99 22.416 23.99 22.456 23.98 C 24.496 23.71 26.556 23.47 28.616 23.25 C 28.416 25.61 28.226 27.97 28.066 30.32 C 28.056 30.48 28.126 30.74 28.746 30.79 C 28.796 30.79 28.856 30.8 28.916 30.8 C 29.556 30.8 30.536 30.59 30.576 29.99 C 30.736 27.67 30.926 25.32 31.116 22.98 C 34.006 22.7 36.896 22.48 39.776 22.31 C 40.016 22.3 40.126 22.15 40.166 22.06 C 40.376 21.66 39.906 21 39.906 20.99 Z"
    />
  </svg>
</template>
