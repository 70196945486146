<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #8289c4"
    />
    <path
      d="M36.423 14.502 9.65 18.262a4.681 4.681 0 0 1-2.98 3.92l1.307 9.304a4.681 4.681 0 0 1 4.137 3.538l26.558-3.73a4.68 4.68 0 0 1 2.91-4.504l-1.313-9.352a4.68 4.68 0 0 1-3.846-2.936zM17.705 17.131l.353 2.512"
      class="st2"
    />
    <path
      d="m18.608 23.561.826 5.876"
      style="
        fill: none;
        stroke: #1d1d1b;
        stroke-width: 0.8;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 3.95617, 3.95617;
      "
    />
    <path
      d="m19.709 31.396.353 2.512M20.887 19.628l12.486-1.754.717 5.103-12.486 1.754z"
      class="st2"
    />
    <path
      d="m21.87 27.377 10.689-1.501M22.344 29.998l7.918-1.112"
      class="st4"
    />
    <path
      d="M16 7.5h16M16 9.5h14"
      class="st5"
    />
  </svg>
</template>
<style lang="scss" scoped>
.st0 {
  fill: #b5b9dc;
}

.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st4 {
  stroke-linecap: square;
}

.st4,
.st5 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
