<template>
  <FpContainer>
    <h3 class="tw-text-3xl tw-font-light tw-mt-20 tw-mb-8">
      {{ $t('404.heading') }}
    </h3>
    <div class="tw-whitespace-pre-wrap tw-mb-8">
      {{ $t('404.body') }}
    </div>
    <FpButton
      class="tw-mb-20"
      to="/"
      >{{ $t('404.link') }}
    </FpButton>
  </FpContainer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import FpButton from '@/components/FpButton.vue'
import FpContainer from '@/components/FpContainer.vue'
import { useAuthStore } from '@/stores/auth-store'

const { isLoggedIn } = storeToRefs(useAuthStore())

const router = useRouter()
const routeToFrontPage = () => {
  if (isLoggedIn) {
    router.push({ path: '/dashboard' })
  } else {
    router.push({ path: '/' })
  }
}
</script>
