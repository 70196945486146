<template>
  <div class="fp-menu">
    <div class="fp-menu-inner fp-menu-log">
      <h1 class="fp-menu__headline">Fair play</h1>
      <div
        class="fp-menu__logo-1"
        @click="navHome()"
      />
      <div
        class="fp-menu__logo-2"
        @click="navHome()"
      />
    </div>

    <div
      v-if="menuType == 'frontpage'"
      class="fp-menu__links"
    >
      <a
        class="fp-menu__link fp-menu__link-high noselect tw-cursor-pointer"
        @click="() => goToLogin()"
      >
        {{ $t('menu.login') }}
      </a>
      <a
        class="fp-menu__link noselect tw-cursor-pointer"
        @click="() => goToRegister()"
      >
        {{ $t('menu.tryit') }}
      </a>
    </div>
    <div
      v-if="menuType == 'frontpage'"
      ref="menuButtonRef"
      class="fp-menu__burger"
      :class="{ 'fp-menu__burger--open': menuOpen }"
      @click="swapMenu()"
    >
      <div class="fp-menu__burger-1" />
      <div class="fp-menu__burger-2" />
      <div class="fp-menu__burger-3" />
    </div>
    <div
      v-if="menuType == 'frontpage'"
      ref="frontPageMenuRef"
      class="fp-menu__fold-out"
      :class="{ 'fp-menu__fold-out--open': menuOpen }"
    >
      <div class="fp-menu__fold-inner">
        <div class="fp-menu__fold-top" />
        <RouterLink
          class="fp-menu__fold-out-link noselect"
          to="/"
        >
          {{ $t('menu.home') }}
        </RouterLink>
        <RouterLink
          class="fp-menu__fold-out-link noselect"
          to="/about"
        >
          {{ $t('menu.about') }}
        </RouterLink>
        <RouterLink
          class="fp-menu__fold-out-link noselect"
          to="/contact"
        >
          {{ $t('menu.contact') }}
        </RouterLink>
        <RouterLink
          class="fp-menu__fold-out-link noselect"
          to="/prices"
        >
          {{ $t('menu.prices') }}
        </RouterLink>
        <RouterLink
          class="fp-menu__fold-out-link noselect"
          to="/terms"
        >
          {{ $t('menu.terms') }}
        </RouterLink>
        <RouterLink
          class="fp-menu__fold-out-link noselect"
          to="/gdpr"
        >
          {{ $t('menu.gdpr') }}
        </RouterLink>
        <br />
        <br />
        <a
          class="fp-menu__fold-out-link noselect tw-cursor-pointer"
          @click.prevent="() => goToRegister()"
        >
          {{ $t('menu.createAccount') }}
        </a>
        <a
          class="fp-menu__fold-out-link noselect tw-cursor-pointer"
          @click.prevent="() => goToLogin()"
        >
          {{ $t('menu.login') }}
        </a>
        <div class="fp-menu-lang">
          <div
            class="fp-menu-lang-option"
            @click="setLanguage('en')"
          >
            <p class="fp-menu-lang-text">ENG</p>
            <LangEn />
          </div>
          <div
            class="fp-menu-lang-option"
            @click="setLanguage('da')"
          >
            <p class="fp-menu-lang-text">DK</p>
            <LangDk />
          </div>
        </div>
        <div class="fp-menu__fold-bottom">
          <MenuLogo />
        </div>
      </div>
    </div>
    <div
      v-if="menuType == 'logged_in'"
      class="fp-menu__burger fp-menu__burger--mobile"
      :class="menuMobileOpen && ' fp-menu__burger--open'"
      @click="swapMobileMenu()"
    >
      <div class="fp-menu__burger-1" />
      <div class="fp-menu__burger-2" />
      <div class="fp-menu__burger-3" />
    </div>
    <div
      v-if="menuType == 'logged_in'"
      class="fp-dashboard-menu"
      :class="{ 'fp-dashboard-menu--open': menuMobileOpen }"
    >
      <div class="fp-dashboard-menu-inner">
        <RouterLink
          class="fp-dashboard-menu-add noselect"
          to="/interview"
          @click="clickOnMenu()"
        >
          <Add />
          <div class="fp-dashboard-menu-add-box" />
          <p class="fp-dashboard-menu-add-text">
            {{ $t('menu.draftcontract') }}
          </p>
        </RouterLink>
        <RouterLink
          class="fp-dashboard-menu-link noselect"
          to="/dashboard"
          @click="clickOnMenu()"
        >
          <Dashboard />
          <div class="fp-dashboard-menu-link-box" />
          <p class="fp-dashboard-menu-link-text">
            {{ $t('menu.dashboard') }}
          </p>
        </RouterLink>
        <RouterLink
          class="fp-dashboard-menu-link noselect"
          to="/advisor"
          @click="clickOnMenu()"
        >
          <Advisor />
          <div class="fp-dashboard-menu-link-box" />
          <p class="fp-dashboard-menu-link-text">
            {{ $t('menu.advisor') }}
          </p>
        </RouterLink>
        <RouterLink
          class="fp-dashboard-menu-link noselect"
          to="/profile"
          @click="clickOnMenu()"
        >
          <Profile />
          <div class="fp-dashboard-menu-link-box" />
          <p class="fp-dashboard-menu-link-text">
            {{ $t('menu.profile') }}
          </p>
        </RouterLink>
        <RouterLink
          class="fp-dashboard-menu-link noselect"
          to="/settings"
          @click="clickOnMenu()"
        >
          <Settings />
          <div class="fp-dashboard-menu-link-box" />
          <p class="fp-dashboard-menu-link-text">
            {{ $t('menu.settings') }}
          </p>
        </RouterLink>
        <div
          class="fp-dashboard-menu-link noselect"
          @click="logOut"
        >
          <Logout />
          <div class="fp-dashboard-menu-link-box" />
          <p class="fp-dashboard-menu-link-text">
            {{ $t('menu.logout') }}
          </p>
        </div>
        <div v-if="showLanguageSelector">
          <div
            class="fp-dashboard-menu-link noselect"
            @click="setLanguage('en')"
          >
            <LangEn />
            <div class="fp-dashboard-menu-link-box" />
            <p class="fp-dashboard-menu-link-text">ENG</p>
          </div>
          <div
            class="fp-dashboard-menu-link noselect"
            @click="setLanguage('da')"
          >
            <LangDk />
            <div class="fp-dashboard-menu-link-box" />
            <p class="fp-dashboard-menu-link-text">DK</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { setLanguage } from '@/lang'
import { goToLogin, goToRegister } from '@/services/OAuthService'
import { useAuthStore } from '@/stores/auth-store'
import Add from '@/svg/Add.vue'
import Advisor from '@/svg/Advisor.vue'
import Dashboard from '@/svg/Dashboard.vue'
import LangDk from '@/svg/LangDk.vue'
import LangEn from '@/svg/LangEn.vue'
import Logout from '@/svg/Logout.vue'
import MenuLogo from '@/svg/MenuLogo.vue'
import Profile from '@/svg/Profile.vue'
import Settings from '@/svg/Settings.vue'

const menuOpen = ref(false)
const menuMobileOpen = ref(false)

const clickOnMenu = () => {
  menuMobileOpen.value = false
}
const swapMenu = () => {
  menuOpen.value = !menuOpen.value
}
const swapMobileMenu = () => {
  menuMobileOpen.value = !menuMobileOpen.value
}

const { logOut } = useAuthStore()

const router = useRouter()
const navHome = () => {
  if (menuType.value === 'frontpage') {
    router.push({ path: '/' })
  } else {
    router.push({ path: '/dashboard' })
  }
}

const { token } = storeToRefs(useAuthStore())

const menuType = computed<'frontpage' | 'logged_in'>(() => {
  if (token.value) {
    return 'logged_in'
  }
  return 'frontpage'
})

const route = useRoute()
const showLanguageSelector = computed(() => {
  return !(route.fullPath.includes('interview/') || route.fullPath.includes('contracts'))
})

const frontPageMenuRef = ref<HTMLElement | null>(null)
const menuButtonRef = ref<HTMLElement | null>(null)
onClickOutside(
  frontPageMenuRef,
  () => {
    menuOpen.value = false
  },
  { ignore: [menuButtonRef] },
)
</script>
//TODO: Setting scoped style ruins icon color on active. Need to fix so icon color is always just currentColor
<style lang="scss">
@import '@/scss/theme';

.fp-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: $primary;
  z-index: 100;

  &-lang {
    display: flex;
    flex-wrap: nowrap;
    width: 240px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;

    &-text {
      font-size: 18px;
      line-height: 60px;
      padding-left: 54px;
    }

    &-option {
      position: relative;
      width: 50%;
      height: 100%;
      cursor: pointer;

      .svg {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 30px;
        height: 30px;
      }
    }
  }

  &-inner {
    position: relative;
    width: 100%;
    height: 60px;
    overflow: hidden;
  }

  &__logo-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 220px;
    height: 100%;
    background-image: url('../svg/logo1.svg');
    background-repeat: no-repeat;
    z-index: 10;
    cursor: pointer;
  }

  &__logo-2 {
    position: absolute;
    top: 0;
    left: 180px;
    width: 400px;
    height: 100%;
    background-image: url('../svg/logo2.svg');
    background-repeat: no-repeat;
    z-index: 20;
    cursor: pointer;
  }

  &__headline {
    text-transform: uppercase;
    color: $primary;
    font-size: 26px;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 60px;
  }

  &__links {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    top: 0;
    right: 60px;
    height: 60px;
  }

  &__link {
    text-transform: uppercase;
    color: $light;
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 60px;
    text-decoration: none;

    &-high {
      background-color: #457fee;
    }
  }

  &__burger {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    z-index: 20;
    cursor: pointer;

    &-1 {
      position: absolute;
      top: 20px;
      right: 14px;
      width: 32px;
      height: 2px;
      background-color: #ffffff;
      transition: all 300ms ease-in-out;
    }

    &-2 {
      position: absolute;
      top: 29px;
      right: 14px;
      width: 32px;
      height: 2px;
      background-color: #ffffff;
      transition: all 300ms ease-in-out;
    }

    &-3 {
      position: absolute;
      top: 38px;
      right: 14px;
      width: 32px;
      height: 2px;
      background-color: #ffffff;
      transition: all 300ms ease-in-out;
    }

    &--open {
      .fp-menu__burger-1 {
        top: 29px;
        right: 14px;
        background-color: #000000;
        transform: rotate(-45deg);
      }

      .fp-menu__burger-2 {
        background-color: transparent;
      }

      .fp-menu__burger-3 {
        top: 29px;
        right: 14px;
        background-color: #000000;
        transform: rotate(45deg);
      }
    }
  }

  &__btn {
    position: relative;
    background-color: #c8c8c8;
    margin: 20px;
    padding: 10px;
    cursor: pointer;
  }

  &__fold-out {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: #f5f5f5;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: 11px -6px;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    box-shadow: 0 3px 10px #00000030;
  }

  .fp-menu__fold-out.fp-menu__fold-out--open {
    width: 400px;
  }

  &__fold-inner {
    display: block;
    width: 400px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__fold-top {
    width: 100%;
    height: 60px;
  }

  &__fold-out-link {
    display: block;
    width: auto;
    height: 66px;
    font-size: 14px;
    line-height: 66px;
    text-align: right;
    padding-right: 50px;
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__fold-out-link.is-active {
    color: #ffffff;
    background-color: #457fed;

    .svg .svg-1,
    .svg-2,
    .svg-3,
    .svg-4,
    .svg-5,
    .svg-6 {
      stroke: #ffffff;
      color: #ffffff;
      fill: #ffffff;
    }
  }

  &__fold-bottom {
    position: relative;
    width: 100%;
    height: 120px;

    .svg {
      position: absolute;
      top: 20px;
      left: 50%;
      width: 260px;
      height: auto;
      margin-left: -130px;
    }
  }
}

.fp-menu__burger.fp-menu__burger--mobile {
  position: fixed;
  display: none;
  transition: width 200ms ease-in-out, background-color 200ms ease-in-out;
}

.fp-dashboard-menu {
  position: fixed;
  top: 60px;
  left: 0;
  width: 60px;
  height: calc(100% - 60px);
  background-color: #dbdbdb;
  transition: all 200ms ease-in-out;
  overflow: hidden;

  &-add {
    display: block;
    position: relative;
    text-decoration: none;
    width: 100%;
    height: 88px;
    background-color: transparent;
    transition: all 200ms ease-in-out;

    &-box {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60px;
      z-index: 10;
      background-color: transparent;
      transition: all 200ms ease-in-out;
    }

    .svg {
      position: absolute;
      top: 18px;
      left: 3px;
      width: 50px;
      height: 50px;
      z-index: 20;
    }

    &-text {
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      line-height: 90px;
      padding-left: 80px;
      color: #000000;
      text-transform: uppercase;
    }
  }

  &-inner {
    width: 274px;
  }

  &-link {
    display: block;
    position: relative;
    text-decoration: none;
    width: 100%;
    height: 60px;
    background-color: transparent;
    transition: all 200ms ease-in-out;
    cursor: pointer;

    &-box {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60px;
      z-index: 10;
      background-color: transparent;
      transition: all 200ms ease-in-out;
    }

    .svg {
      position: absolute;
      top: 14px;
      left: 15px;
      width: 30px;
      height: 30px;
      z-index: 20;
    }

    &-text {
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      line-height: 58px;
      padding-left: 80px;
      color: #000000;
      text-transform: uppercase;
    }
  }

  &-link.is-active,
  &-add.is-active {
    background-color: #f5f5f5;

    .svg-1,
    .svg-2,
    .svg-3,
    .svg-4,
    .svg-5,
    .svg-6 {
      stroke: #ffffff;
    }

    .fp-dashboard-menu-link-box,
    .fp-dashboard-menu-add-box {
      background-color: #224e78;
    }
  }
}

.fp-dashboard-menu:hover {
  width: 274px;
}

.fp-dashboard-menu-link:hover,
.fp-dashboard-menu-add:hover {
  background-color: #c4c4c4;
}

@media only screen and (max-width: 800px) {
  .fp-menu__links {
    display: none;
  }
  .fp-menu__logo-1 {
    left: 0;
    width: 140px;
  }
  .fp-menu__logo-2 {
    top: 12px;
    left: 10px;
    width: 200px;
  }
  .fp-menu-inner.fp-menu-log {
    .fp-menu__logo-1 {
      left: 60px;
    }

    .fp-menu__logo-2 {
      left: 70px;
    }
  }
  .fp-menu__burger.fp-menu__burger--mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: auto;
    background-color: #dbdbdb;

    .fp-menu__burger-1,
    .fp-menu__burger-2,
    .fp-menu__burger-3 {
      background-color: #000000;
    }
  }
  .fp-menu__burger.fp-menu__burger--mobile.fp-menu__burger--open {
    left: 0;
    width: 274px;

    .fp-menu__burger-1,
    .fp-menu__burger-3 {
      background-color: #000000;
    }

    .fp-menu__burger-2 {
      background-color: transparent;
    }
  }
  .fp-dashboard-menu-add {
    margin-top: 0;
  }
  .fp-dashboard-menu,
  .fp-dashboard-menu:hover {
    width: 0;
  }
  .fp-dashboard-menu.fp-dashboard-menu--open,
  .fp-dashboard-menu--open.fp-dashboard-menu:hover {
    width: 274px;
  }
}
</style>
