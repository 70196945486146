<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="svg-1"
      fill="none"
      stroke="#1D1D1B"
      d="M 45.763 30.557 L 42.113 27.222 C 42.218 26.446 42.302 25.67 42.302 24.872 C 42.302 24.013 42.218 23.195 42.092 22.378 L 45.763 19.021 C 46.329 18.476 46.455 17.637 46.078 16.988 L 42.324 10.507 C 41.945 9.857 41.149 9.542 40.435 9.773 L 35.633 11.304 C 34.395 10.339 33.032 9.542 31.565 8.954 L 30.473 4.026 C 30.305 3.293 29.655 2.747 28.88 2.747 L 21.413 2.747 C 20.659 2.747 19.988 3.271 19.82 4.026 L 18.729 8.954 C 17.261 9.542 15.898 10.339 14.66 11.304 L 9.858 9.773 C 9.123 9.542 8.348 9.857 7.969 10.507 L 4.215 16.988 C 3.839 17.659 3.964 18.476 4.531 19 L 8.201 22.356 C 8.075 23.174 7.991 23.992 7.991 24.853 C 7.991 25.649 8.075 26.446 8.18 27.2 L 4.531 30.557 C 3.964 31.06 3.839 31.898 4.215 32.569 L 7.969 39.051 C 8.348 39.701 9.145 40.015 9.858 39.784 L 14.513 38.316 C 15.792 39.323 17.219 40.162 18.771 40.791 L 19.82 45.531 C 19.988 46.264 20.638 46.81 21.413 46.81 L 28.901 46.81 C 29.655 46.81 30.326 46.285 30.494 45.531 L 31.544 40.791 C 33.095 40.162 34.522 39.344 35.801 38.316 L 40.457 39.784 C 41.191 40.015 41.967 39.701 42.345 39.051 L 46.099 32.569 C 46.455 31.898 46.329 31.06 45.763 30.557 Z M 25.147 33.409 C 20.407 33.409 16.59 29.57 16.59 24.853 C 16.59 20.112 20.428 16.295 25.147 16.295 C 29.886 16.295 33.704 20.134 33.704 24.853 C 33.704 29.57 29.886 33.409 25.147 33.409 Z"
      style="stroke-width: 1.5px"
    />
  </svg>
</template>
