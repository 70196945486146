<template>
  <FpSlideIn
    :heading="heading"
    :show-close-button="!loading"
  >
    <FadeTransition>
      <div
        v-if="loading"
        class="tw-flex tw-justify-center"
      >
        <FpSpinner />
      </div>

      <div v-else>
        <div
          v-for="(section, index) in infoSection"
          :key="index"
          class="tw-mb-3"
        >
          <p>
            {{ section.content }}
          </p>
        </div>
        <div
          v-if="infoKey === 'CC_Q_36'"
          class="tw-my-4"
        >
          <img
            v-if="lcid === 'da'"
            src="../img/fee-upon-cancellation-da.png"
            alt=""
          />
          <img
            v-else
            src="../img/fee-upon-cancellation-en.png"
            alt=""
          />
        </div>
      </div>
    </FadeTransition>
  </FpSlideIn>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { fetchInfoSection } from '@/api/api'
import FpSlideIn from '@/components/FpSlideIn.vue'
import FpSpinner from '@/components/FpSpinner.vue'
import FadeTransition from '@/components/Transitions/FadeTransition.vue'
import type { SupportedLocales } from '@/lang'
import type { InfoDto } from '@/types/api'

export interface InfoKeySlideInProps {
  heading?: string
  infoKey: string
  lcid?: SupportedLocales
}

const props = defineProps<InfoKeySlideInProps>()

const infoSection = ref<InfoDto | null>(null)

const loading = ref(true)

const loadInfo = async () => {
  loading.value = true
  infoSection.value = await fetchInfoSection(props.infoKey, props.lcid)
  loading.value = false
}

const { locale } = useI18n()

loadInfo()

watch([() => props.infoKey, locale], loadInfo)
</script>
