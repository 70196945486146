import type { NavigationGuard } from 'vue-router'
import { setLanguage } from '@/lang'
import type { SupportedLocales } from '@/lang'

export const languageRouterMiddleware: NavigationGuard = async (to) => {
  if (to.params.languageCode && (to.params.languageCode as SupportedLocales)) {
    setLanguage(to.params.languageCode as SupportedLocales)
  }
}

export default languageRouterMiddleware
