<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      style="fill: none; stroke: #000000"
      cx="15.5"
      cy="15.5"
      r="15"
    />
    <path
      style="fill: none; stroke: #000000"
      d="M 22.631 15.498 L 9.311 15.498"
      transform="matrix(-1, 0, 0, -1, 31.942, 30.996)"
    />
    <path
      style="fill: none; stroke: #000000"
      d="M 9.001 9.378 L 15.121 15.498 L 8.991 21.628"
      transform="matrix(-1, 0, 0, -1, 24.112, 31.006001)"
    />
  </svg>
</template>
