<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_5386_00000152239045516999157730000013818267171055974272_"
      d="M13 3 3 13v29h31V3H13z"
      style="fill: #f6b8b5"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #f28c88"
    />
    <path
      d="M21.502 32.852c1.097 0 1.097-22.612 2.194-22.612s1.097 22.612 2.195 22.612c1.096 0 1.096-17.349 2.193-17.349 1.098 0 1.098 12.854 2.195 12.854 1.098 0 1.098-10.762 2.195-10.762 1.097 0 1.097 8.718 2.194 8.718s1.097-7.083 2.194-7.083c1.095 0 1.157 3.95 2.757 3.95h1.421"
      class="st2"
    />
    <path
      d="M25.959 32.852c-1.097 0-1.097-22.612-2.194-22.612s-1.097 22.612-2.195 22.612c-1.097 0-1.097-17.349-2.194-17.349s-1.097 12.854-2.195 12.854c-1.097 0-1.097-10.762-2.194-10.762s-1.097 8.718-2.195 8.718c-1.096 0-1.096-7.083-2.193-7.083-1.096 0-1.158 3.95-2.757 3.95H5.268M36.5 13.5c-3.222-4.374-8.351-7.143-14.139-6.503-.626.07-1.24.175-1.839.314M12.5 33.5c1.771 2.004 3.926 3.207 6.645 4.115a15.64 15.64 0 0 0 8.127.48 15.801 15.801 0 0 0 9.177-5.615"
      class="st2"
    />
    <path
      d="m31.5 12.5 5 1v-5M17 33.5h-4.5l.5 4.325"
      class="st3"
    />
  </svg>
</template>
<style>
.st2 {
  stroke-linecap: round;
}
.st2,
.st3 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
