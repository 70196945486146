<template>
  <FpModal
    :title="$t('missingContractPartyInfoModal.title')"
    size="large"
    @close="$emit('close')"
  >
    <div
      v-if="isPartyMissingAnyInfo"
      class="tw-mb-2"
    >
      <p class="tw-font-semibold">
        {{ $t(`missingContractPartyInfoModal.roles.${contract.roleId}`) }}
      </p>
      <p v-if="props.missingInfo.missingContractPartyFirstName">
        {{
          props.missingInfo.contractPartyType === 'Person'
            ? $t('missingContractPartyInfoModal.info.firstName')
            : $t('missingContractPartyInfoModal.info.companyName')
        }}
      </p>
      <p v-if="props.missingInfo.missingContractPartyLastName">
        {{ $t('missingContractPartyInfoModal.info.lastName') }}
      </p>
      <p v-if="props.missingInfo.missingContractPartyAddressLine1">
        {{ $t('missingContractPartyInfoModal.info.address') }}
      </p>
      <p v-if="props.missingInfo.missingContractPartyPostalCode">
        {{ $t('missingContractPartyInfoModal.info.postalCode') }}
      </p>
      <p v-if="props.missingInfo.missingContractPartyCity">
        {{ $t('missingContractPartyInfoModal.info.city') }}
      </p>
      <p v-if="props.missingInfo.missingContractPartyCountry">
        {{ $t('missingContractPartyInfoModal.info.country') }}
      </p>
      <p v-if="props.missingInfo.missingContractPartyCPRCVR">
        {{
          props.missingInfo.contractPartyType === 'Person'
            ? $t('missingContractPartyInfoModal.info.personalId')
            : $t('missingContractPartyInfoModal.info.companyId')
        }}
      </p>
    </div>
    <div v-if="isCounterpartyMissingAnyInfo">
      <p class="tw-font-semibold">
        {{ $t(`missingContractPartyInfoModal.roles.${contract.counterpartRoleId}`) }}
      </p>
      <p v-if="props.missingInfo.missingCounterPartyFirstName">
        {{
          props.missingInfo.contractCounterPartyType === 'Person'
            ? $t('missingContractPartyInfoModal.info.firstName')
            : $t('missingContractPartyInfoModal.info.companyName')
        }}
      </p>
      <p v-if="props.missingInfo.missingCounterPartyLastName">
        {{ $t('missingContractPartyInfoModal.info.lastName') }}
      </p>
      <p v-if="props.missingInfo.missingCounterPartyAddressLine1">
        {{ $t('missingContractPartyInfoModal.info.address') }}
      </p>
      <p v-if="props.missingInfo.missingCounterPartyPostalCode">
        {{ $t('missingContractPartyInfoModal.info.postalCode') }}
      </p>
      <p v-if="props.missingInfo.missingCounterPartyCity">
        {{ $t('missingContractPartyInfoModal.info.city') }}
      </p>
      <p v-if="props.missingInfo.missingCounterPartyCountry">
        {{ $t('missingContractPartyInfoModal.info.country') }}
      </p>
      <p v-if="props.missingInfo.missingCounterPartyCPRCVR">
        {{
          props.missingInfo.contractCounterPartyType === 'Person'
            ? $t('missingContractPartyInfoModal.info.personalId')
            : $t('missingContractPartyInfoModal.info.companyId')
        }}
      </p>
    </div>
    <p class="tw-mt-6">
      {{ $t('missingContractPartyInfoModal.bottomText') }}
    </p>
    <template #footer>
      <div class="tw-flex tw-justify-end tw-gap-4">
        <FpButton
          accent="blue"
          :loading="generatingContract"
          @click="generateContract"
        >
          {{ $t('missingContractPartyInfoModal.buttons.createContract') }}
        </FpButton>
        <FpButton
          accent="red"
          @click="$emit('close')"
        >
          {{ $t('missingContractPartyInfoModal.buttons.cancel') }}
        </FpButton>
      </div>
    </template>
  </FpModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { createPDFContract } from '@/api/api'
import FpButton from '@/components/FpButton.vue'
import FpModal from '@/components/FpModal.vue'
import { useToastStore } from '@/stores/toast-store'
import type { MissingInfoDto } from '@/types/MissingInfoDto'
import type { ContractDto } from '@/types/api'

const props = defineProps<{
  contract: ContractDto
  missingInfo: MissingInfoDto
}>()

const emit = defineEmits<{
  'close': [void]
  'contract-generated': [void]
}>()

const isPartyMissingAnyInfo = computed<boolean>(() => {
  return (
    props.missingInfo.missingContractPartyFirstName ||
    props.missingInfo.missingContractPartyLastName ||
    props.missingInfo.missingContractPartyAddressLine1 ||
    props.missingInfo.missingContractPartyCity ||
    props.missingInfo.missingContractPartyPostalCode ||
    props.missingInfo.missingContractPartyCountry ||
    props.missingInfo.missingContractPartyCPRCVR
  )
})

const isCounterpartyMissingAnyInfo = computed<boolean>(() => {
  return (
    props.missingInfo.missingCounterPartyFirstName ||
    props.missingInfo.missingCounterPartyLastName ||
    props.missingInfo.missingCounterPartyAddressLine1 ||
    props.missingInfo.missingCounterPartyCity ||
    props.missingInfo.missingCounterPartyPostalCode ||
    props.missingInfo.missingCounterPartyCountry ||
    props.missingInfo.missingCounterPartyCPRCVR
  )
})

const generatingContract = ref(false)
const { toast } = useToastStore()
const generateContract = async () => {
  generatingContract.value = true
  try {
    await createPDFContract(props.contract.id)
    emit('contract-generated')
    emit('close')
  } catch (error) {
    toast('contractSummary.toasts.genericError', 'error')
  } finally {
    generatingContract.value = false
  }
}
</script>
