<template>
  <div class="fp-container">
    <slot />
  </div>
</template>

<style lang="scss">
.fp-container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 800px) {
  .fp-container {
    padding-left: 80px;
    padding-right: 80px;
  }
}
</style>
