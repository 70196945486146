<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #a3d3c2"
        />
        <path
          d="M17.3 30.8c1.1 0 1.1-22.6 2.2-22.6s1.1 22.6 2.2 22.6 1.1-17.3 2.2-17.3 1.1 12.9 2.2 12.9 1.1-10.8 2.2-10.8 1.1 8.7 2.2 8.7 1.1-7.1 2.2-7.1 1.2 4.3 2.8 4.3h4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="M21.8 30.8c-1.1 0-1.1-22.6-2.2-22.6s-1.1 22.6-2.2 22.6-1.1-17.3-2.2-17.3-1.1 12.9-2.2 12.9-1.1-10.8-2.2-10.8-1.1 8.7-2.2 8.7-1.1-7.1-2.2-7.1-1.3 4.3-2.9 4.3h-3"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="M32.5 10.5C29.2 6.4 24.1 4 18.6 4.6 14.8 5 11 7 8.5 9.5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="M29.5 10.6h3V7.5M7.6 29.5c3.3 4.1 8.4 6.5 13.9 5.9 3.8-.4 7.6-2.4 10.1-4.9"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="M10.6 29.4h-3v3.1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
