<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448.67 510.62"
  >
    <path
      class="st0"
      d="M419.88,10.24c-7.1-1.31-14.42-2.44-21.66-2.11c-2.85,0.13-8.44-0.02-9.76,3.18
	c-2.81,6.79-5.67,13.57-8.58,20.32c-4.93-1.66-9.98-2.96-15.1-3.87c-2.97-0.55-5.97-1.05-8.99-1.44c0.02-4.68-1.59-9.23-4.55-12.85
	c-6.47-7.8-22.97-9.9-32.03-11.37c-6.32-1.03-23.09-4.97-27.4,1.72c-8.51,13.21-13.05,28.11-17.8,42.96
	c-0.29,0.89-0.58,1.79-0.86,2.68c-0.76-2.53-1.99-4.92-3.69-7c-6.47-7.8-22.97-9.9-32.03-11.37c-6.32-1.03-23.09-4.97-27.4,1.72
	c-8.51,13.21-13.05,28.11-17.8,42.96c-0.95,2.97-1.91,5.93-2.87,8.89c-7.9-4.41-17.86-6.61-26.25-8.15
	c-9.33-1.71-25.14-5.37-34.05,0.43c-3.99,2.6-5.79,7.16-7.55,11.37c-2.67,6.36-5.24,12.75-7.78,19.16
	c-4.91,12.39-9.62,24.86-14.22,37.37c-9.04,24.6-17.63,49.37-26.37,74.08c-9.8,27.73-19.78,55.4-30.78,82.68
	c-0.77,1.91-1.56,3.82-2.34,5.73c-2.7-0.23-5.41-0.29-8.11-0.17c-2.82,0.13-8.49-0.02-9.76,3.18c-4.9,12.37-9.81,24.75-14.71,37.13
	c-3.05,7.71-11.93,21.52-4.58,29.05c6.44,6.6,19.43,9.14,28.01,10.72c5.08,0.96,10.21,1.63,15.36,2.01c0.6,0.04,1.37,0.09,2.25,0.14
	c-9.92,27.08-22.77,54.64-26.6,83.32c-0.78,5.83,17.84,10.22,20.78,10.96c10.82,2.72,23.43,5.56,34.58,3.42
	c10.7-2.05,17.43-8.82,23.25-16.98c1.58,1,3.23,1.88,4.95,2.62c6.69,2.88,13.69,4.95,20.87,6.16c7.78,1.43,25.3,6.17,31.42-1.07
	c2.43-2.88,4.71-5.87,6.87-8.92c-1.74,8.85-3.53,17.69-5.36,26.52c-1.34,6.56,21.76,11.18,24.76,11.87
	c6.34,1.55,12.82,2.53,19.34,2.91c3.58,0.14,12,1.38,14.62-1.45c10.67-11.49,18.6-24.54,25.22-38.33
	c5.63,4.39,20.26,7.34,22.61,7.87c6.34,1.55,12.82,2.53,19.34,2.91c3.58,0.14,12,1.38,14.62-1.45
	c20.27-21.83,30.65-49.28,40.95-76.74c11.18-29.78,21.71-59.8,31.57-90.05c19.76-60.49,37.02-121.79,52.21-183.58
	c8.68-35.32,16.72-70.79,24.13-106.4C450.12,15.78,424.12,11.01,419.88,10.24z"
    ></path>
  </svg>
</template>

<style scoped lang="scss">
.st0 {
  stroke: none;
  fill: #f7f3ea;
}
</style>
