<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #5eaf8c"
    />
    <g id="Group_1798_00000168839438061421757370000014487518516690598322_">
      <path
        id="Rectangle_1004_00000134945404993461116410000004448304596394389692_"
        d="M16.5 7.5h20v28h-20z"
        class="st2"
      />
      <path
        id="Path_5194_00000058580008880928731890000003287137996712987798_"
        d="M36.5 10.5h2v27h-19v-2"
        class="st2"
      />
      <path
        id="Line_266_00000111183788636833188870000000941111938302033813_"
        d="M6.5 19.5h4"
        class="st3"
      />
      <path
        id="Path_5193_00000088094959902382680670000002820101380160905368_"
        d="M9.5 37.5h-2c-.4 0-1-.5-1-1v-18l2-2 2 2v18c0 1-1 1-1 1z"
        class="st3"
      />
      <path
        id="Line_267_00000172420505251906891760000015660448877404350137_"
        d="M6.5 34.5h4"
        class="st2"
      />
      <path
        id="Path_5196_00000136388796075516906850000014676230186260787080_"
        d="M31.5 27.5v-12l-7-1v11"
        class="st2"
      />
    </g>
    <circle
      cx="29.5"
      cy="27.5"
      r="2"
      class="st2"
    />
    <circle
      cx="22.5"
      cy="25.5"
      r="2"
      class="st2"
    />
  </svg>
</template>
<style lang="scss" scoped>
.st0 {
  fill: #97cbb5;
}

.st2,
.st3 {
  fill: none;
  stroke: #161615;
  stroke-width: 0.8;
}

.st2 {
  stroke-miterlimit: 10;
}

.st3 {
  stroke-linejoin: round;
}
</style>
