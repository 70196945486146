<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      class="svg-1"
      x="2.131"
      y="2.205"
      fill="none"
      stroke="#1D1D1B"
      width="45.684"
      height="10.142"
      style="stroke-width: 1.5px"
    />
    <rect
      class="svg-2"
      x="2"
      y="18.071"
      fill="none"
      stroke="#1D1D1B"
      width="20.023"
      height="29.817"
      style="stroke-width: 1.5px"
    />
    <rect
      class="svg-3"
      x="27.791"
      y="35.896"
      fill="none"
      stroke="#1D1D1B"
      width="20.023"
      height="11.992"
      style="stroke-width: 1.5px"
    />
    <rect
      class="svg-4"
      x="27.791"
      y="18.071"
      fill="none"
      stroke="#1D1D1B"
      width="20.023"
      height="11.992"
      style="stroke-width: 1.5px"
    />
  </svg>
</template>
