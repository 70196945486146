<template>
  <div class="fp-contact">
    <FpContainer>
      <FpHeadingWithContractIcon icon="question-mark">
        {{ $t('contact.heading') }}
      </FpHeadingWithContractIcon>
      <i18n-t
        class="tw-whitespace-pre-wrap"
        keypath="contact.body"
        scope="global"
        tag="p"
      >
        <template #email>
          <a
            class="tw-text-primaryBlue"
            :href="`mailto:${supportEmail}`"
            target="_blank"
          >
            {{ supportEmail }}
          </a>
        </template>
      </i18n-t>
    </FpContainer>
  </div>
</template>

<script setup lang="ts">
import FpContainer from '@/components/FpContainer.vue'
import FpHeadingWithContractIcon from '@/components/FpHeadingWithContractIcon.vue'

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
</script>

<style lang="scss">
@import '@/scss/theme.scss';

.fp-contact {
  font-weight: 200;
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 100px;
  background-color: $light;
  width: 100%;
  white-space: pre-wrap;
  padding-top: 70px;
}
</style>
