<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      style="fill: #f6b8b5"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #f28c88"
    />
    <path
      d="M17.5 15.5c2.21 0 4 2 4 4 0 2.596-1.79 5-4 5s-4-2.404-4-5c0-2 1.79-4 4-4z"
      class="st2"
    />
    <path
      d="M10.615 40.036v-7.2c0-2.8-.3-4.8 1.2-6.1 1.1-1 4.185-2.236 5.685-2.236M22.315 38.136 22.5 30.5M13.615 38.136 13.5 30.5M17.5 24.5c1.5 0 5.415 1.336 6.515 2.336 1.4 1.3 1.2 3.3 1.2 6.1v7.1M30.817 4.708l1.591 3.225c.01.021.031.036.055.04l3.559.517c.06.008.083.081.04.123l-2.575 2.51a.072.072 0 0 0-.021.065l.608 3.544a.072.072 0 0 1-.105.077l-3.184-1.674a.072.072 0 0 0-.067 0l-3.183 1.674a.072.072 0 0 1-.105-.077l.607-3.544a.072.072 0 0 0-.02-.064l-2.576-2.51a.072.072 0 0 1 .04-.124l3.56-.517a.072.072 0 0 0 .054-.04l1.592-3.225a.072.072 0 0 1 .13 0z"
      class="st2"
    />
    <circle
      cx="18"
      cy="23"
      r="10.5"
      class="st2"
    />
  </svg>
</template>
<style>
.st2 {
  fill: none;
  stroke: #1d1e1c;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
