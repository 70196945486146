<template>
  <FpModal
    v-if="confirmModalContext"
    persistent
  >
    <div class="tw-mt-2 tw-uppercase tw-font-extralight tw-text-[1.25rem]">
      {{ confirmModalContext.heading }}
    </div>
    <div class="tw-mt-4 tw-font-extralight tw-text-[1rem]">
      {{ confirmModalContext.body }}
    </div>
    <template #footer>
      <div class="tw-flex tw-justify-end">
        <FpButton
          class="tw-mr-4"
          :accent="confirmModalContext.confirmAccent || 'red'"
          @click="confirmHandler"
        >
          {{ $t('global.confirm.continue') }}
        </FpButton>
        <FpButton
          accent="grey"
          @click="cancelHandler"
        >
          {{ $t('global.confirm.cancel') }}
        </FpButton>
      </div>
    </template>
  </FpModal>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import FpButton, { type FpButtonAccent } from '@/components/FpButton.vue'
import FpModal from '@/components/FpModal.vue'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'

export interface ConfirmModalContext {
  heading: string
  body: string
  confirmCallback: () => void
  cancelCallback?: () => void
  confirmAccent?: FpButtonAccent
}

const { confirmModalContext } = storeToRefs(useConfirmModalStore())
const { closeConfirmModal } = useConfirmModalStore()

const cancelHandler = () => {
  confirmModalContext.value?.cancelCallback?.()
  closeConfirmModal()
}

const confirmHandler = () => {
  confirmModalContext.value?.confirmCallback()
  closeConfirmModal()
}
</script>
