<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      style="fill: #f6b8b5"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #f28c88"
    />
    <path
      d="M5.034 23.836c2.276 0 1.507 3.79 2.738 3.79 1.233 0 1.233-7.887 2.466-7.887 1.234 0 1.234 10.535 2.467 10.535 1.234 0 1.234-14.994 2.467-14.994 1.234 0 1.234 19.59 2.468 19.59.623 0 .93-5.626 1.242-11.196v-.003c.315-6.263.585-12.648 1.214-12.648 1.234 0 1.234 23.958 2.468 23.958.802 0 1.082-9.336 1.52-15.863.234-3.508 1.01 4.771 1.581 4.771h2.377"
      class="st2"
    />
    <circle
      cx="39.228"
      cy="30.03"
      r="2.7"
      class="st2"
    />
    <path
      d="m37.351 28.093-9.45-12.928"
      class="st2"
    />
    <circle
      cx="29.365"
      cy="30.03"
      r="2.7"
      class="st2"
    />
    <path
      d="m31.243 28.093 9.45-12.928"
      class="st2"
    />
  </svg>
</template>
<style>
.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
</style>
