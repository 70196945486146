<template>
  <svg
    version="1.1"
    class="bg-svg"
    viewBox="0 0 440 90"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="bg-svg-1"
      d="M 15.623 88.1 C 74.623 87.5 133.623 86.8 192.723 85.9 C 245.423 85.1 298.123 84.2 350.823 83.2 C 379.023 82.7 407.223 82.1 435.423 81.6 C 438.223 81.5 433.023 44.7 432.523 41.7 C 431.123 32.5 429.623 23.1 427.423 14.1 C 427.023 12.3 425.423 3 423.523 3 C 372.723 4 321.923 5 271.123 5.9 C 216.123 6.9 161.123 7.7 106.123 8.4 C 72.023 8.8 37.923 9.2 3.823 9.5 C 1.023 9.5 6.223 46.4 6.723 49.4 C 8.123 58.7 9.623 68 11.823 77 C 12.123 78.8 13.723 88.1 15.623 88.1 L 15.623 88.1 Z"
    />
  </svg>
</template>
