<template>
  <FpModal
    :title="$t(`addEditCompanyModal.${props.editContext ? 'edit' : 'add'}.heading`)"
    size="large"
    @close="$emit('close')"
  >
    <div class="tw-grid tw-grid-cols-12 tw-gap-5">
      <FpInputFormGroup
        v-model="formValues.name"
        class="tw-col-span-12 sm:tw-col-span-5"
        :label="$t('profile.companyName.label')"
        :placeholder="$t('profile.companyName.placeholder')"
      />
      <div class="tw-col-span-12 sm:tw-col-span-8">
        <FpInputFormGroup
          v-model="formValues.addressLine1"
          :label="$t('profile.address.label')"
          :placeholder="$t('profile.address.placeholderFirst')"
          class="tw-mb-2"
        />
        <FpInput
          v-model="formValues.addressLine2"
          :placeholder="$t('profile.address.placeholderSecond')"
        />
      </div>
      <FpInputFormGroup
        v-model="formValues.postalCode"
        class="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-5"
        :label="$t('profile.zipCode.label')"
        :placeholder="$t('profile.zipCode.placeholder')"
      />
      <FpInputFormGroup
        v-model="formValues.city"
        class="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-5"
        :label="$t('profile.city.label')"
        :placeholder="$t('profile.city.placeholder')"
      />
      <FpInputFormGroup
        v-model="formValues.state"
        class="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-5"
        :label="$t('profile.state.label')"
        :placeholder="$t('profile.state.placeholder')"
      />
      <FpSelectFormGroup
        v-model="formValues.countryCode"
        class="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-5"
        :options="getCountrySelectOptions()"
        :label="$t('profile.country.label')"
        :placeholder="$t('profile.country.placeholder')"
      />
      <FpInputFormGroup
        v-model="formValues.cvr"
        class="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-5"
        :label="$t('profile.vat.label')"
        :placeholder="$t('profile.vat.placeholder')"
      />
      <div class="tw-col-span-12 sm:tw-col-span-8">
        <h6 class="tw-font-medium tw-text-lg">{{ $t('addEditCompanyModal.signatory.heading') }}</h6>
        <p>
          {{ $t('addEditCompanyModal.signatory.body') }}
          <span class="tw-ml-1 tw-italic tw-text-muted"> {{ $t('addEditCompanyModal.signatory.muted') }}</span>
        </p>
      </div>
    </div>
    <template #footer>
      <div class="tw-flex tw-justify-end tw-gap-4">
        <FpButton
          accent="blue"
          :loading="submitting"
          @click="submit"
        >
          {{ $t('addEditCompanyModal.submitButton') }}
        </FpButton>
        <FpButton
          accent="grey"
          :disabled="submitting"
          @click="$emit('close')"
        >
          {{ $t('global.close') }}
        </FpButton>
      </div>
    </template>
  </FpModal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { createCompany, editCompany } from '@/api/api'
import FpButton from '@/components/FpButton.vue'
import FpInput from '@/components/FpInput.vue'
import FpInputFormGroup from '@/components/FpInputFormGroup.vue'
import FpModal from '@/components/FpModal.vue'
import FpSelectFormGroup from '@/components/FpSelectFormGroup.vue'
import { getCountryNameFromCountryCode, getCountrySelectOptions } from '@/services/CountryService'
import { useToastStore } from '@/stores/toast-store'
import type { CompanyDto } from '@/types/api'

const props = defineProps<{
  editContext?: CompanyDto
}>()

const formValues = ref<{
  name: string
  cvr: string
  countryCode: string | null
  city: string
  region: string
  postalCode: string
  addressLine1: string
  addressLine2: string
  country: string
  state: string
}>(
  props.editContext
    ? {
        name: props.editContext.name || '',
        cvr: props.editContext.cvr || '',
        countryCode: props.editContext.countryCode || null,
        city: props.editContext.city || '',
        region: props.editContext.region || '',
        postalCode: props.editContext.postalCode || '',
        addressLine1: props.editContext.addressLine1 || '',
        addressLine2: props.editContext.addressLine2 || '',
        country: props.editContext.country || '',
        state: props.editContext.state || '',
      }
    : {
        name: '',
        cvr: '',
        countryCode: null,
        city: '',
        region: '',
        postalCode: '',
        addressLine1: '',
        addressLine2: '',
        country: '',
        state: '',
      },
)

const emit = defineEmits<{
  close: [void]
  create: [void]
  edit: [CompanyDto]
}>()

const { toast } = useToastStore()

const submitting = ref(false)

const getPayload = () => {
  return {
    ...formValues.value,
    country: getCountryNameFromCountryCode(formValues.value.countryCode),
  }
}

const submit = async () => {
  submitting.value = true
  try {
    if (props.editContext) {
      const response = await editCompany(props.editContext.id!, getPayload())
      emit('edit', response)
      emit('close')
      return
    }
    await createCompany(getPayload())
    emit('create')
    emit('close')
  } catch (e) {
    toast('Something went wrong', 'error')
    submitting.value = false
  }
}
</script>
