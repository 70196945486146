<template>
  <div class="tw-bg-bgGrey tw-pb-20">
    <FpContainer class="tw-pt-10">
      <FpHeadingWithContractIcon>
        {{ $t('settings.headline') }}
      </FpHeadingWithContractIcon>
      <FpHeadingWithIconAndSubline>
        {{ $t('settings.subheadings.security') }}
      </FpHeadingWithIconAndSubline>
      <div class="tw-grid tw-grid-cols-2 tw-max-w-[500px] tw-mt-6 tw-gap-y-6">
        <div>
          {{ $t('settings.labels.username') }}
        </div>
        <div>
          {{ user!.userName }}
          <FpButton
            class="tw-mt-1"
            size="small"
            accent="green"
            :href="`${identityServerBaseUrl}/Account/changeusername`"
            target="_blank"
          >
            {{ $t('settings.buttons.editUsername') }}
          </FpButton>
        </div>
        <div>
          {{ $t('settings.labels.email') }}
        </div>
        <div>
          {{ user!.email }}
          <FpButton
            class="tw-mt-1"
            :href="`${identityServerBaseUrl}/Account/startchangeemailflow`"
            size="small"
            accent="green"
            target="_blank"
          >
            {{ $t('settings.buttons.editEmail') }}
          </FpButton>
        </div>
        <div>
          {{ $t('settings.labels.password') }}
        </div>
        <div>
          <FpButton
            :href="`${identityServerBaseUrl}/Account/ChangePassword`"
            size="small"
            accent="green"
            target="_blank"
          >
            {{ $t('settings.buttons.editPassword') }}
          </FpButton>
          <FpButton
            class="tw-mt-2"
            :href="`${identityServerBaseUrl}/Account/resetpassword`"
            size="small"
            accent="red"
            target="_blank"
          >
            {{ $t('settings.buttons.resetPassword') }}
          </FpButton>
        </div>
      </div>
      <FpHeadingWithIconAndSubline class="tw-mb-6">
        {{ $t('settings.subheadings.language') }}
      </FpHeadingWithIconAndSubline>

      {{ $t('settings.descriptions.language') }}

      <div class="tw-flex tw-mb-5 tw-gap-4 tw-mt-6">
        <div class="tw-w-[50px] tw-cursor-pointer">
          <LangDk @click="setLanguage('da')" />
        </div>
        <div class="tw-w-[50px] tw-cursor-pointer">
          <LangEn @click="setLanguage('en')" />
        </div>
      </div>

      <FpHeadingWithIconAndSubline class="tw-mb-6">
        {{ $t('settings.subheadings.deleteAccount') }}
      </FpHeadingWithIconAndSubline>

      <p class="tw-mb-6">
        {{ $t('settings.descriptions.deleteAccount') }}
      </p>

      <FpButton
        accent="red"
        size="small"
        @click="confirmDeleteAccount"
      >
        {{ $t('settings.buttons.deleteAccount') }}
      </FpButton>
    </FpContainer>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { deleteAccount } from '@/api/api'
import FpButton from '@/components/FpButton.vue'
import FpContainer from '@/components/FpContainer.vue'
import FpHeadingWithContractIcon from '@/components/FpHeadingWithContractIcon.vue'
import FpHeadingWithIconAndSubline from '@/components/FpHeadingWithIconAndSubline.vue'
import { setLanguage } from '@/lang'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'
import LangDk from '@/svg/LangDk.vue'
import LangEn from '@/svg/LangEn.vue'

const { user } = storeToRefs(useAuthStore())
const { logOut } = useAuthStore()

const { showConfirmModal } = useConfirmModalStore()

const { t } = useI18n()
const confirmDeleteAccount = () => {
  showConfirmModal({
    heading: t('settings.confirms.deleteAccount.heading'),
    body: t('settings.confirms.deleteAccount.body'),
    confirmCallback: async () => {
      await deleteAccount()
      await logOut()
    },
  })
}

const identityServerBaseUrl = import.meta.env.VITE_IDENTITY_SERVER_URL
</script>
