<template>
  <svg
    version="1.1"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-miterlimit="10"
  >
    <line
      x1="11.768"
      y1="19.828"
      x2="36.068"
      y2="19.828"
    />
    <polyline points="31.268 13.328 36.268 19.828 31.268 26.328" />
    <path
      d="M 28.868 25.828 L 28.868 34.228 C 28.868 35.028 28.268 35.628 27.468 35.628 L 6.268 35.628 C 5.468 35.628 4.868 35.028 4.868 34.228 L 4.868 6.028 C 4.868 5.228 5.468 4.628 6.268 4.628 L 27.468 4.628 C 28.268 4.628 28.868 5.228 28.868 6.028 L 28.868 14.728"
    />
  </svg>
</template>
