<template>
  <div class="tw-grid tw-grid-cols-12 tw-p-4 tw-bg-light tw-rounded-b-lg">
    <div
      class="tw-col-span-12 md:tw-col-span-6 tw-flex md:tw-justify-end tw-border-b md:tw-border-b-0 md:tw-border-r tw-border-inputBorderGrey tw-pb-4 tw-mb-4 md:tw-pb-0 md:tw-mb-0 md:tw-pr-4 md:tw-mr-4"
    >
      <div class="tw-flex tw-items-center">
        <div class="tw-flex tw-flex-col tw-font-semibold tw-gap-1 tw-items-center tw-mx-2 md:tw-mx-6">
          <IconPicker
            class="tw-w-[80px]"
            icon="me"
          />
          {{ $t('contractSummary.you') }}
        </div>

        <div class="tw-flex tw-flex-col tw-text-[1.25rem]">
          <h3 class="tw-uppercase tw-font-medium">
            {{ $t(`contractSummary.roleRepresentatives.${currentUserInfo?.roleId}`) }}
          </h3>
          <div>{{ $t('contractSummary.negotiator', { name: currentUserInfo?.negotiator }) }}</div>
          <div class="tw-flex tw-items-center tw-gap-4">
            {{ $t('contractSummary.part') }}
            <FpSelect
              :model-value="selectedPartyId"
              class="tw-flex-1"
              :options="partyOptions"
              :disabled="disabled"
              @update:model-value="onUserPartySelected"
            />
          </div>
          <div>{{ $t('contractSummary.entitledToSign', { name: currentUserInfo?.signer }) }}</div>
        </div>
      </div>
    </div>
    <div class="tw-col-span-12 md:tw-col-span-6 tw-flex md:tw-justify-start tw-items-center">
      <div class="tw-flex tw-flex-col tw-font-semibold tw-gap-1 tw-items-center tw-mx-2 md:tw-mx-6">
        <IconPicker
          class="tw-w-[80px]"
          icon="you"
        />
        {{ $t('contractSummary.counterpart') }}
      </div>

      <div
        v-if="otherPartyInfo"
        class="tw-flex tw-flex-col tw-text-[1.25rem]"
      >
        <h3 class="tw-uppercase tw-font-medium">
          {{ $t(`contractSummary.roleRepresentatives.${otherPartyInfo.roleId}`) }}
        </h3>
        <div>{{ $t('contractSummary.negotiator', { name: otherPartyInfo.negotiator }) }}</div>
        <div>{{ $t('contractSummary.part', { name: otherPartyInfo.party }) }}</div>
        <div>{{ $t('contractSummary.entitledToSign', { name: otherPartyInfo.signer }) }}</div>
      </div>
      <div
        v-else
        class="tw-flex tw-flex-col tw-h-full tw-w-full"
      >
        <h3 class="tw-uppercase tw-font-medium tw-text-[1.25rem]">
          {{ $t(`contractSummary.roleRepresentatives.${contract.counterpartRoleId}`) }}
        </h3>
        <FpInput
          id="invitationEmailInput"
          class="tw-mt-0.5 tw-w-full tw-max-w-[400px]"
          :model-value="emailOfCounterPart"
          :disabled="invitationStatus?.status === 'InvitationIkkeBesvaret'"
          :placeholder="$t('contractSummary.placeholders.emailToCounterpart')"
          :has-error="emailHasError"
          @update:model-value="$emit('update:emailOfCounterPart', $event)"
        />
        <p v-if="invitationStatus?.status === 'InvitationIkkeBesvaret'">
          {{ $t('contractSummary.invitationHasBeenSentToCounterpart') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { fetchMyAssociatedCompanies, setPartyForContract } from '@/api/api'
import FpInput from '@/components/FpInput.vue'
import FpSelect, { type FpSelectOption } from '@/components/FpSelect.vue'
import IconPicker from '@/components/IconPicker.vue'
import { useAuthStore } from '@/stores/auth-store'
import { useToastStore } from '@/stores/toast-store'
import type { CompanyDto, ContractDto, CounterPartInvitationStatusDto } from '@/types/api'

const props = defineProps<{
  contract: ContractDto
  emailOfCounterPart: string
  invitationStatus: CounterPartInvitationStatusDto | null
  disabled?: boolean
  emailHasError?: boolean
}>()

defineEmits<{
  'update:emailOfCounterPart': [string]
}>()

const associatedCompanies = ref<CompanyDto[]>(await fetchMyAssociatedCompanies())

const authStore = useAuthStore()

const { t } = useI18n()

/**
 * This is the users associatedCompanies + an option for signing as themselves privately
 */
const partyOptions = computed<FpSelectOption[]>(() => {
  const options: FpSelectOption[] = [
    {
      value: null,
      label: t('contractSummary.companyOptionsPlaceholder'),
      disabled: true,
    },
  ]
  options.push(
    ...associatedCompanies.value.map((company) => ({
      value: company.id,
      label: company.name!,
    })),
  )
  options.push({
    value: authStore.user!.id,
    label: authStore.fullName!,
  })
  return options
})

const { toast } = useToastStore()

const onUserPartySelected = async (id: string) => {
  const oldPartyKey = selectedPartyId.value
  selectedPartyId.value = id
  try {
    await setPartyForContract(id, props.contract.id)
    toast(t('contractSummary.toasts.questionUpdated'), 'success')
  } catch (error) {
    selectedPartyId.value = oldPartyKey
    toast(t('contractSummary.toasts.genericError'), 'error')
  }
}

/**
 * Can be either owner og counterpart, depending on who is currently logged in.
 */
const currentUserInfo = computed<{
  negotiator: string
  partyId: string | null
  party: string | null
  signer: string
  roleId: string
}>(() => {
  if (currentUserIsCounterpart.value) {
    return {
      // contractId: props.contract.counter
      negotiator: props.contract.counterpartNegotiatorName!,
      partyId: props.contract.counterPartyId,
      party: props.contract.counterPartyName!,
      signer: props.contract.counterpartNegotiatorName!,
      roleId: props.contract.counterpartRoleId,
    }
  }
  return {
    negotiator: props.contract.negotiatorName!,
    partyId: props.contract.partyId || null,
    party: props.contract.partyName,
    signer: props.contract.negotiatorName!,
    roleId: props.contract.roleId,
  }
})

/**
 * Can be either owner og counterpart, depending on who is currently logged in.
 */
const otherPartyInfo = computed<null | {
  negotiator: string | null
  party: string | null
  signer: string | null
  roleId: string
}>(() => {
  if (!hasCounterpart.value) {
    return null
  }
  if (currentUserIsCounterpart.value) {
    return {
      negotiator: props.contract.negotiatorName!,
      party: props.contract.partyName!,
      signer: props.contract.negotiatorName!,
      roleId: props.contract.roleId,
    }
  }
  return {
    negotiator: props.contract.counterpartNegotiatorName!,
    party: props.contract.counterPartyName!,
    signer: props.contract.counterpartNegotiatorName!,
    roleId: props.contract.counterpartRoleId,
  }
})

const hasCounterpart = computed<boolean>(() => !!props.contract.counterpartNegotiatorId)

const currentUserIsCounterpart = computed(() => {
  return authStore.user!.id !== props.contract.negotiatorId
})

const selectedPartyId = ref<string | null>(currentUserInfo.value?.partyId || null)
</script>
