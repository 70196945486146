<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.534"
    height="31.21"
    viewBox="0 0 35.534 31.21"
  >
    <g
      id="Group_1581"
      transform="translate(-562.704 -997.16)"
    >
      <path
        id="Path_4647"
        d="M290.8,425.987l9.52,9.52,18.67-18.67"
        transform="translate(278.785 583.333)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.3"
      />
      <path
        id="Path_4711"
        d="M317.1,428.99c.046.481.07.969.07,1.462a14.955,14.955,0,1,1-4.025-10.207"
        transform="translate(276.09 582.313)"
        fill="none"
        stroke="currentColor"
        stroke-width="1.3"
      />
    </g>
  </svg>
</template>
