<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="375"
    height="132.313"
    viewBox="0 0 375 132.313"
  >
    <path
      id="Path_4447"
      data-name="Path 4447"
      d="M2743.641,1281.655l-39.433,9.167c-4.106-10.073-11.223-22.073-14.534-21.446l-21.836,4.135c-2-9.566-12.669-31.268-17.221-30.265-16.32,3.6-31.562,10.458-46.861,17.075-3.219-5.441-6.495-9.39-8.333-8.8l-16.253,5.26c-3.844-9.872-10.139-22.881-13.969-21.436a239.562,239.562,0,0,1-29.53,8.993c-3.939-7.841-8.824-14.806-11.487-14.354-36.162,6.151-68.654,23.228-100.627,40.4-2.331,1.253,1.082,10.747,5.513,19.488a387.082,387.082,0,0,1-42.431,16.507c-2.866.893,10.666,36.549,16.931,34.6,21.692-6.759,42.334-16.357,62.978-25.9,2.546,10.485,13.838,30.309,17.021,28.83,12.062-5.6,24.643-5.942,37.356-5.133,18.18,9.517,42.709,5.779,62.006,3.125,8.793-1.207,17.505-2.847,26.153-4.776.924,1.321,1.871,2.6,2.86,3.756,7.762,9.094,22.051,5.709,32.5,4.391q6.6-.831,13.188-1.794c2.482,4.008,4.912,6.622,6.684,6.041s3.492-1.208,5.225-1.836c3.492,8.06,7.814,15.312,11.087,14.536q22.151-5.25,44.3-10.5c.881-.209-.348-5.347-2.6-11.6,11.765-9.038,27.055-13.878,38.241-23.87C2764.686,1312.579,2748.81,1280.453,2743.641,1281.655Z"
      transform="translate(-2386.245 -1229.969)"
      fill="#5dbfa2"
    />
  </svg>
</template>
