<template>
  <svg
    version="1.1"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-miterlimit="10"
  >
    <path
      d="M 28.171 22.046 L 28.171 33.446 C 28.171 34.246 27.571 34.846 26.771 34.846 L 5.571 34.846 C 4.771 34.846 4.171 34.246 4.171 33.446 L 4.171 5.246 C 4.171 4.446 4.771 3.846 5.571 3.846 L 26.771 3.846 C 27.571 3.846 28.171 4.446 28.171 5.246 L 28.171 7.946"
    />
    <path
      stroke-linejoin="round"
      d="M 17.471 26.946 L 9.671 29.146 L 13.871 22.246 L 32.971 7.746 C 33.571 7.346 34.271 7.446 34.771 7.946 L 36.871 10.646 C 37.171 11.246 37.071 12.046 36.471 12.446 L 17.471 26.946 Z"
    />
    <line
      x1="13.871"
      y1="22.246"
      x2="17.471"
      y2="26.946"
    />
    <line
      x1="34.771"
      y1="13.746"
      x2="31.171"
      y2="9.046"
    />
  </svg>
</template>
