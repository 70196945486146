<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xml:space="preserve"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-miterlimit: 10"
    viewBox="0.5 0.3 16.82 15.92"
  >
    <g transform="matrix(0.481273,0,0,0.481273,0.518727,0.414981)">
      <g>
        <path
          d="M25,19L25,30.4C25,31.2 24.4,31.8 23.6,31.8L2.4,31.8C1.6,31.8 1,31.2 1,30.4L1,2.2C1,1.4 1.6,0.8 2.4,0.8L23.6,0.8C24.4,0.8 25,1.4 25,2.2L25,4.9M14.3,23.9L6.5,26.1L10.7,19.2L29.8,4.7C30.4,4.3 31.1,4.4 31.6,4.9L33.7,7.6C34,8.2 33.9,9 33.3,9.4L14.3,23.9ZM10.7,19.2L14.3,23.9M31.6,10.7L28,6"
          style="fill: none; fill-rule: nonzero; stroke: currentColor; stroke-width: 1px"
        />
      </g>
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
