<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 46.5"
  >
    <path
      style="fill: #285a8b"
      d="M31.3,38.9c-7.7-4.1-23.8-5.1-23.8-5.1l3.3-23.1L0,13l11.9,33.5c0,0,18.2-1.6,19.9-6.2
				c0.1-0.2,0.1-0.3,0.1-0.5c0,0,0,0,0,0C31.9,39.4,31.8,39.2,31.3,38.9z"
    />
    <path
      style="fill: #457fee"
      d="M32,6c0-1.4,0.2-2.5-2.3-3.7C24.7,0,12.3,0,12.3,0l-1.5,10.6L7.5,33.7c0,0,16,1,23.8,5.1
				c0.6,0.3,0.7,0.5,0.7,1c0,0,0,0,0,0v-1.3C32,36.4,32,7.7,32,6z M21.2,29.2c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.1-0.6,0.1
				c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.3
				c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.5,0-0.7c0.1-0.3,0.3-0.4,0.5-0.4c0.2-0.1,0.5-0.1,0.7,0c0.1,0,0.3,0,0.4,0.1
				c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.4,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4
				c0,0.2,0,0.3,0.1,0.5C21.3,29,21.3,29.1,21.2,29.2z M21.7,25.1c0,1.1-2.8,0-2.8-1c0.1-5.4,0.3-10.8,0.5-16.2c0-1.1,2.8,0,2.8,1
				C22,14.3,21.8,19.7,21.7,25.1z"
    />
    <path
      style="fill: #ffffff"
      d="M19.4,7.9c-0.1,5.4-0.3,10.8-0.5,16.2c0,1,2.7,2.1,2.8,1c0.1-5.4,0.3-10.8,0.5-16.2
				C22.2,7.9,19.4,6.8,19.4,7.9z"
    />
    <path
      style="fill: #ffffff"
      d="M21.2,28.4c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.5,0-0.7,0c-0.2,0.1-0.5,0.2-0.5,0.4
				c-0.1,0.2-0.1,0.5,0,0.7c0,0.2,0.1,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.2
				c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.4,0c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.4
				C21.2,28.7,21.2,28.5,21.2,28.4z"
    />
  </svg>
</template>
