<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_5386_00000152239045516999157730000013818267171055974272_"
      d="M13 3 3 13v29h31V3H13z"
      style="fill: #f6b8b5"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #f28c88"
    />
    <path
      d="M37.5 22.5v-14l-10-2V18M8.5 16v11M7 18.5h3M12.5 16v11M11 23.5h3M16.5 16v11M15 20.5h3"
      class="st2"
    />
    <path
      d="M24.48 38.746c1.097 0 1.097-11.672 2.194-11.672s1.097 11.672 2.194 11.672 1.097-8.955 2.194-8.955c1.098 0 1.098 6.634 2.195 6.634 1.097 0 1.097-5.554 2.195-5.554 1.097 0 1.097 4.5 2.194 4.5s1.097-3.657 2.194-3.657c1.095 0 1.157 2.04 2.757 2.04h1.421"
      class="st3"
    />
    <path
      d="M28.937 38.746c-1.097 0-1.097-11.672-2.194-11.672-1.098 0-1.098 11.672-2.195 11.672-1.097 0-1.097-8.955-2.194-8.955s-1.097 6.634-2.195 6.634c-1.097 0-1.097-5.554-2.194-5.554-1.098 0-1.098 4.5-2.195 4.5-1.096 0-1.096-3.657-2.193-3.657-1.096 0-1.158 2.04-2.757 2.04H8.246"
      class="st3"
    />
    <circle
      cx="24.5"
      cy="17.5"
      r="3"
      class="st2"
    />
    <circle
      cx="34.5"
      cy="22.5"
      r="3"
      class="st2"
    />
  </svg>
</template>
<style>
.st2,
.st3 {
  fill: none;
  stroke: #1d1e1c;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
.st3 {
  stroke: #1d1d1b;
  stroke-linecap: round;
}
</style>
