<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 38.394 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Line_338_00000106110346906250348490000009454255832377496505_"
      d="M10.995 3.515h6.27"
      class="st0"
    />
    <path
      id="Line_338_00000010277720652747446440000005736817150836766609_"
      d="M1.896 3.515h6.271"
      class="st0"
    />
    <path
      id="Line_339_00000022555314839038044200000007845254137642606498_"
      d="M9.581 4.786v12.628"
      class="st0"
    />
    <path
      id="Line_339_00000052102554906323296620000002716840480665478304_"
      d="M9.581.177v1.781"
      class="st0"
    />
    <path
      id="Path_5479_00000002384178117770163870000003472210857758803125_"
      d="M10.995 3.372A1.414 1.414 0 1 1 9.58 1.958c.78 0 1.414.633 1.414 1.414z"
      class="st0"
    />
    <path
      id="Path_5480_00000106843615085568719140000014022766946300982420_"
      d="m4.262 3.663 3.03 6.627v.738c0 1.5-1.216 2.716-2.716 2.716h-.628a2.716 2.716 0 0 1-2.716-2.716v-.738l3.03-6.627z"
      class="st1"
    />
    <path
      id="Line_340_00000080920139738271159570000006085040436335023529_"
      d="M1.232 10.29h6.061"
      class="st0"
    />
    <path
      id="Path_5481_00000062872277421692615910000012346789568805972124_"
      d="m14.899 3.663 3.033 6.627v.738c0 1.5-1.217 2.716-2.717 2.716h-.628a2.717 2.717 0 0 1-2.717-2.716v-.738l3.029-6.627z"
      class="st1"
    />
    <path
      id="Line_341_00000169537360759139024200000004054526747466797474_"
      d="M11.869 10.29h6.061"
      class="st0"
    />
    <path
      id="Path_5482_00000100356345423755211760000005630606103433082809_"
      d="M12.558 17.414H6.604a2.528 2.528 0 0 0-2.528 2.528v.335h11.01v-.34a2.528 2.528 0 0 0-2.528-2.523z"
      class="st0"
    />
    <path
      id="Path_5483_00000051377608561058922040000009218635306914154415_"
      d="M18.52 6.383c2.495-4.975 8.55-6.985 13.525-4.49s6.986 8.55 4.49 13.525c-2.494 4.975-8.55 6.985-13.525 4.49a10.077 10.077 0 0 1-4.709-4.956"
      class="st2"
    />
    <path
      id="Path_5484_00000016036264839948976460000013020954288129402283_"
      d="M35.215 4.377a14.24 14.24 0 0 1-7.684 2 14.239 14.239 0 0 1-7.683-2"
      class="st2"
    />
    <path
      id="Path_5485_00000102540841108185927240000006399744766940165254_"
      d="M19.984 17.564a14.387 14.387 0 0 1 7.55-1.914 14.385 14.385 0 0 1 7.55 1.915"
      class="st2"
    />
    <ellipse
      id="Ellipse_453_00000009574085723906822520000007769064389914006686_"
      cx="27.531"
      cy="10.899"
      class="st2"
      rx="4.546"
      ry="10.078"
    />
    <path
      id="Line_342_00000136388678035194464930000003300517411349374373_"
      d="M27.531.822v20.155"
      class="st2"
    />
    <path
      id="Line_343_00000112628815759978716850000014045089343296737189_"
      d="M20.348 10.563h17.261"
      class="st2"
    />
  </svg>
</template>
<style>
.st0,
.st1,
.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
}
.st0 {
  stroke-miterlimit: 10;
}
.st1,
.st2 {
  stroke-linejoin: bevel;
}
.st2 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
