<template>
  <div
    class="tw-text-5xl tw-py-10 tw-flex tw-justify-center tw-relative tw-bg-bgGrey"
    style="height: calc(100vh - 60px)"
  >
    <FpSpinner />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import FpSpinner from '@/components/FpSpinner.vue'
import { type OAuthState, fetchAccessToken } from '@/services/OAuthService'
import { useToastStore } from '@/stores/toast-store'

const route = useRoute()
const router = useRouter()

/**
 * For some reason, we get weird errors if we do this directly in the setup function.
 */
onMounted(async () => {
  if (route.query.error) {
    await router.replace('/')
    useToastStore().toast('Der skete en fejl under login', 'error')
  } else {
    const user = await fetchAccessToken()
    if (user.state) {
      await router.replace((user.state as OAuthState).redirectUrl)
      return
    }
    await router.replace('/dashboard')
    return
  }
})
</script>
