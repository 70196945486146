import { createI18n } from 'vue-i18n'
import langDA from '@/lang/langDA.json'
import langEN from '@/lang/langEN.json'

export type SupportedLocales = 'da' | 'en'

export const DEFAULT_LOCALE: SupportedLocales = (localStorage.getItem('fp-lang') as SupportedLocales)
  ? (localStorage.getItem('fp-lang') as SupportedLocales)
  : navigator.language.includes('da')
  ? 'da'
  : 'en'

// The line below enables type checking on localization files. This is really nice if you're trying to make sure all
// locales work as expected - but also a pain in the ass if you're just trying to build without worrying about
// localization.
// export const i18n = createI18n<[typeof langEN], SupportedLocales>({

export const i18n = createI18n<[typeof langEN], SupportedLocales>({
  legacy: false,
  locale: DEFAULT_LOCALE,
  fallbackLocale: 'en',
  messages: {
    en: langEN,
    da: langDA,
  },
})

export const setLanguage = (lang: SupportedLocales): void => {
  // @ts-ignore
  i18n.global.locale.value = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  localStorage.setItem('fp-lang', lang)
}
