<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="3.75 4.75 10.4 7.4"
  >
    <path
      style="fill: none; stroke-width: 1.5; stroke-miterlimit: 10"
      stroke="currentColor"
      d="M4.5,8.4l3,3l5.9-5.9"
    />
  </svg>
</template>
