<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_5386_00000152239045516999157730000013818267171055974272_"
      d="M13 3 3 13v29h31V3H13z"
      style="fill: #bfd6e7"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #8cb6d3"
    />
    <path
      d="M26.722 19.869h-3.809"
      class="st2"
    />
    <path
      d="M24.817 19.869v4.848"
      style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
    />
    <path
      d="M33.759 19.615v5.102M28.701 19.513l2.52 5.102 2.523-4.84M28.683 24.717v-5.355"
      class="st4"
    />
    <path
      d="M37.5 16.5v19h-17v-19"
      class="st2"
    />
    <path
      d="m37.5 20.5 5-4-6.508-6.077-3.956-1.5a3.412 3.412 0 1 1-6.824 0h0l-4.153 1.5-5.407 4.978L20.5 20.5"
      style="
        fill: none;
        stroke: #1d1d1b;
        stroke-width: 0.8;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      "
    />
    <path
      d="M12.796 17.453H9.63v-.79a.92.92 0 0 1 .92-.92h1.327a.92.92 0 0 1 .92.92v.79zM12.57 17.637c0 3.036 1.86 5.256 1.86 8.423 0 1.74-.72 4.332-.72 5.16s.587 2.052.587 2.892-.396 1.692-1.428 1.692H9.556c-1.032 0-1.428-.852-1.428-1.692s.588-2.064.588-2.892-.72-3.42-.72-5.16c0-3.167 1.86-5.387 1.86-8.423M8.306 24.314l5.523-1.299M8.429 29.084l5.584-1.06"
      class="st2"
    />
  </svg>
</template>
<style>
.st2,
.st4 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
.st2 {
  stroke-linecap: square;
}
.st4 {
  stroke-linejoin: bevel;
}
</style>
