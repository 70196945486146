<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #a3d3c2"
        />
        <path
          d="M34.5 13.2V24M34.5 7v4.4M32.5 11.5h4v2h-4z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="26.5"
          cy="28.6"
          r="2.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="m25.9 27.9-2.4-2.4M26.5 18.4V24M26.5 7v9.5M24.5 16.5h4v2h-4z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="6"
          cy="15"
          r="2.5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="14.9"
          cy="19.1"
          r="2.5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M17.4 19V4.1L8.5 3v12"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M20.5 28.5c-3 0-3 0-3 1 0 1.5-.6 3-1 3-1 0-1-8-2-8s-1 10-2 10-1-12-2-12-1 12-2 12-1-10-2-10-1 8-2 8c-.4 0-1-1.7-1-3 0-1-.2-1-3-1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <circle
          cx="34.1"
          cy="28.6"
          r="2.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="m34.7 27.9 2.4-2.4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
