<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 376 140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style="fill: none"
      d="M 191.35 49.03 C 191.26 48.62 191.16 48.2 191.07 47.79 C 191.17 48.2 191.26 48.61 191.35 49.03 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 177.33 19.21 C 177.55 19.35 177.76 19.51 177.98 19.65 C 177.77 19.51 177.54 19.36 177.33 19.21 Z"
    />
    <path
      style="fill: #285a8c"
      d="M 177.3 19.2 C 177.3 19.2 177.3 19.2 177.3 19.2 C 177.31 19.21 177.32 19.21 177.33 19.22 C 177.32 19.21 177.31 19.2 177.3 19.2 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 30.49 33.45 C 22.86 30.54 9.57 24.56 0 20.16 L 0 80.54 C 4.51 76.8 9.22 72.65 13.82 68.22 C 24.5 57.92 35.77 45.42 43.68 36.33 C 39.16 36.11 34.69 35.14 30.49 33.45 Z"
    />
    <path
      style="fill: #5ebfa2"
      d="M 54.23 23.94 C 54.23 23.94 50.03 29.04 43.69 36.32 C 35.77 45.41 24.5 57.91 13.83 68.21 C 9.22 72.65 4.51 76.8 0 80.54 L 0 115.31 C 13.93 93.71 51.75 34.15 52.86 32.41 C 54.51 29.92 55.01 26.83 54.23 23.94 Z"
    />
    <path
      style="fill: none"
      d="M 333.92 64.67 C 330.97 65.25 328.08 65.83 325.28 66.4 C 328.09 65.83 330.97 65.25 333.92 64.67 Z"
    />
    <path
      style="fill: none"
      d="M 367.96 58.91 C 358.6 60.19 348.8 61.85 339.38 63.62 C 348.8 61.85 358.6 60.19 367.96 58.91 Z"
    />
    <path
      style="fill: #5478bc"
      d="M 325.28 66.41 C 328.08 65.83 330.97 65.25 333.92 64.68 C 335.72 64.33 337.54 63.98 339.38 63.63 C 348.8 61.86 358.6 60.19 367.96 58.92 C 368.57 58.84 369.19 58.75 369.8 58.67 C 371.88 58.4 373.94 58.15 376 57.92 L 376 57.92 C 376 57.92 376 57.92 376 57.92 L 376 36.02 C 339.3 50.12 298.93 65.7 297.17 66.38 C 293.3 67.8 290.22 70.81 288.71 74.65 C 288.71 74.65 297.54 72.44 310.55 69.56 L 310.55 69.56 C 315.03 68.56 320.01 67.49 325.28 66.41 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 369.8 58.66 C 369.19 58.74 368.57 58.83 367.96 58.91 C 358.6 60.19 348.8 61.85 339.38 63.62 C 337.54 63.97 335.72 64.32 333.92 64.67 C 330.97 65.25 328.08 65.83 325.28 66.4 C 320 67.48 315.02 68.55 310.55 69.54 C 310.55 69.54 310.55 69.54 310.55 69.54 C 315.4 73.49 319.55 78.23 322.82 83.56 C 333.07 99.56 354.06 138.51 354.06 138.51 L 376 125.31 L 376 57.91 C 373.94 58.14 371.87 58.39 369.8 58.66 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 325.28 66.41 C 320 67.49 315.02 68.56 310.55 69.55 L 310.55 69.55 C 310.55 69.55 310.55 69.55 310.55 69.55 C 315.03 68.56 320.01 67.49 325.28 66.41 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 339.38 63.63 C 337.54 63.98 335.72 64.33 333.92 64.68 C 335.72 64.32 337.54 63.97 339.38 63.63 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 369.8 58.66 C 369.19 58.74 368.57 58.83 367.96 58.91 C 368.57 58.83 369.19 58.74 369.8 58.66 C 371.88 58.39 373.94 58.14 376 57.91 L 376 57.91 C 373.94 58.14 371.87 58.39 369.8 58.66 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M262.69,101.79c-0.08-0.14-0.19-0.24-0.31-0.33c-0.04-0.03-0.08-0.05-0.11-0.07c-0.1-0.06-0.21-0.1-0.33-0.13 c-0.04-0.01-0.09-0.03-0.13-0.04c-0.16-0.03-0.34-0.04-0.54-0.04c-0.05,0-0.1,0.01-0.14,0.01c-0.16,0.01-0.33,0.03-0.51,0.06 c-0.08,0.01-0.16,0.03-0.24,0.04c-0.18,0.04-0.37,0.08-0.56,0.13c-0.07,0.02-0.13,0.03-0.2,0.05c-0.25,0.07-0.52,0.15-0.79,0.24 c-0.11,0.04-0.23,0.08-0.35,0.12c-0.18,0.06-0.36,0.12-0.54,0.19c-0.16,0.05-0.31,0.11-0.47,0.17c-0.06,0.02-0.13,0.05-0.19,0.07 c-0.21,0.08-0.42,0.15-0.64,0.23c-2.21,0.82-10.48,2.97-20.71,4.83l24.12,5.18c1.09-2.48,1.97-5.05,2.61-7.69 C263.05,103.29,263.03,102.34,262.69,101.79z"
    />
    <path
      style="fill: #285a8c"
      d="M258.3,97.36c-4.05-3.41-56.13-54.53-63.39-62.06c-4.85-5.03-10.5-11.39-16.93-15.64 c8.94,6.47,10.82,17.8,13.1,28.12c0.09,0.42,0.18,0.83,0.28,1.24c0.03,0.15,0.07,0.31,0.1,0.46c2.24,9.73,7.79,35.96,10.88,50.64 c1.3,6.16,2.16,10.29,2.16,10.3c9.85,0.23,21.54-1.29,31.42-3.09c10.23-1.86,18.5-4.02,20.71-4.83c0.22-0.08,0.43-0.16,0.64-0.23 c0.06-0.02,0.13-0.05,0.19-0.07c0.16-0.06,0.32-0.11,0.47-0.17c0.18-0.07,0.37-0.13,0.54-0.19c0.12-0.04,0.23-0.08,0.35-0.12 c0.27-0.09,0.54-0.17,0.79-0.24c0.07-0.02,0.13-0.03,0.2-0.05c0.19-0.05,0.38-0.09,0.56-0.13c0.08-0.02,0.16-0.03,0.24-0.04 c0.18-0.03,0.35-0.05,0.51-0.06c0.05,0,0.1-0.01,0.14-0.01c0.2-0.01,0.37,0.01,0.54,0.04c0.05,0.01,0.09,0.02,0.13,0.04 c0.12,0.03,0.23,0.08,0.33,0.13c0.04,0.02,0.08,0.04,0.11,0.07c0.12,0.09,0.23,0.19,0.31,0.33c0,0,0,0,0,0 C263.03,102.34,262.35,100.77,258.3,97.36z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 190.35 49.03 C 190.26 48.62 190.16 48.2 190.07 47.79 C 187.73 37.21 185.81 25.59 176.3 19.21 C 173.73 17.49 170.63 16.14 166.8 15.3 C 166.9 32.52 163.14 43.92 158.62 63.91 C 154.1 83.9 152.26 89.58 152.26 89.58 L 201.33 100.12 C 198.24 85.45 192.69 59.22 190.45 49.48 C 190.42 49.33 190.39 49.18 190.35 49.03 Z"
    />
  </svg>
</template>
