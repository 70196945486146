<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #a3d3c2"
        />
        <path
          d="M.5 19.5c4 0 5 0 5 1 0 1.7.3 3 1 3 1.3 0 1-10 2-10s2 15 3 15 1-18 2-18 2 23 3 23 1-8.2 1.3-13.8v0c.3-6.3.7-14.2 1.7-14.2s1 25 2 25 1-16 2-16 1.5 5 2 5h1.8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <circle
          cx="36.4"
          cy="26.9"
          r="2.7"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="m34.9 24.6-7-14.4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <circle
          cx="28.9"
          cy="26.8"
          r="2.7"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="M30.4 24.5 37.1 10"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
