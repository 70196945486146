<template>
  <div class="tw-w-full">
    <canvas
      id="canvas"
      class="tw-border tw-border-dashed tw-border-inputBorderGrey tw-rounded-lg tw-mb-4"
      :width="432"
    />
    <slot
      name="actions"
      :clear-function="clearSignature"
      :signature-pad="signaturePad"
    />
  </div>
</template>

<script lang="ts" setup>
import SignaturePad from 'signature_pad'
import { onMounted, ref } from 'vue'

const signaturePad = ref<SignaturePad | null>(null)

onMounted(() => {
  const canvas = document.getElementById('canvas')! as HTMLCanvasElement

  signaturePad.value = new SignaturePad(canvas)
  signaturePad.value.on()
})

const clearSignature = () => {
  signaturePad.value!.clear()
}
</script>
