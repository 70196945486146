<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      style="fill: none; stroke: currentColor; stroke-linejoin: round; stroke-miterlimit: 10"
      points="4.406 4.838 8.063 14.831 26.612 14.831"
    />
    <polygon
      style="fill: none; stroke: currentColor; stroke-linejoin: round; stroke-miterlimit: 10"
      points="4.406 24.823 8.063 14.831 26.612 14.831"
    />
  </svg>
</template>
