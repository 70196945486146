<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #5eaf8c"
    />
    <path
      d="m12.5 35.5 6-3M14 20.5l4.5 1M25.5 10.5v3.319"
      class="st2"
    />
    <path
      d="M31 37.5H18.5v-24h18V29"
      class="st3"
    />
    <path
      d="M32.5 26.5v-9l-8 2v12"
      class="st3"
    />
    <circle
      cx="35.647"
      cy="34.677"
      r="5.472"
      class="st3"
    />
    <path
      d="M37.901 36.682a3.04 3.04 0 1 1-.136-4.155"
      class="st3"
    />
    <circle
      cx="25.5"
      cy="8.5"
      r="2"
      class="st2"
    />
    <circle
      cx="11"
      cy="20"
      r="3"
      class="st3"
    />
    <circle
      cx="10"
      cy="36"
      r="2.5"
      class="st2"
    />
    <circle
      cx="22.5"
      cy="31.5"
      r="2"
      class="st2"
    />
    <circle
      cx="30.5"
      cy="26.5"
      r="2"
      class="st2"
    />
  </svg>
</template>
<style scoped lang="scss">
.st0 {
  fill: #97cbb5;
}

.st2,
.st3 {
  fill: none;
  stroke: #1d1e1c;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}

.st3 {
  stroke: #1d1d1b;
}
</style>
