<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      style="stroke: #000000"
      class="st0"
      d="M 35.552 27.079 L 35.552 39.855 C 35.552 40.727 34.832 41.447 33.96 41.447 L 10.663 41.447 C 9.791 41.447 9.071 40.727 9.071 39.855 L 9.071 8.919 C 9.071 8.047 9.791 7.327 10.663 7.327 L 33.96 7.327 C 34.832 7.327 35.552 8.047 35.552 8.919 L 35.552 11.516"
    />
    <path
      fill="none"
      style="stroke: #000000"
      class="st1"
      d="M 15.042 34.737 L 19.857 27.098 L 40.386 11.554 C 41.106 11.023 42.111 11.175 42.641 11.895 L 44.613 14.568 C 45.143 15.288 44.992 16.293 44.272 16.824 L 23.743 32.367 L 15.042 34.737 Z"
    />
    <line
      fill="none"
      style="stroke: #000000"
      class="st0"
      x1="19.857"
      y1="27.117"
      x2="23.743"
      y2="32.367"
    />
    <line
      fill="none"
      style="stroke: #000000"
      class="st0"
      x1="38.623"
      y1="12.881"
      x2="42.566"
      y2="18.207"
    />
  </svg>
</template>
