<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 46.5"
  >
    <path
      style="fill: #285a8b"
      d="M31.3,38.9c-7.7-4.1-23.8-5.1-23.8-5.1l3.3-23.1L0,13l11.9,33.5c0,0,18.2-1.6,19.9-6.2
				c0.1-0.2,0.1-0.3,0.1-0.5c0,0,0,0,0,0C31.9,39.4,31.8,39.2,31.3,38.9z"
    />
    <path
      style="fill: #457fee"
      d="M32,6c0-1.4,0.2-2.5-2.3-3.7C24.7,0,12.3,0,12.3,0l-1.5,10.6L7.5,33.7c0,0,16,1,23.8,5.1
				c0.6,0.3,0.7,0.5,0.7,1c0,0,0,0,0,0v-1.3C32,36.4,32,7.7,32,6z M20.1,29c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.3-0.3,0.5
				c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.1-0.8,0.3-1.2,0.4c-0.1,0-0.3,0-0.4-0.1c0-0.1-0.1-0.1-0.1-0.2
				c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.2,0.3-0.3
				c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.1,0.8-0.3,1.2-0.4c0.1,0,0.3,0,0.4,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.1,0.6
				C20.1,28.8,20.1,28.9,20.1,29z M27.4,12.5c-0.3,1-0.8,1.8-1.5,2.5c-1.1,1.1-2.6,1.7-3.6,3C21.4,19,21,20.2,21,21.6
				c0,0.7-0.2,1.5-0.7,2c-0.4,0.4-1,0.3-1-0.3c-0.1-2,0.3-4.1,1.3-5.9c0.8-1.5,2-2.6,3.4-3.6c0.6-0.4,1.2-0.8,1.7-1.3
				c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2c-0.7-0.2-1.6,0.2-2.3,0.4c-0.9,0.2-1.7,0.5-2.5,0.8
				c-1.7,0.6-3.3,1.4-4.8,2.4c-0.6,0.4-0.8-0.4-0.8-0.8c0-0.7,0.3-1.5,0.9-1.9c1.8-1.2,3.9-2.1,5.9-2.7c0.9-0.3,1.8-0.6,2.8-0.8
				c0.8-0.2,1.7-0.4,2.4,0.2C27.7,10.4,27.6,11.6,27.4,12.5z"
    />
    <path
      style="fill: #457fee"
      d="M25.9,12.5C25.9,12.5,25.9,12.5,25.9,12.5L25.9,12.5z"
    />
    <path
      style="fill: #ffffff"
      d="M24.6,9.5c-0.9,0.2-1.8,0.5-2.8,0.8c-2.1,0.7-4.1,1.5-5.9,2.7c-0.6,0.4-0.9,1.2-0.9,1.9
				c0,0.4,0.2,1.2,0.8,0.8c1.5-1,3.1-1.8,4.8-2.4c0.8-0.3,1.7-0.5,2.5-0.8c0.7-0.2,1.6-0.6,2.3-0.4c0.1,0,0.2,0.1,0.3,0.2
				c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.1c-0.5,0.5-1.1,0.9-1.7,1.3c-1.4,1-2.5,2.1-3.4,3.6c-1,1.8-1.4,3.8-1.3,5.9
				c0,0.6,0.6,0.7,1,0.3c0.5-0.5,0.7-1.3,0.7-2C21,20.2,21.4,19,22.3,18c1-1.2,2.5-1.8,3.6-3c0.7-0.7,1.2-1.6,1.5-2.5
				c0.2-0.9,0.3-2.1-0.4-2.7C26.3,9.1,25.4,9.3,24.6,9.5z M25.9,12.5C25.9,12.5,25.9,12.5,25.9,12.5L25.9,12.5z"
    />
    <path
      style="fill: #ffffff"
      d="M19.9,27.8c-0.1-0.1-0.2-0.2-0.4-0.1c-0.4,0.1-0.8,0.3-1.2,0.4c-0.1,0-0.2,0.1-0.3,0.2
				c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.2,0,0.4c0,0.2,0,0.4,0.1,0.6
				c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.1c0.4-0.1,0.8-0.3,1.2-0.4c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
				c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4-0.1-0.6C20,28,20,27.9,19.9,27.8z"
    />
  </svg>
</template>
