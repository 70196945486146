<template>
  <svg
    version="1.1"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-miterlimit="10"
  >
    <path
      d="M 29.715 26.597 L 29.715 33.697 C 29.715 34.497 29.115 35.097 28.315 35.097 L 7.115 35.097 C 6.315 35.097 5.715 34.497 5.715 33.697 L 5.715 5.497 C 5.715 4.697 6.315 4.097 7.115 4.097 L 28.315 4.097 C 29.115 4.097 29.715 4.697 29.715 5.497 L 29.715 7.197"
    />
    <circle
      cx="22.915"
      cy="19.797"
      r="2.4"
    />
    <line
      x1="11.215"
      y1="29.097"
      x2="21.015"
      y2="21.197"
    />
    <rect
      x="23.3"
      y="7.6"
      transform="matrix(0.630992, 0.77579, -0.77579, 0.630992, 21.907661, -14.278021)"
      stroke-linejoin="round"
      width="7.6"
      height="3.3"
    />
    <polyline
      stroke-linejoin="round"
      points="28.115 10.697 19.415 12.397 11.015 29.297 29.015 24.697 32.915 16.597"
    />
  </svg>
</template>
