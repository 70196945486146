import type { MissingInfoDto } from '@/types/MissingInfoDto'

export const isMissingInfoDtoMissingAnyInfo = (missingInfoDto: MissingInfoDto): boolean => {
  return (
    missingInfoDto.missingContractPartyFirstName ||
    missingInfoDto.missingContractPartyLastName ||
    missingInfoDto.missingContractPartyAddressLine1 ||
    missingInfoDto.missingContractPartyCity ||
    missingInfoDto.missingContractPartyPostalCode ||
    missingInfoDto.missingContractPartyCountry ||
    missingInfoDto.missingContractPartyCPRCVR ||
    missingInfoDto.missingCounterPartyAddressLine1 ||
    missingInfoDto.missingCounterPartyFirstName ||
    missingInfoDto.missingCounterPartyLastName ||
    missingInfoDto.missingCounterPartyCity ||
    missingInfoDto.missingCounterPartyPostalCode ||
    missingInfoDto.missingCounterPartyCountry ||
    missingInfoDto.missingCounterPartyCPRCVR
  )
}
