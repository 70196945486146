<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20.1"
          cy="20"
          r="19"
          style="fill: #cbddeb"
        />
        <path
          d="M10.5 14.5h-4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
        <path
          d="M8.5 14.4v5.1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M17.5 19.6v-5.4L15 19.5l-2.5-5.3v5.4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linejoin: bevel; stroke-miterlimit: 10"
        />
        <path
          d="M20.5 11.5v18h-16v-18"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
        <path
          d="m20.5 14.5 3.4-3.3L19 6.7l-3.6-1.4c0 1.7-1.4 3.1-3.1 3.1s-3-1.3-3-3h0L5.6 6.7.7 11.2l3.8 3.3"
          style="
            fill: none;
            stroke: #1d1d1b;
            stroke-width: 0.8;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          "
        />
        <path
          d="M24.5 20.5v14h8v-14l-3-3h-2z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <circle
          cx="28.6"
          cy="20.7"
          r="1.1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
        <path
          d="m32.5 34.5 5.7-2-4.3-13.1-4.4-1.9"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
