<template>
  <FpContainer class="tw-mt-8 tw-mb-80">
    <FpHeadingWithContractIcon>{{ $t('contractInvitation.heading') }}</FpHeadingWithContractIcon>
    <p class="tw-mb-4">
      {{ $t('contractInvitation.description') }}
    </p>
    <div class="tw-mb-4">
      <p class="tw-font-semibold">{{ $t('contractInvitation.details.heading') }}</p>
      <br />
      <ul>
        <li>
          <span class="tw-mr-1">{{ $t('contractInvitation.details.typeOfContract') }}</span
          ><span class="tw-font-semibold">{{ $t(`contractType.${invitation.contractTypeId}`) }}</span>
        </li>
        <li>
          <span class="tw-mr-1">{{ $t('contractInvitation.details.negotiator') }}</span
          ><span class="tw-font-semibold">{{ invitation.invitersFirstName }} {{ invitation.invitersLastName }}</span>
        </li>
        <li>
          <span class="tw-mr-1">{{ $t('contractInvitation.details.from') }}</span
          ><span class="tw-font-semibold">{{ invitation.invitersEmail }}</span>
        </li>
      </ul>
      <br />
      <p class="tw-font-semibold">
        {{ $t('contractInvitation.howDoYouWishToProceed') }}
      </p>
    </div>
    <div class="tw-mb-4">
      <div>
        <h2 class="tw-font-semibold">
          {{
            authStore.isLoggedIn
              ? $t('contractInvitation.loggedIn.heading')
              : invitation.accountExistsForEmail
              ? $t('contractInvitation.hasAccountButNotLoggedIn.heading')
              : $t('contractInvitation.noAccount.heading')
          }}
        </h2>
        <p class="tw-whitespace-pre-wrap">
          {{
            authStore.isLoggedIn
              ? $t('contractInvitation.loggedIn.body')
              : invitation.accountExistsForEmail
              ? $t('contractInvitation.hasAccountButNotLoggedIn.body')
              : $t('contractInvitation.noAccount.body')
          }}
        </p>
      </div>
    </div>
    <div class="tw-mb-4">
      <FpButton
        v-if="authStore.isLoggedIn"
        @click="onAcceptClicked"
      >
        {{ $t('contractInvitation.buttons.acceptInvitation') }}
      </FpButton>
      <FpButton
        v-else-if="invitation.accountExistsForEmail"
        @click="onLoginClicked"
      >
        {{ $t('contractInvitation.buttons.logIn') }}
      </FpButton>
      <FpButton
        v-else
        @click="onRegisterClicked"
      >
        {{ $t('contractInvitation.buttons.register') }}
      </FpButton>
    </div>
    <div class="tw-mb-4">
      <h2 class="tw-font-semibold">
        {{ $t('contractInvitation.rejectSection.heading') }}
      </h2>
      <p>
        {{ $t('contractInvitation.rejectSection.body') }}
      </p>
    </div>
    <FpButton
      accent="red"
      @click="onDeclineInvitationClick"
    >
      {{ $t('contractInvitation.buttons.decline') }}
    </FpButton>
    <FpDivider class="tw-my-6" />
    <h2 class="tw-font-semibold">
      {{ $t('contractInvitation.about.heading') }}
    </h2>
    <p>
      {{ $t('contractInvitation.about.body') }}
    </p>
    <h2 class="tw-mt-6 tw-font-semibold">
      {{ $t('contractInvitation.needHelp.heading') }}
    </h2>
    <i18n-t
      class="tw-whitespace-pre-wrap"
      keypath="contractInvitation.needHelp.body"
      scope="global"
      tag="p"
    >
      <template #email>
        <a
          class="tw-text-primaryBlue"
          :href="`mailto:${supportEmail}`"
          target="_blank"
        >
          {{ supportEmail }}
        </a>
      </template>
    </i18n-t>
  </FpContainer>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { acceptInvitation, declineInvitation, getInvitation } from '@/api/api'
import FpButton from '@/components/FpButton.vue'
import FpContainer from '@/components/FpContainer.vue'
import FpDivider from '@/components/FpDivider.vue'
import FpHeadingWithContractIcon from '@/components/FpHeadingWithContractIcon.vue'
import { goToLogin, goToRegister } from '@/services/OAuthService'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'
import { useToastStore } from '@/stores/toast-store'
import type { Invitation } from '@/types/api'

const props = defineProps<{
  invitationId: string
}>()

const invitation = ref<Invitation>(await getInvitation(props.invitationId))

const authStore = useAuthStore()
const router = useRouter()
const { toast } = useToastStore()
const { showConfirmModal } = useConfirmModalStore()

const { t } = useI18n()
const onDeclineInvitationClick = () => {
  showConfirmModal({
    heading: t('contractInvitation.confirms.decline.heading'),
    body: t('contractInvitation.confirms.decline.body'),
    confirmCallback: async () => {
      await declineInvitation(props.invitationId)
      toast(t('contractInvitation.toasts.invitationDeclined'), 'success')
      if (authStore.isLoggedIn) {
        await router.replace('/dashboard')
        return
      }
      await router.replace('/')
    },
  })
}

const route = useRoute()
const onLoginClicked = () => {
  goToLogin({ redirectUrl: route.fullPath })
}

const onAcceptClicked = async () => {
  try {
    await acceptInvitation(props.invitationId)
    toast(t('contractInvitation.toasts.invitationAccepted'), 'success')
    await router.replace(`/contracts/${invitation.value.contractId}`)
  } catch (e) {
    toast(t('global.genericError'), 'error')
  }
}

const onRegisterClicked = async () => {
  goToRegister({ redirectUrl: route.fullPath }, props.invitationId)
}

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
</script>
