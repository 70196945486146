<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #cbddeb"
        />
        <ellipse
          cx="10.3"
          cy="14.5"
          rx="3.9"
          ry="4.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M2.3 36.2v-9.1c0-2.7-.3-4.8 1.3-6 1.2-1 5-1.9 6.7-1.9M14.6 34.3l.9-8.8M6.5 34.5l-1-9"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M10.2 19.1c1.6 0 5.5 1 6.7 1.9 1.6 1.2 1.3 3.3 1.3 6v9.1M24.1 9.5h13.5M24.1 11.5H35M24.1 13.5h12.7M24.1 15.5h13.5M24.1 17.5h7.6M24.1 19.5h12M24.1 21.5h10.8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="22"
          cy="21.5"
          r=".5"
          style="fill: #1d1d1b"
        />
        <circle
          cx="22"
          cy="19.5"
          r=".5"
          style="fill: #1d1d1b"
        />
        <circle
          cx="22"
          cy="17.5"
          r=".5"
          style="fill: #1d1d1b"
        />
        <circle
          cx="22"
          cy="15.5"
          r=".5"
          style="fill: #1d1d1b"
        />
        <circle
          cx="22"
          cy="13.5"
          r=".5"
          style="fill: #1d1d1b"
        />
        <circle
          cx="22"
          cy="11.5"
          r=".5"
          style="fill: #1d1d1b"
        />
        <circle
          cx="22"
          cy="9.5"
          r=".5"
          style="fill: #1d1d1b"
        />
      </g>
    </switch>
  </svg>
</template>
