<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      class="svg-1"
      fill="none"
      stroke="#1D1D1B"
      x1="13.055"
      y1="24.813"
      x2="46.955"
      y2="24.813"
      style="stroke-width: 1.5px"
    />
    <path
      class="svg-2"
      fill="none"
      stroke="#1D1D1B"
      d="M 38.422 14.183 L 47.442 24.813 L 38.845 34.066"
      style="stroke-width: 1.5px"
    />
    <path
      class="svg-3"
      fill="none"
      stroke="#1D1D1B"
      d="M 46.976 46.389 L 3.358 46.389 L 3.358 3.448 L 46.955 3.448"
      style="stroke-width: 1.5px"
    />
  </svg>
</template>
