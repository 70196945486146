<template>
  <div>
    <MenuFrontpage />
    <div class="tw-w-full tw-h-[60px]" />
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense>
          <template #default>
            <component :is="Component" />
          </template>

          <template #fallback>
            <div
              class="tw-text-5xl tw-py-10 tw-flex tw-justify-center tw-relative tw-bg-bgGrey"
              style="height: calc(100vh - 60px)"
            >
              <FpSpinner />
            </div>
          </template>
        </Suspense>
      </template>
    </RouterView>
    <FpToaster />
    <FpConfirmModal />
    <Footer />
  </div>
</template>
<script lang="ts" setup>
import FpSpinner from '@/components/FpSpinner.vue'
import FpToaster from '@/components/FpToaster.vue'
import MenuFrontpage from '@/components/MenuFrontpage.vue'
import FpConfirmModal from '@/components/Modals/FpConfirmModal.vue'
import Footer from '@/pages/Footer.vue'
import { fetchUser } from '@/services/OAuthService'

fetchUser()
</script>
