<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #e7ad52"
    />
    <path
      d="M38.5 15.5c.6 0 1 .67 1 1.27v15.9c0 .6-.4.83-1 .83h-28c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1h28z"
      class="st2"
    />
    <circle
      cx="35.589"
      cy="19.47"
      r="1.6"
      class="st2"
    />
    <circle
      cx="24.689"
      cy="24.57"
      r="7"
      class="st2"
    />
    <circle
      cx="24.689"
      cy="24.57"
      r="5.1"
      class="st2"
    />
    <path
      d="M30.5 12.5h-12l-1 3h14z"
      class="st2"
    />
  </svg>
</template>
<style lang="scss" scoped>
.st0 {
  fill: #f0d87b;
}

.st2 {
  fill: none;
  stroke: #1d1e1c;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
