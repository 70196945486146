<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0.51 0.51 16.98 16.98"
  >
    <circle
      class="st0"
      cx="9"
      cy="9"
      r="8.49"
    />
    <g>
      <line
        class="st1"
        x1="9"
        y1="3.28"
        x2="9"
        y2="14.72"
      />
      <line
        class="st1"
        x1="14.72"
        y1="9"
        x2="3.28"
        y2="9"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>
<style scoped lang="scss">
@import '@/scss/theme';

.st0 {
  fill: $success;
  stroke: none;
}

.st1 {
  fill: none;
  stroke: $light;
  stroke-miterlimit: 10;
}
</style>
