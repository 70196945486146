<template>
  <div class="tw-max-w-[400px] tw-mb-2">
    <div
      v-for="(person, index) in question.values"
      :key="index"
      class="tw-relative"
    >
      <p class="tw-mb-2 tw-text-center tw-uppercase">
        {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.heading', { count: index + 1 }) }}
      </p>
      <XMarkCircle
        v-if="index > 0"
        class="tw-w-5 tw-absolute tw-right-0.5 tw-top-0 tw-cursor-pointer hover:tw-opacity-80"
        :disabled="disabled"
        @click="
          $emit('update:question', {
            ...question,
            values: question.values.toSpliced(index, 1),
          })
        "
      />
      <div class="tw-grid tw-gap-1 tw-grid-cols-12 tw-items-start">
        <!--NAME-->
        <p class="tw-mt-2 tw-col-span-4">{{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.name') }}</p>
        <FpInput
          class="tw-col-span-8"
          :model-value="person.name"
          :disabled="disabled"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, name: $event }),
            })
          "
        />
        <p class="tw-mt-2 tw-col-span-4">{{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.address') }}</p>
        <FpInput
          class="tw-col-span-8"
          :model-value="person.address"
          :disabled="disabled"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, address: $event }),
            })
          "
        />
        <!--ZIP-->
        <p class="tw-mt-2 tw-col-span-4">
          {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.postalCode') }}
        </p>
        <FpInput
          class="tw-col-span-8"
          :model-value="person.postalCode"
          :disabled="disabled"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, postalCode: $event }),
            })
          "
        />
        <!--CITY-->
        <p class="tw-mt-2 tw-col-span-4">{{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.city') }}</p>
        <FpInput
          class="tw-col-span-8"
          :model-value="person.city"
          :disabled="disabled"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, city: $event }),
            })
          "
        />
        <!--Country-->
        <p class="tw-mt-2 tw-col-span-4">{{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.country') }}</p>
        <FpInput
          class="tw-col-span-8"
          :model-value="person.country"
          :disabled="disabled"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, country: $event }),
            })
          "
        />
        <!--ID Type-->
        <p class="tw-mt-2 tw-col-span-4">
          {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.idType.label') }}
        </p>
        <div class="tw-col-span-8">
          <FpSelect
            :disabled="disabled"
            :model-value="idTypeForPersons[index]"
            :options="[
              { label: $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.companyId'), value: 'company' },
              { label: $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.personalId'), value: 'personal' },
              {
                label: $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.notSpecified'),
                value: 'not_specified',
              },
            ]"
            @update:model-value="onIdTypeUpdate(index, $event)"
          />
          <p class="tw-mt-2 tw-text-xs tw-italic tw-mt-1">
            {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.idType.helpText') }}
          </p>
        </div>
        <!--ID Number-->
        <template v-if="idTypeForPersons[index] !== 'not_specified'">
          <p class="tw-mt-2 tw-col-span-4">
            {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.idNumber.label') }}
          </p>
          <div class="tw-col-span-8">
            <FpInput
              :disabled="disabled"
              :model-value="idTypeForPersons[index] === 'company' ? person.cvrNumber : person.cprNumber"
              @update:model-value="
                $emit('update:question', {
                  ...question,
                  values: question.values.toSpliced(
                    index,
                    1,
                    idTypeForPersons[index] === 'company'
                      ? { ...person, cvrNumber: $event }
                      : { ...person, cprNumber: $event },
                  ),
                })
              "
            />
            <p class="tw-text-xs tw-italic tw-mt-1">
              {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.idNumber.helpText') }}
            </p>
          </div>
        </template>
        <!--Payment amount-->
        <p class="tw-mt-2 tw-col-span-4">
          {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.compensation') }}
        </p>
        <FpInput
          class="tw-col-span-8"
          type="number"
          :disabled="disabled"
          :model-value="person.value"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, value: parseFloat($event) }),
            })
          "
        />
        <!--Currency-->
        <p class="tw-mt-2 tw-col-span-4">
          {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.currency') }}
        </p>
        <FpInput
          class="tw-col-span-5"
          :model-value="person.currency"
          :disabled="disabled"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, currency: $event }),
            })
          "
        />
        <!--Payment method-->
        <p class="tw-mt-2 tw-col-span-4">
          {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.paymentMethod') }}
        </p>
        <FpSelect
          class="tw-col-span-5"
          :model-value="person.paymentMethod || question.paymentMethods[0]"
          :disabled="disabled"
          :options="question.paymentMethods.map((paymentMethod) => ({ label: paymentMethod, value: paymentMethod }))"
          @update:model-value="
            $emit('update:question', {
              ...question,
              values: question.values.toSpliced(index, 1, { ...person, paymentMethod: $event }),
            })
          "
        />
        <div class="tw-col-span-12 tw-grid tw-grid-cols-12">
          <p class="tw-mt-2 tw-col-span-4">
            {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.additionalInfo') }}
          </p>
          <FpInput
            type="textarea"
            class="tw-col-span-8"
            :disabled="disabled"
            :model-value="person.accountInfo"
            @update:model-value="
              $emit('update:question', {
                ...question,
                values: question.values.toSpliced(index, 1, { ...person, accountInfo: $event }),
              })
            "
          />
        </div>
      </div>
      <FpDivider class="tw-my-5" />
    </div>
    <AddMoreFieldsToQuestionButton
      :disabled="disabled"
      @click="
        $emit('update:question', {
          ...question,
          values: [
            ...question.values,
            {
              name: '',
              address: '',
              postalCode: '',
              city: '',
              country: '',
              value: 0,
              currency: '',
              paymentMethod: '',
              accountInfo: '',
            },
          ],
        })
      "
    >
      {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.addMore') }}
    </AddMoreFieldsToQuestionButton>
    <FpDivider class="tw-my-5" />
    <div class="tw-flex tw-justify-between">
      <p>{{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.totalCompensation') }}</p>
      <div>
        {{
          formatNumber(
            question.values.reduce((totalValue, currentQuestion) => {
              if (currentQuestion.currency === question.values[0].currency) {
                return totalValue + (currentQuestion.value ?? 0)
              }
              return totalValue
            }, 0),
          )
        }}
        {{ question.values[0].currency }}
      </div>
    </div>
    <div class="tw-mt-10 tw-text-sm tw-font-medium tw-italic">
      {{ $t('interviewTemplates.arrayOfInputsWithPersonAndCurrency.totalCompensationDescription') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import AddMoreFieldsToQuestionButton from '@/components/AddMoreFieldsToQuestionButton.vue'
import FpDivider from '@/components/FpDivider.vue'
import FpInput from '@/components/FpInput.vue'
import FpSelect from '@/components/FpSelect.vue'
import XMarkCircle from '@/svg/XMarkCircle.vue'
import type { ArrayOfInputsWithPersonAndCurrency } from '@/types/QuestionDto'
import { formatNumber } from '@/utils/formatters'

const props = defineProps<{
  question: ArrayOfInputsWithPersonAndCurrency
  disabled?: boolean
}>()

const emit = defineEmits<{
  'update:question': [question: ArrayOfInputsWithPersonAndCurrency]
}>()

type PersonalOrCompanyIdType = 'personal' | 'company' | 'not_specified'
const idTypeForPersons = ref<PersonalOrCompanyIdType[]>(
  props.question.values.map((person) => {
    if (person.cvrNumber) {
      return 'company'
    } else if (person.cprNumber) {
      return 'personal'
    }
    return 'not_specified'
  }),
)

const onIdTypeUpdate = (personIndex: number, value: PersonalOrCompanyIdType) => {
  idTypeForPersons.value[personIndex] = value
  emit('update:question', {
    ...props.question,
    values: props.question.values.toSpliced(personIndex, 1, {
      ...props.question.values[personIndex],
      cvrNumber: '',
      cprNumber: '',
    }),
  })
}
</script>
