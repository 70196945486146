<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <polygon
                    class="st0"
                    points="13,3 3,13 3,42 34,42 34,3 							"
                  />
                </g>
                <g>
                  <polygon
                    class="st0"
                    points="13,3 3,13 3,42 34,42 34,3 							"
                  />
                </g>
              </g>
              <path
                id="Path_5387_00000093141198675718908550000013673687873916379317_"
                class="st1"
                d="M3,13h10V3L3,13z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g>
      <g
        id="Group_1783_00000044144415977799064080000018091317527340165816_"
        transform="translate(0 0)"
      >
        <g>
          <g id="Group_1783-2_00000181077444095575071850000015716654471182614657_">
            <path
              id="Path_5158_00000117650298098124484400000013146666071203576767_"
              class="st2"
              d="M30.96712,30.18403v4.7l2.7-0.1v-4.5
					L30.96712,30.18403z"
            />
            <path
              id="Path_5159_00000008141333142418435750000007112578281885745561_"
              class="st3"
              d="M7.46711,32.58403L7.46711,32.58403
					c0,0.7,0.6,1.3,1.3,1.3l22.2,1v-4.7l-22.2,1C8.06712,31.28403,7.46711,31.88403,7.46711,32.58403z"
            />
            <path
              id="Path_5160_00000045610862124636302760000006834224914649118093_"
              class="st3"
              d="M33.66711,34.78403
					c1,0.5,2.1,0.8,3.3,0.9c3.7,0,7.1-3.1,7.1-3.1c-3.1-0.6-4.3-3.1-7.1-3.1c-1.1,0.1-2.3,0.4-3.3,0.9"
            />
            <path
              id="Path_5161_00000070116458539281225450000007903302558562216869_"
              class="st2"
              d="M31.23039,22.8222l-3.71184-6.57525
					l5.62078-7.21157l5.62078,7.21157l-3.71184,6.57525"
            />

            <circle
              id="Ellipse_402_00000049183192737723740560000012655074132570026138_"
              class="st2"
              cx="33.03329"
              cy="16.24694"
              r="1.80289"
            />

            <line
              id="Line_236_00000059305859507700530790000002099559521704213138_"
              class="st2"
              x1="33.03329"
              y1="18.47404"
              x2="33.03329"
              y2="22.71614"
            />
            <path
              id="Path_5162_00000059280619567305132830000015344389490616547751_"
              class="st2"
              d="M36.00275,26.10982h-5.83289
					l0.95447-3.39368h4.02999L36.00275,26.10982z"
            />
            <path
              id="Path_5163_00000084522676757872933610000013825660639675404429_"
              class="st2"
              d="M12.5,25.5l6-13l6,13"
            />
            <line
              id="Line_237_00000149343769550167192540000015188378172052962695_"
              class="st2"
              x1="10.5"
              y1="25.5"
              x2="15"
              y2="25.5"
            />
            <line
              id="Line_238_00000003799578715704989740000009035905198681872560_"
              class="st2"
              x1="16"
              y1="12.5"
              x2="18.5"
              y2="12.5"
            />

            <line
              id="Line_239_00000085214249997811208780000004847172575779266491_"
              class="st2"
              x1="14.62713"
              y1="20.99948"
              x2="22.58882"
              y2="20.99948"
            />
            <line
              id="Line_240_00000164489257824867915990000004706216337553485204_"
              class="st2"
              x1="22"
              y1="25.5"
              x2="26"
              y2="25.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
<style scoped>
.st0 {
  fill: #f0d87b;
}

.st1 {
  fill: #e7ad52;
}

.st2 {
  fill: none;
  stroke: #161615;
  stroke-width: 0.8;
  stroke-linejoin: round;
}

.st3 {
  fill: none;
  stroke: #161615;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
