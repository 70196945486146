import { type RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import About from '@/pages/About.vue'
import Advisor from '@/pages/Advisor.vue'
import Contact from '@/pages/Contact.vue'
import Contract from '@/pages/Contract.vue'
import ContractInvitation from '@/pages/ContractInvitation.vue'
import CreateUserCallback from '@/pages/CreateUserCallback.vue'
import Dashboard from '@/pages/Dashboard.vue'
import EmailValidation from '@/pages/EmailValidation.vue'
import Frontpage from '@/pages/Frontpage.vue'
import Gdpr from '@/pages/Gdpr.vue'
import Help from '@/pages/Help.vue'
import InterviewQuestion from '@/pages/InterviewQuestion.vue'
import InterviewQuestionContainer from '@/pages/InterviewQuestionContainer.vue'
import InterviewSelectRoles from '@/pages/InterviewSelectRoles.vue'
import OAuthCallback from '@/pages/OAuthCallback.vue'
import PageNotFound from '@/pages/PageNotFound.vue'
import Prices from '@/pages/Prices.vue'
import Profile from '@/pages/Profile.vue'
import Settings from '@/pages/Settings.vue'
import Terms from '@/pages/Terms.vue'
import UserCreated from '@/pages/UserCreated.vue'
import authRouterMiddleware from '@/router/middleware/auth-router-middleware'
import languageRouterMiddleware from '@/router/middleware/language-router-middleware'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Frontpage',
    component: Frontpage,
    meta: {
      auth: 'not_logged_in',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/gdpr',
    name: 'gdpr',
    component: Gdpr,
  },
  {
    path: '/prices',
    name: 'prices',
    component: Prices,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: 'logged_in',
    },
  },
  // {
  //   path: '/search',
  //   name: 'SearchContracts',
  //   component: SearchContracts,
  //   meta: {
  //     auth: 'logged_in',
  //   },
  // },
  {
    path: '/help',
    name: 'help',
    component: Help,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      auth: 'logged_in',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      auth: 'logged_in',
    },
  },
  {
    path: '/interview',
    meta: {
      auth: 'logged_in',
    },
    children: [
      {
        path: '',
        name: 'Interview',
        component: InterviewSelectRoles,
      },
      {
        path: 'questions/:contractTypeId/:roleId/:counterPartRoleId/:languageCode/:lawId',
        name: 'InterviewQuestions',
        component: InterviewQuestionContainer,
        props: true,
        children: [
          {
            path: ':questionIndex',
            component: InterviewQuestion,
            props: true,
            name: 'InterviewQuestion',
          },
        ],
      },
    ],
  },
  {
    path: '/contracts/:contractId',
    name: 'Contract',
    component: Contract,
    props: true,
    meta: {
      auth: 'logged_in',
    },
  },
  {
    path: '/advisor',
    name: 'Advisor',
    component: Advisor,
    meta: {
      auth: 'logged_in',
    },
  },
  {
    path: '/user-created',
    name: 'UserCreated',
    component: UserCreated,
  },
  {
    path: '/login-callback',
    name: 'callback',
    component: OAuthCallback,
  },
  {
    path: '/create-user-callback',
    name: 'CreateUserCallback',
    component: CreateUserCallback,
  },
  {
    path: '/email-validations/:token',
    name: 'EmailValidation',
    props: true,
    component: EmailValidation,
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: '/',
  },
  {
    path: '/invitations/:invitationId',
    name: 'Invitation',
    component: ContractInvitation,
    props: true,
  },
  {
    path: '/:pathMatch(.*)*', // 404
    name: 'NotFound',
    component: PageNotFound,
  },
]

export const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'is-active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
})

router.beforeEach(authRouterMiddleware)
router.beforeEach(languageRouterMiddleware)

export default router
