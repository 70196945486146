<template>
  <div
    class="tw-py-10 tw-flex tw-justify-center tw-relative tw-bg-bgGrey"
    style="height: calc(100vh - 60px)"
  >
    <FpSpinner />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import FpSpinner from '@/components/FpSpinner.vue'
import { type OAuthState, goToLogin } from '@/services/OAuthService'
import { useToastStore } from '@/stores/toast-store'

const route = useRoute()
const router = useRouter()

/**
 * For some reason, we get weird errors if we do this directly in the setup function.
 */
onMounted(async () => {
  if (route.query.error) {
    await router.replace('/')
    useToastStore().toast('Der skete en fejl under oprettelse', 'error')
  } else if (route.query.state) {
    const state = JSON.parse(localStorage.getItem(String(route.query.state))!) as any as OAuthState
    await goToLogin(state)
  } else {
    await router.replace({ name: 'UserCreated' })
  }
})
</script>
