export const getIconForContractType = (contractTypeId: number | string) => {
  contractTypeId = String(contractTypeId)
  switch (contractTypeId) {
    case '1':
      return 'pladeaftale'
    case '2':
      return 'featuredartistaftale'
    case '3':
      return 'komponistaftale'
    case '4':
      return 'managementaftale'
    case '5':
      return 'produceraftale'
    case '6':
      return 'fotoaftale'
    case '7':
      return 'forlagsaftale'
    case '8':
      return 'brandpartnershipaftale'
    case '9':
      return 'remixaftale'
    case '10':
      return 'videoaftale'
    case '11':
      return 'koncertaftale'
    case '12':
      return 'distributionsaftale'
    case '13':
      return 'sampleaftale'
    case '14':
      return 'illustrationsaftale'
    case '15':
      return 'lokalelejeaftale'
    case '16':
      return 'licensaftale'
    case '17':
      return 'indspilningsaftale'
    default:
      return 'pladeaftale'
  }
}
