<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      style="fill: #f6b8b5"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #f28c88"
    />
    <path
      d="M16 6.5h14M16 8.5h11.5"
      class="st2"
    />
    <circle
      cx="19.405"
      cy="26.597"
      r="11.008"
      class="st2"
    />
    <circle
      cx="19.405"
      cy="26.597"
      r="2.898"
      class="st2"
    />
    <path
      d="M19.405 35.577a8.98 8.98 0 0 0 8.981-8.98M19.405 17.616a8.98 8.98 0 0 0-8.98 8.98"
      class="st2"
    />
    <path
      d="M19.405 19.643a6.953 6.953 0 0 0-6.953 6.954M19.405 33.55a6.953 6.953 0 0 0 6.954-6.953"
      class="st2"
    />
    <path
      d="M19.405 21.67a4.926 4.926 0 0 0-4.925 4.927M19.405 31.522a4.926 4.926 0 0 0 4.926-4.925M19.405 23.698a2.898 2.898 0 0 0-2.898 2.899M19.405 29.495c1.6 0 2.898-1.298 2.898-2.898"
      class="st2"
    />
    <circle
      cx="19.405"
      cy="26.597"
      r=".435"
      style="fill: #1d1d1b"
    />
  </svg>
</template>
<style>
.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
