<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="3.21 9.98 38.55 24.34"
  >
    <g>
      <path
        class="st0"
        d="M25.83204,23.2896c0.41394,0.05339,0.83602,0.08092,1.26458,0.08092c4.6673,0,8.56543-3.26324,9.55683-7.63043   c2.54773-0.0119,4.60992-2.0788,4.60992-4.62927c0-0.21777-0.03503-0.42596-0.06409-0.63568L3.82215,10.47514   c-0.60792,2.24181,1.4194,4.359,3.65892,4.359c0.52863,0,1.02954-0.10852,1.49506-0.28766   c0.10175,1.43042,0.67297,2.83411,1.7666,3.92773c1.64715,1.64711,3.98527,2.15439,6.07134,1.54375"
      />
      <path
        class="st0"
        d="M18.60198,15.46496h5.13945c0.04534,0,0.07606,0.04615,0.05857,0.08798l-1.95436,4.93269   c-0.01749,0.04183,0.01323,0.08798,0.05857,0.08798h5.49768c0.05015,0,0.08344,0.0508,0.05644,0.09306   c-0.74592,1.16742-7.97827,13.14215-8.11458,13.14392c-0.09105,0.00117,1.66325-8.3494,1.86419-9.17281   c0.00976-0.03998-0.02231-0.0712-0.06346-0.0712h-5.93774c-0.04443,0-0.07512-0.04447-0.05935-0.08601l3.39524-8.97465   C18.55199,15.48126,18.57561,15.46496,18.60198,15.46496z"
      />
    </g>
  </svg>
</template>
<style scoped>
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}
</style>
