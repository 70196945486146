<template>
  <div class="tw-bg-bgGrey">
    <FpContainer class="tw-pt-10">
      <div>
        <FpHeadingWithContractIcon>
          {{ $t('profile.headline') }}
        </FpHeadingWithContractIcon>
        <FpHeadingWithIconAndSubline>
          {{ $t('profile.aboutYou') }}
        </FpHeadingWithIconAndSubline>
        <div class="tw-grid tw-grid-cols-12 tw-max-w-[700px] tw-pt-6 tw-pb-5 tw-gap-4">
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.firstName"
              :placeholder="$t('profile.firstName.placeholder')"
              :label="$t('profile.firstName.label')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.middlename"
              :placeholder="$t('profile.middleName.placeholder')"
              :label="$t('profile.middleName.label')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.lastName"
              :placeholder="$t('profile.lastName.placeholder')"
              :label="$t('profile.lastName.label')"
            />
          </div>
          <div class="tw-col-span-12">
            <FpInputFormGroup
              v-model="userFields.addressLine1"
              :placeholder="$t('profile.address.placeholderFirst')"
              :label="$t('profile.address.label')"
            />
          </div>
          <div class="tw-col-span-12">
            <FpInput
              v-model="userFields.addressLine2"
              :placeholder="$t('profile.address.placeholderSecond')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.postalCode"
              :placeholder="$t('profile.zipCode.placeholder')"
              :label="$t('profile.zipCode.label')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.city"
              :placeholder="$t('profile.city.placeholder')"
              :label="$t('profile.city.label')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.state"
              :placeholder="$t('profile.state.placeholder')"
              :label="$t('profile.state.label')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.country"
              :placeholder="$t('profile.country.placeholder')"
              :label="$t('profile.country.label')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              v-model="userFields.phoneNumber"
              :placeholder="$t('profile.phone.placeholder')"
              :label="$t('profile.phone.label')"
            />
          </div>
          <div class="tw-col-span-6">
            <FpInputFormGroup
              :model-value="userFields.cpr"
              :placeholder="$t('profile.socialSecurity.placeholder')"
              :label="$t('profile.socialSecurity.label')"
              @update:model-value="onCprUpdate"
            />
          </div>
          <div class="tw-col-span-6">
            <FpButton
              accent="green"
              @click="submitChanges"
            >
              {{ $t('profile.buttons.saveChanges') }}
            </FpButton>
          </div>
        </div>
        <FpHeadingWithIconAndSubline>
          {{ $t('profile.company') }}
        </FpHeadingWithIconAndSubline>
        <FadeTransition>
          <div
            v-if="showCompaniesPreloader"
            class="tw-py-20 tw-flex tw-items-center tw-justify-center"
          >
            <FpSpinner />
          </div>
          <div v-else>
            <div class="tw-grid tw-grid-cols-12 tw-max-w-[700px] tw-pt-4 tw-pb-5 tw-gap-4">
              <div class="tw-col-span-12">
                <div
                  v-for="company in associatedCompanies"
                  :key="company.id"
                  class="tw-grid tw-grid-cols-12 tw-gap-4 tw-my-4"
                >
                  <div class="tw-col-span-6">
                    <div class="tw-bg-[#d8d8d8] tw-rounded-full tw-h-10 tw-flex tw-items-center tw-px-4">
                      {{ company.name }}
                    </div>
                  </div>
                  <div class="tw-col-span-6 tw-flex tw-gap-4">
                    <FpButton
                      accent="green"
                      size="small"
                      @click="editCompany(company)"
                    >
                      {{ $t('profile.buttons.editCompany') }}
                    </FpButton>
                    <FpButton
                      accent="red"
                      size="small"
                      @click="confirmDeleteCompany(company)"
                    >
                      {{ $t('profile.buttons.deleteCompany') }}
                    </FpButton>
                  </div>
                </div>
              </div>
            </div>
            <FpButton
              class="tw-mt-4 tw-mb-20"
              size="small"
              @click="showAddEditCompanyModal = true"
            >
              {{ $t('profile.buttons.createCompany') }}
            </FpButton>
          </div>
        </FadeTransition>
      </div>
    </FpContainer>
    <FpAddEditCompanyModal
      v-if="showAddEditCompanyModal"
      :edit-context="editCompanyModalContext || undefined"
      @create="onCompanyCreate"
      @edit="onCompanyEdit"
      @close="onCloseAddEditCompanyModal"
    />
  </div>
</template>

<script lang="ts" setup async>
import cloneDeep from 'lodash/cloneDeep'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { deleteCompany, fetchMyAssociatedCompanies, patchUserFields } from '@/api/api'
import FpButton from '@/components/FpButton.vue'
import FpContainer from '@/components/FpContainer.vue'
import FpHeadingWithContractIcon from '@/components/FpHeadingWithContractIcon.vue'
import FpHeadingWithIconAndSubline from '@/components/FpHeadingWithIconAndSubline.vue'
import FpInput from '@/components/FpInput.vue'
import FpInputFormGroup from '@/components/FpInputFormGroup.vue'
import FpSpinner from '@/components/FpSpinner.vue'
import FpAddEditCompanyModal from '@/components/Modals/FpAddEditCompanyModal.vue'
import FadeTransition from '@/components/Transitions/FadeTransition.vue'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'
import { useToastStore } from '@/stores/toast-store'
import type { CompanyDto, UserDto } from '@/types/api'

const { user } = storeToRefs(useAuthStore())

const userFields = ref<UserDto>(cloneDeep(user.value!))
const cprUpdated = ref(false)
const onCprUpdate = (newValue: string) => {
  userFields.value.cpr = newValue
  cprUpdated.value = true
}

const showAddEditCompanyModal = ref(false)

const associatedCompanies = ref<CompanyDto[]>([])
const submitting = ref(false)

const { toast } = useToastStore()
const { t } = useI18n()
const loading = ref(false)

const submitChanges = async () => {
  submitting.value = true
  try {
    await patchUserFields({
      ...userFields.value,
      cpr: cprUpdated.value ? userFields.value.cpr : undefined,
    })
    user.value = userFields.value
    toast(t('profile.toasts.profileUpdated'))
  } catch (error) {
    toast(t('profile.toasts.profileUpdateError'), 'error')
  } finally {
    submitting.value = false
  }
}

const onCompanyCreate = () => {
  toast(t('profile.toasts.companyCreated'))
  fetchAssociatedCompanies()
}

const onCompanyEdit = () => {
  toast(t('profile.toasts.companyUpdated'))
  fetchAssociatedCompanies()
}

const showCompaniesPreloader = ref(false)
const fetchAssociatedCompanies = async () => {
  showCompaniesPreloader.value = true
  associatedCompanies.value = await fetchMyAssociatedCompanies()
  showCompaniesPreloader.value = false
}
fetchAssociatedCompanies()

const editCompanyModalContext = ref<null | CompanyDto>(null)
const editCompany = (company: CompanyDto) => {
  editCompanyModalContext.value = company
  showAddEditCompanyModal.value = true
}

const onCloseAddEditCompanyModal = () => {
  showAddEditCompanyModal.value = false
  editCompanyModalContext.value = null
}

const { showConfirmModal } = useConfirmModalStore()

const confirmDeleteCompany = (company: CompanyDto) => {
  showConfirmModal({
    heading: t('profile.confirms.deleteCompany.heading'),
    body: t('profile.confirms.deleteCompany.body'),
    confirmCallback: () => {
      loading.value = true
      try {
        deleteCompany(company.id!)
        toast(t('profile.toasts.companyDeleted'))
        associatedCompanies.value.splice(
          associatedCompanies.value.findIndex((c) => c.id === company.id),
          1,
        )
      } catch (error: any) {
        toast(error.message, 'error')
      }
    },
  })
}
</script>
