<template>
  <svg
    class="svg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38.394043 22"
  >
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      x1="10.9949236"
      y1="3.5148718"
      x2="17.264925"
      y2="3.5148718"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      x1="1.8959246"
      y1="3.5148718"
      x2="8.1669235"
      y2="3.5148718"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      x1="9.580924"
      y1="4.785872"
      x2="9.580924"
      y2="17.4138718"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      x1="9.580924"
      y1="0.1768717"
      x2="9.580924"
      y2="1.9578718"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      d="M10.9949236,3.3718717
	c0,0.7809308-0.633069,1.4140003-1.4139996,1.4140003c-0.7809315,0-1.4140005-0.6330695-1.4140005-1.414
	s0.633069-1.414,1.4139996-1.4140002h0.000001C10.3618546,1.957872,10.9949236,2.5909412,10.9949236,3.3718717z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      d="M4.2619243,3.6628718l3.0310001,6.6269994
	v0.7379999c-0.0005522,1.5001669-1.2168331,2.7159996-2.717,2.7159996H3.9479246
	c-1.5000052,0-2.7159998-1.2159948-2.7160001-2.7159996v-0.7379999L4.2619243,3.6628718z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      x1="1.2319245"
      y1="10.2898712"
      x2="7.2929244"
      y2="10.2898712"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-linejoin="bevel"
      d="M14.8989239,3.6628718
	l3.0330009,6.6269994v0.7379999c-0.0005531,1.5001669-1.2168331,2.7159996-2.717,2.7159996h-0.6280003
	c-1.5001669,0-2.7164478-1.2158327-2.717-2.7159996v-0.7379999L14.8989239,3.6628718z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      x1="11.8689241"
      y1="10.2898712"
      x2="17.929924"
      y2="10.2898712"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-miterlimit="10"
      d="M12.5579243,17.4138718H6.6039248
	c-1.3961759,0-2.5279999,1.1318245-2.5280004,2.5279999v0.335001h11.0100002v-0.3390007
	C15.0837173,18.5432587,13.9525394,17.4138699,12.5579243,17.4138718z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-linejoin="round"
      stroke-linecap="round"
      d="M18.5202503,6.3828716
	c2.4949512-4.9747963,8.5503788-6.9851074,13.5251751-4.4901557s6.9851074,8.5503778,4.4901543,13.5251741
	c-2.4949493,4.9747972-8.5503769,6.9851084-13.5251732,4.4901571c-2.0992947-1.0528355-3.7649307-2.8058414-4.709156-4.9561758"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-linejoin="round"
      stroke-linecap="round"
      d="M35.2152519,4.3768716
	c-2.3199081,1.3817067-4.9848499,2.0753407-7.684,2c-2.6988201,0.0752048-5.3634033-0.6184258-7.6830006-2"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-linejoin="round"
      stroke-linecap="round"
      d="M19.984251,17.5638714
	c2.2925186-1.3198795,4.9045563-1.9821463,7.5489998-1.9140005c2.6452236-0.0683155,5.2580395,0.5943193,7.5510006,1.9150019"
    />
    <ellipse
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-linejoin="round"
      stroke-linecap="round"
      cx="27.53125"
      cy="10.8988714"
      rx="4.546"
      ry="10.0780001"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-linejoin="round"
      stroke-linecap="round"
      x1="27.53125"
      y1="0.8218717"
      x2="27.53125"
      y2="20.9768715"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="0.8"
      stroke-linejoin="round"
      stroke-linecap="round"
      x1="20.3482513"
      y1="10.5625"
      x2="37.6092529"
      y2="10.5625"
    />
  </svg>
</template>
