<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    class="svg"
    viewBox="3.51 3.51 17.98 17.98"
  >
    <circle
      fill="none"
      stroke="currentColor"
      cx="12.5"
      cy="12.5"
      r="8.49"
    />
    <line
      fill="none"
      stroke="CurrentColor"
      x1="8.849"
      y1="8.317"
      x2="16.939"
      y2="16.397"
    />
    <line
      fill="none"
      stroke="currentColor"
      x1="16.939"
      y1="8.317"
      x2="8.849"
      y2="16.397"
    />
  </svg>
</template>
