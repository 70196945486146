<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_5386_00000152239045516999157730000013818267171055974272_"
      d="M13 3 3 13v29h31V3H13z"
      style="fill: #bfd6e7"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #8cb6d3"
    />
    <path
      d="M15 9.5h11M15 11.5h16M15 13.5h14M26.238 27.785a7.765 7.765 0 1 1-1.76-3.886"
      class="st2"
    />
    <path
      d="M30.318 20.815 19.081 32.051l-4.484-4.484"
      class="st2"
    />
  </svg>
</template>
<style>
.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
