import { api } from '@/api/http'
import { i18n } from '@/lang'
import type { MissingInfoDto } from '@/types/MissingInfoDto'
import type { CommentDto, QuestionDto } from '@/types/QuestionDto'
import type {
  CompanyDto,
  ContractDto,
  ContractListDto,
  ContractRoleDto,
  ContractRolePairDto,
  ContractStatus,
  ContractTurn,
  ContractTypeDto,
  CounterPartInvitationStatusDto,
  InfoDto,
  Invitation,
  LanguageDto,
  LawDto,
  PaginationResponse,
  UserDto,
} from '@/types/api'

export const fetchInfoSection = async (infoKey: string, lcid = i18n.global.locale.value): Promise<InfoDto> => {
  return (await api.get<InfoDto>(`api/info/${infoKey}?lcid=${lcid}`)).data
}

export const fetchContractQuestions = async (
  contractTypeId: string,
  roleId: string,
  counterPartRoleId: string,
  languageCode: string,
  lawId: string,
): Promise<ContractDto> => {
  return (
    await api.get<ContractDto>(
      `api/contracts/${contractTypeId}/${roleId}/${counterPartRoleId}/${languageCode}/${lawId}`,
    )
  ).data
}

export const createContract = async (
  contractInfo: ContractDto,
): Promise<{
  id: number
}> => {
  return (
    await api.post<{
      id: number
    }>(`api/contracts`, contractInfo)
  ).data
}

export const fetchContractRoles = async (): Promise<ContractRoleDto[]> => {
  return (await api.get<ContractRoleDto[]>(`api/contracts/roles?lcid=${i18n.global.locale.value}`)).data
}

export const fetchRolePairings = async (): Promise<ContractRolePairDto[]> => {
  return (await api.get<ContractRolePairDto[]>(`api/contracts/rolepairing?lcid=${i18n.global.locale.value}`)).data
}

export const fetchLawOptions = async (): Promise<LawDto[]> => {
  return (await api.get<LawDto[]>(`api/contracts/lawOptions?lcid=${i18n.global.locale.value}`)).data
}

export const fetchLanguageOptions = async (): Promise<LanguageDto[]> => {
  return (await api.get<LanguageDto[]>(`api/contracts/languageoptions?lcid=${i18n.global.locale.value}`)).data
}

export const fetchContractTypes = async (): Promise<ContractTypeDto[]> => {
  return (await api.get<ContractTypeDto[]>(`api/contracts/contracttypes?lcid=${i18n.global.locale.value}`)).data
}

export const fetchCurrentUser = async (): Promise<UserDto> => {
  return (await api.get<UserDto>(`api/user/whoami`)).data
}

export const fetchContract = async (contractId: string): Promise<ContractDto> => {
  return (await api.get<ContractDto>(`api/contracts/${contractId}`)).data
}

export const fetchContractInvitationStatus = async (contractId: string): Promise<CounterPartInvitationStatusDto> => {
  return (await api.get<CounterPartInvitationStatusDto>(`api/counterpart/GetStatusOnInvitation/${contractId}`)).data
}

export const fetchMyAssociatedCompanies = async (): Promise<CompanyDto[]> => {
  return (
    await api.get<{
      companies: CompanyDto[]
    }>(`api/company/myassociatedcompanies`)
  ).data.companies
}

export const createCompany = async (company: CompanyDto): Promise<CompanyDto> => {
  return (await api.post<CompanyDto>(`api/company/create`, company)).data
}

export const editCompany = async (id: string, company: CompanyDto): Promise<CompanyDto> => {
  return (await api.patch<CompanyDto>(`api/company/edit/${id}`, company)).data
}

export const deleteCompany = async (id: string): Promise<void> => {
  return (await api.delete<void>(`api/company/delete/${id}`)).data
}

export const postCommentToQuestion = async (
  contractId: number | string,
  questionId: number,
  comment: string,
): Promise<CommentDto> => {
  return (await api.post<CommentDto>(`api/contracts/${contractId}/questions/${questionId}/comments`, { comment })).data
}

export const patchUserFields = async (user: UserDto): Promise<void> => {
  return (await api.patch<void>(`api/user/update`, user)).data
}

export const updateContractQuestion = async (contractId: string, question: QuestionDto) => {
  return (await api.put<void>(`api/contracts/${contractId}/questions/${question.id}`, question)).data
}

export const deleteContract = async (contractId: string) => {
  return (await api.delete<void>(`api/contracts/${contractId}`)).data
}

export const cancelContract = async (contractId: string) => {
  return (await api.post<void>(`api/contracts/${contractId}/cancel`, {})).data
}

export const inviteCounterpartToContract = async (
  contractId: string,
  payload: {
    email: string
    readyForSignature: boolean
  },
) => {
  return (await api.post<void>(`api/counterpart/selectcounternegotiator/${contractId}`, payload)).data
}

export const deleteAccount = async () => {
  return (await api.delete<void>(`api/user/delete`)).data
}

export const getInvitation = async (id: string): Promise<Invitation> => {
  return (await api.get<Invitation>(`api/counterpart/GetInitielInfo`, { id })).data
}

export const declineInvitation = async (id: string): Promise<void> => {
  return (await api.post<void>(`api/counterpart/decline`, {}, { id })).data
}

export const acceptInvitation = async (id: string): Promise<void> => {
  return (await api.post<void>(`api/counterpart/acceptonexistingaccount`, {}, { id })).data
}

export const setPartyForContract = async (party: string, contractId: string | number) => {
  return await api.post<void>(`api/party/setparty/${party}/${contractId}`, {})
}

export const duplicateContract = async (
  contractId: string,
): Promise<{
  id: string
}> => {
  return (
    await api.post<{
      id: string
    }>(`api/contracts/${contractId}/duplicate`, {})
  ).data
}

export const submitSignatureToContract = async (contractId: string | number, signature: string) => {
  return await api.post<void>(`api/document/SignContract`, {
    contractId,
    base64EncodedImgString: signature,
  })
}

export const changeContractNegotiationTurn = async (
  contractId: string,
  payload: {
    readyForSignature: boolean
  },
) => {
  return await api.post<void>(`api/contracts/${contractId}/changeturn`, payload)
}

export const fetchContractsList = async (
  params: {
    status?: ContractStatus | ContractStatus[]
    turn?: ContractTurn | 'null'
    page?: number
    pageSize?: number
  } = {},
): Promise<PaginationResponse<ContractListDto>> => {
  return (
    await api.get<PaginationResponse<ContractListDto>>(`api/contracts`, {
      lcid: i18n.global.locale.value,
      ...params,
    })
  ).data
}

export const tryGeneratePDFContract = async (contractId: string | number) => {
  return (
    await api.get<{
      errorOcurred: boolean
      errors: string
    }>(`api/document/TryGenerateContractPDF/${contractId}?language=${i18n.global.locale.value}`)
  ).data
}

export const fetchPDFContract = async (contractId: string | number) => {
  return (await api.get<any>(`api/document/HentPDFContract/${contractId}`, {}, {}, { responseType: 'arraybuffer' }))
    .data
}

export const createPDFContract = async (contractId: string | number) => {
  return (
    await api.post<any>(`api/document/CreatePdf`, {
      contractId,
      languageCode: i18n.global.locale.value,
    })
  ).data
}

export const getMissingPartyInfoForContractWithId = async (contractId: string | number) => {
  return (await api.get<MissingInfoDto>(`api/party/missinginfo/${contractId}`)).data
}
