<template>
  <FpModal :title="$t('signContractModal.heading')">
    <template #body-and-footer>
      <p class="tw-mb-2">{{ $t('signContractModal.body') }}</p>
      <FpSignature>
        <template #actions="{ clearFunction, signaturePad, isEmpty }">
          <div class="tw-flex tw-justify-between tw-gap-4">
            <FpButton
              accent="red"
              @click="clearFunction"
            >
              {{ $t('signContractModal.buttons.clearSignature') }}
            </FpButton>
            <FpButton
              :loading="loading"
              :disabled="isEmpty"
              @click="confirmSubmitSignature(signaturePad)"
            >
              {{ $t('signContractModal.buttons.signContract') }}
            </FpButton>
          </div>
        </template>
      </FpSignature>
    </template>
  </FpModal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { submitSignatureToContract } from '@/api/api'
import FpButton from '@/components/FpButton.vue'
import FpModal from '@/components/FpModal.vue'
import FpSignature from '@/components/FpSignature.vue'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'
import { useToastStore } from '@/stores/toast-store'
import type { ContractDto } from '@/types/api'

const props = defineProps<{
  contract: ContractDto
}>()

const emit = defineEmits<{
  contractSigned: [void]
}>()

const { showConfirmModal } = useConfirmModalStore()
const { toast } = useToastStore()
const loading = ref(false)
const { t } = useI18n()

const confirmSubmitSignature = async (signaturePad: any) => {
  if (signaturePad.isEmpty()) {
    toast(t('signContractModal.toasts.signatureFieldEmpty'), 'error')
    return
  }
  showConfirmModal({
    heading: t('signContractModal.confirms.confirmSigningModal.heading'),
    body: t('signContractModal.confirms.confirmSigningModal.body'),
    confirmCallback: async () => {
      try {
        loading.value = true
        await submitSignatureToContract(props.contract.id, signaturePad.toDataURL())
        emit('contractSigned')
      } catch (e) {
        loading.value = false
        toast(t('global.genericError'), 'error')
      }
    },
    confirmAccent: 'blue',
  })
}
</script>
