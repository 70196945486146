<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #cbddeb"
        />
        <path
          d="m30 10 5.6-5.6M35.5 8.6l.1-4.2-4.2.2M10 30l-5.6 5.6M4.5 31.4l-.1 4.2 4.2-.2M30 30l5.6 5.6M31.4 35.4l4.2.2-.2-4.2M10 10 4.4 4.4M8.6 4.6l-4.2-.2.2 4.2"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="20"
          cy="20"
          r="10.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="20"
          cy="20"
          r="2.8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M20 17.2c-1.5 0-2.8 1.2-2.8 2.8M20 22.8c1.5 0 2.8-1.2 2.8-2.8M20 28c4.4 0 8-3.6 8-8M20 12c-4.4 0-8 3.6-8 8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M20 25.4c3 0 5.4-2.4 5.4-5.4M20 14.6c-3 0-5.4 2.4-5.4 5.4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
