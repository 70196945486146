<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.7 49.5"
  >
    <g>
      <g>
        <g>
          <defs>
            <rect
              x="0"
              y="0.4"
              width="33.8"
              height="49.1"
            />
          </defs>
          <g>
            <path
              style="fill: #285a8b"
              d="M33,41.4C24.9,37,7.9,36,7.9,36l3.4-24.4L0,14.1l12.6,35.4c0,0,19.3-1.7,21-6.5
						c0.1-0.2,0.1-0.4,0.1-0.5c0-0.1,0-0.1,0-0.2C33.7,41.9,33.6,41.7,33,41.4z"
            />
            <path
              style="fill: #457fee"
              d="M13,0.4L7.9,36c0,0,16.9,1.1,25.1,5.4c0.6,0.3,0.7,0.6,0.7,1v-1.3
						c0-2.3,0.1-32.6,0-34.4c0-1.5,0.2-2.6-2.4-3.9C26.1,0.3,13,0.4,13,0.4"
            />
            <path
              style="fill: #ffffff"
              d="M16.5,16.4c4.5-0.5,9.1-1,13.6-1.6c0.8-0.1,1.4-2.6,0.5-2.4
						c-4.5,0.5-9,1-13.6,1.6C16.2,14.1,15.6,16.5,16.5,16.4"
            />
            <path
              style="fill: #ffffff"
              d="M14.6,14.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1l-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
						c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
						c-0.1,0.2-0.1,0.3-0.2,0.5l0,0.3c0,0.2,0,0.3,0.1,0.5l0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1l0,0l0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0
						l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5
						c0-0.1,0-0.2,0-0.3C14.7,14.9,14.7,14.7,14.6,14.6z"
            />
            <g>
              <polygon
                style="fill: #ffffff"
                points="11.9,27.2 11.9,27.2 11.8,27.2 						"
              />
              <path
                style="fill: #ffffff"
                d="M13,25c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
							c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
							c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.2-0.1,0.3-0.2,0.5l0,0.3c0,0.2,0,0.3,0.1,0.5l0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1l0.1,0
							c0,0,0,0,0,0c0,0,0,0,0.1,0l0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
							c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.2,0-0.3C13.1,25.4,13.1,25.2,13,25z"
              />
            </g>
            <g>
              <path
                style="fill: #ffffff"
                d="M13.8,19.8c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
							c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
							c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.2-0.1,0.3-0.2,0.5l0,0.3c0,0.2,0,0.3,0.1,0.5l0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1l0.1,0
							c0,0,0,0,0,0c0,0,0,0,0.1,0l0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
							c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.2,0-0.3C13.8,20.1,13.8,19.9,13.8,19.8z"
              />
              <polygon
                style="fill: #ffffff"
                points="12.6,22 12.6,22 12.6,22 						"
              />
            </g>
            <path
              style="fill: #ffffff"
              d="M12.1,30.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1l-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
						c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
						c-0.1,0.2-0.1,0.3-0.2,0.5l0,0.3c0,0.2,0,0.3,0.1,0.5l0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1l0,0l0.1,0l0,0c0,0,0,0,0.1,0l0,0
						c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5
						c0-0.1,0-0.2,0-0.3C12.2,30.5,12.2,30.3,12.1,30.2z"
            />
            <path
              style="fill: #ffffff"
              d="M16,21.6c5.1-0.5,10.1-1.1,15.1-1.9c0.8-0.1,1.4-2.6,0.5-2.4
						c-5,0.8-10.1,1.4-15.1,1.9C15.6,19.2,15,21.7,16,21.6"
            />
            <path
              style="fill: #ffffff"
              d="M14.9,27.1c4.8-0.4,9.6-1,14.4-1.6c0.8-0.1,1.4-2.6,0.5-2.4
						c-4.8,0.6-9.6,1.2-14.4,1.6C14.5,24.7,13.9,27.2,14.9,27.1"
            />
            <path
              style="fill: #ffffff"
              d="M13.8,32.2c5.2-0.3,10.4-0.8,15.6-1.4c0.8-0.1,1.4-2.6,0.5-2.4
						c-5.2,0.7-10.4,1.2-15.6,1.4C13.5,29.8,12.8,32.2,13.8,32.2"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
