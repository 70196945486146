import type { InternalAxiosRequestConfig } from 'axios'
import { useAuthStore } from '@/stores/auth-store'

export const authRequestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const { token } = useAuthStore()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}
