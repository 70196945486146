<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="4.97 7.62 35 29.76"
  >
    <g>
      <rect
        x="5.46875"
        y="14.5"
        class="st0"
        width="34"
        height="15.97902"
      />
      <polyline
        class="st0"
        points="7.5,19.36956 7.5,16.5 11.51765,16.5  "
      />
      <polyline
        class="st0"
        points="11.51765,28.47933 7.5,28.47933 7.5,25.29507  "
      />
      <polyline
        class="st0"
        points="37.45833,19.36956 37.45833,16.5 33.51526,16.5  "
      />
      <polyline
        class="st0"
        points="33.51526,28.47933 37.45833,28.47933 37.45833,25.29507  "
      />
      <polyline
        class="st0"
        points="15.78372,14.37451 34.98306,8.11911 36.99297,14.28804  "
      />
      <polyline
        class="st0"
        points="29.50961,30.52992 10.01694,36.88089 7.68029,30.52992  "
      />
      <g>
        <polyline
          class="st1"
          points="14.15001,19.33386 15.49794,19.33386 15.49794,25.4332   "
        />
        <line
          class="st1"
          x1="14.15001"
          y1="25.4332"
          x2="16.84158"
          y2="25.4332"
        />
        <path
          class="st1"
          d="M19.44148,25.57177L19.44148,25.57177c-0.69695,0-1.26193-0.56499-1.26193-1.26193v-3.85262    c0-0.69695,0.56499-1.26193,1.26193-1.26193h0c0.69695,0,1.26193,0.56499,1.26193,1.26193v3.85262    C20.70341,25.00679,20.13843,25.57177,19.44148,25.57177z"
        />
        <path
          class="st1"
          d="M23.94588,25.57177L23.94588,25.57177c-0.69695,0-1.26193-0.56499-1.26193-1.26193v-3.85262    c0-0.69695,0.56499-1.26193,1.26193-1.26193h0c0.69695,0,1.26193,0.56499,1.26193,1.26193v3.85262    C25.20781,25.00679,24.64283,25.57177,23.94588,25.57177z"
        />
        <path
          class="st1"
          d="M28.45028,25.57177L28.45028,25.57177c-0.69695,0-1.26193-0.56499-1.26193-1.26193v-3.85262    c0-0.69695,0.56499-1.26193,1.26193-1.26193h0c0.69695,0,1.26193,0.56499,1.26193,1.26193v3.85262    C29.71221,25.00679,29.14722,25.57177,28.45028,25.57177z"
        />
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts"></script>
<style type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}
</style>
