<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #f0d87b"
        />
        <path
          d="M33.7 11.4c.6 0 1.1.5 1.1 1.1v15.9c0 .6-.5 1.1-1.1 1.1H6.3c-.6 0-1.1-.5-1.1-1.1V12.5c0-.6.5-1.1 1.1-1.1h27.4z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="30.9"
          cy="15.2"
          r="1.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="20"
          cy="20.3"
          r="7"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="20"
          cy="20.3"
          r="5.1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M25.4 7.8H14.6l-.9 3.6h12.6z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M39.4 12.6V8.3h-4.6M5.4 8.3H.8v4.4M.8 28.1v4.1h4.6M34.8 32.2h4.6v-4.1"
          style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
