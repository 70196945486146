<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_5386_00000152239045516999157730000013818267171055974272_"
      d="M13 3 3 13v29h31V3H13z"
      style="fill: #bfd6e7"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #8cb6d3"
    />
    <path
      d="M23.003 18.516V5.731M26.212 9.184l-3.208-3.468-3.203 3.468M22.972 26.484v12.785M19.763 35.816l3.208 3.468 3.202-3.468M19.004 22.484H7.243M10.696 19.275l-3.468 3.208 3.468 3.203M26.971 22.516h11.761M35.279 25.725l3.467-3.208-3.467-3.203"
      class="st2"
    />
    <circle
      cx="22.987"
      cy="22.5"
      r="9.744"
      class="st2"
    />
    <circle
      cx="22.987"
      cy="22.5"
      r=".779"
      style="fill: #1d1d1b"
    />
  </svg>
</template>
<style scoped lang="scss">
.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
