<template>
  <textarea
    v-if="type === 'textarea'"
    class="fp-input"
    :class="[{ 'tw-cursor-pointer': clickable }, hasError ? 'tw-border-dangerRed' : 'tw-border-inputBorderGrey']"
    :value="props.modelValue"
    :disabled="disabled"
    :readonly="clickable"
    :rows="4"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
  />
  <input
    v-else
    class="fp-input fp-input--not-textarea"
    :class="[{ 'tw-cursor-pointer': clickable }, hasError ? 'tw-border-dangerRed' : 'tw-border-inputBorderGrey']"
    :type="type"
    :value="props.modelValue"
    :disabled="disabled"
    :readonly="clickable"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :step="step"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

export type FpInputProps = {
  modelValue?: string | number | null
  type?: 'text' | 'number' | 'password' | 'email' | 'search' | 'textarea'
  placeholder?: string
  autocomplete?: string
  disabled?: boolean
  step?: number
  clickable?: boolean
  hasError?: boolean
}

const props = withDefaults(defineProps<FpInputProps>(), {
  modelValue: null,
  type: 'text',
  autocomplete: 'off',
  placeholder: undefined,
  step: undefined,
})

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])

const focused = ref(false)
const onFocus = ($event: Event) => {
  focused.value = true
  emit('focus', $event)
}
const onBlur = ($event: Event) => {
  focused.value = false
  emit('blur', $event)
}

const onInput = (event: any) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value)
}
</script>

<style lang="scss" scoped>
.fp-input {
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  background: #ffffff;
  border-radius: 10px;
  color: #000000;
  width: 100%;

  &--not-textarea {
    height: 40px;
  }

  &::placeholder {
    font-size: 18px;
    font-weight: 0;
    color: #8f8f8f;
  }

  &:disabled {
    opacity: 0.6;
    background-color: #d1d1d1;
    cursor: not-allowed;
  }
}
</style>
