<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1.54 9.97 41.91 25.01"
    stroke-miterlimit="10"
    stroke="currentColor"
    fill="none"
  >
    <g>
      <g>
        <line
          x1="20.80494"
          y1="24.43583"
          x2="17.52857"
          y2="24.43583"
        />
        <path
          d="M22.53153,14.81912l0.89489,1.97697c0.00594,0.01311,0.01741,0.0222,0.03069,0.02431l2.00104,0.31702
			c0.03343,0.0053,0.04678,0.05009,0.02259,0.07579l-1.44797,1.53886c-0.00961,0.01021-0.01399,0.02492-0.01172,0.03933
			l0.34182,2.1729c0.00571,0.0363-0.02923,0.06398-0.05913,0.04684l-1.78979-1.0259c-0.01187-0.00681-0.02606-0.00681-0.03793,0
			l-1.78979,1.0259c-0.0299,0.01714-0.06484-0.01054-0.05913-0.04684l0.34182-2.1729
			c0.00227-0.01442-0.00212-0.02912-0.01172-0.03933l-1.44797-1.53886c-0.02419-0.02571-0.01084-0.0705,0.02259-0.07579
			l2.00104-0.31702c0.01328-0.0021,0.02475-0.01119,0.03069-0.02431l0.8949-1.97697
			C22.47338,14.78609,22.51658,14.78609,22.53153,14.81912z"
        />
        <polyline points="15.5,34.47591 15.5,12.7531 29.5,12.7531 29.5,34.47591" />
        <polyline points="13.42166,34.29456 13.42166,10.46875 31.57834,10.46875 31.57834,34.29456" />
        <line
          x1="13.42166"
          y1="10.46875"
          x2="15.5"
          y2="12.57175"
        />
        <line
          x1="29.5"
          y1="12.57175"
          x2="31.57834"
          y2="10.46875"
        />
        <circle
          cx="17.8369"
          cy="24.47564"
          r="0.41574"
        />
      </g>
      <line
        stroke-width="0.92558"
        x1="42.99277"
        y1="34.29456"
        x2="2.00723"
        y2="34.29456"
      />
      <g>
        <g>
          <line
            x1="5.57186"
            y1="34.29456"
            x2="5.57186"
            y2="25.4399"
          />
          <circle
            cx="5.57186"
            cy="24.62198"
            r="1.00747"
          />
        </g>
        <g>
          <line
            x1="39.42814"
            y1="34.29456"
            x2="39.42814"
            y2="25.4399"
          />
          <circle
            cx="39.42814"
            cy="24.62198"
            r="1.00747"
          />
        </g>
      </g>
      <path
        d="M39.37545,25.66219c-4.90685,1.07657-10.71291,1.69917-16.93532,1.69917s-12.02848-0.6226-16.93533-1.69917"
      />
    </g>
  </svg>
</template>
