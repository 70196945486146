<template>
  <FpContainer class="tw-my-8 lg:tw-my-16">
    <div class="tw-grid tw-grid-cols-12 tw-gap-6">
      <div class="tw-col-span-12 lg:tw-col-span-4">
        <div class="tw-bg-light tw-rounded-lg tw-text-center tw-mb-6 tw-pb-10 tw-overflow-hidden">
          <div class="tw-h-20 tw-overflow-hidden tw-pt-2 tw-bg-lightBlue">
            <DashboardBackground />
          </div>
          <div class="-tw-mt-10 tw-flex tw-justify-center">
            <div class="tw-w-20 tw-h-20 tw-bg-lightBlue tw-rounded-full tw-overflow-hidden">
              <IconPicker
                icon="me"
                class="tw-mt-1"
              />
            </div>
          </div>
          <h1 class="tw-mt-4 tw-text-2xl">{{ $t('dashboard.greeting', { name: authStore.user?.firstName }) }}</h1>
          <p>{{ $t('dashboard.overview') }}</p>
        </div>
        <div class="tw-flex tw-gap-2 tw-text-center tw-mb-6 tw-font-extralight">
          <div class="tw-bg-dangerRed tw-text-light tw-w-1/2 tw-py-4 tw-rounded-lg">
            <h2>
              {{ $t('dashboard.awaitingYou') }}
            </h2>
            <div class="tw-text-3xl">
              {{ totalAwaitingYou }}
            </div>
          </div>
          <div class="tw-bg-successGreen tw-text-light tw-w-1/2 tw-py-4 tw-rounded-lg">
            <h2>
              {{ $t('dashboard.awaitingOthers') }}
            </h2>
            <div class="tw-text-3xl">
              {{ totalAwaitingOthers }}
            </div>
          </div>
        </div>
        <div class="tw-bg-bgGreyDark tw-p-2 tw-rounded-md">
          <h5 class="tw-text-md tw-text-center tw-my-2 tw-font-medium">TIPS & TRICKS</h5>
          <div class="tw-relative tw-w-full tw-h-48">
            <iframe
              class="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-rounded-md"
              src="https://player.vimeo.com/video/799446053?dnt=1"
              frameborder="0"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-8">
        <DashboardListSection
          class="tw-mb-8"
          status="AwaitingMe"
          @total-records="totalAwaitingYou = $event"
          @cancel="cancelledKey++"
        />

        <DashboardListSection
          class="tw-mb-8"
          status="AwaitingOther"
          @total-records="totalAwaitingOthers = $event"
          @cancel="cancelledKey++"
        />

        <DashboardListSection
          class="tw-mb-8"
          status="Draft"
          @cancel="cancelledKey++"
        />

        <DashboardListSection
          :key="cancelledKey"
          class="tw-mb-8"
          status="Canceled"
        />

        <DashboardListSection
          class="tw-mb-8"
          status="Signed"
        />
      </div>
    </div>
  </FpContainer>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import DashboardListSection from '@/components/DashboardListSection.vue'
import FpContainer from '@/components/FpContainer.vue'
import IconPicker from '@/components/IconPicker.vue'
import { useAuthStore } from '@/stores/auth-store'
import DashboardBackground from '@/svg/DashboardBackground.vue'

const totalAwaitingYou = ref<number | null>(null)
const totalAwaitingOthers = ref<number | null>(null)

const authStore = useAuthStore()

const cancelledKey = ref(1)
</script>
