<template>
  <InfoKeySlideIn
    v-if="computedSlideInInfoContext"
    :heading="computedSlideInInfoContext.heading"
    :info-key="computedSlideInInfoContext.infoKey"
    @close="selectedSlideInInfoString = null"
  />
  <FpContainer class="tw-bg-bgGrey">
    <div class="tw-relative">
      <h3 class="fp-interview__roles-headline">
        {{ $t('interview.heading') }}
      </h3>
      <p class="fp-interview__roles-text tw-whitespace-pre-wrap">
        {{ $t('interview.body') }}
      </p>

      <FpHeadingWithIconAndSubline
        id="role-heading"
        icon="me"
      >
        {{ $t('interview.whatIsYourRole') }}
      </FpHeadingWithIconAndSubline>
      <div
        v-if="roleOptions.length === 0"
        class="fp-interview__roles-wrap tw-justify-center tw-items-center"
      >
        <FpSpinner />
      </div>
      <div
        v-else
        class="fp-interview__roles-wrap"
      >
        <ContractRoleItem
          v-for="(role, index) in roleOptions"
          :key="`user-role-${index}`"
          :selected="userSelectedRole?.id === role.id"
          :disabled="!role.valid"
          :label="role.label!"
          :icon="role.svgIcon as IconType"
          :background-color="role.color!"
          @click="onRoleSelected(role)"
          @click-info="selectedSlideInInfoString = role.info!"
        />
      </div>

      <FpHeadingWithIconAndSubline
        id="counterpart-role-heading"
        style="scroll-margin-top: 60px"
        icon="you"
      >
        {{ $t('interview.whatIsYourCounterPartsRole') }}
      </FpHeadingWithIconAndSubline>
      <div
        v-if="roleOptions.length === 0"
        class="fp-interview__roles-wrap tw-justify-center tw-items-center"
      >
        <FpSpinner />
      </div>
      <div
        v-else-if="!userSelectedRole"
        class="fp-interview__roles-wrap"
      >
        <p class="fp-interview__roles-wait">
          {{ $t('interview.needForUserInput') }}
        </p>
      </div>
      <div
        v-else
        class="fp-interview__roles-wrap"
      >
        <ContractRoleItem
          v-for="(counterPartRole, index) in roleOptions"
          :key="`counter-part-role-${index}`"
          :selected="counterPartSelectedRole?.id === counterPartRole.id"
          :disabled="!counterPartRole.valid || !isValidPairing(userSelectedRole!, counterPartRole!)"
          :label="counterPartRole.label!"
          :icon="counterPartRole.svgIcon as IconType"
          :background-color="counterPartRole.color!"
          @click="onCounterpartSelected(counterPartRole)"
          @click-info="selectedSlideInInfoString = counterPartRole.info!"
        />
      </div>

      <FpHeadingWithIconAndSubline
        id="contract-type-heading"
        style="scroll-margin-top: 60px"
        icon="deal"
      >
        {{ $t('interview.whatIsYourContractAbout') }}
      </FpHeadingWithIconAndSubline>
      <div
        v-if="contractOptions.length === 0"
        class="fp-interview__roles-wrap tw-justify-center tw-items-center"
      >
        <FpSpinner />
      </div>
      <div
        v-else-if="!counterPartSelectedRole"
        class="fp-interview__roles-wrap"
      >
        <p class="fp-interview__roles-wait">
          {{ $t('interview.needForUserInput') }}
        </p>
      </div>
      <div
        v-else
        class="fp-interview__roles-wrap"
      >
        <ContractRoleItem
          v-for="(contractType, index) in contractOptions"
          :key="`contract-type--${index}`"
          :selected="selectedContractType?.contractId === contractType.contractId"
          :disabled="!contractType.valid || !isValidPairing(userSelectedRole!, counterPartSelectedRole!, contractType)"
          :label="contractType.name!"
          :icon="contractType.svgIcon as IconType"
          @click="onContractTypeSelected(contractType)"
          @click-info="selectedSlideInInfoString = contractType.info!"
        />
      </div>
    </div>

    <div
      id="language-and-law"
      class="tw-flex tw-gap-6 tw-flex-col md:tw-flex-row"
      style="scroll-margin-top: 60px"
    >
      <div
        v-for="select in languageAndLawSelects"
        :key="select.type"
        class="tw-w-full md:tw-w-1/2"
      >
        <FpHeadingWithIconAndSubline
          :icon="select.icon"
          class="tw-mb-4"
        >
          {{ select.heading }}
          <FpInfoButton @click="selectedSlideInInfoString = select.info" />
        </FpHeadingWithIconAndSubline>

        <div class="tw-flex tw-gap-2 tw-flex-col lg:tw-flex-row">
          <p class="tw-w-full lg:tw-w-1/2">
            {{ select.description }}
          </p>
          <FpSelect
            :model-value="select.type === 'language' ? selectedLanguage : selectedLaw"
            class="tw-w-full lg:tw-w-1/2"
            :options="select.options"
            @update:model-value="select.type === 'language' ? (selectedLanguage = $event) : (selectedLaw = $event)"
          />
        </div>
      </div>
    </div>

    <FpButton
      class="tw-my-20"
      size="large"
      :disabled="
        !selectedContractType?.contractId ||
        !userSelectedRole?.id ||
        !counterPartSelectedRole?.id ||
        !selectedLanguage ||
        !selectedLaw
      "
      :to="{
        name: 'InterviewQuestion',
        params: {
          contractTypeId: selectedContractType?.contractId,
          roleId: userSelectedRole?.id,
          counterPartRoleId: counterPartSelectedRole?.id,
          languageCode: selectedLanguage,
          lawId: selectedLaw,
          questionIndex: 0,
        },
      }"
    >
      {{ $t('interview.buttons.startInterview') }}
    </FpButton>
  </FpContainer>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  fetchContractRoles,
  fetchContractTypes,
  fetchLanguageOptions,
  fetchLawOptions,
  fetchRolePairings,
} from '@/api/api'
import ContractRoleItem from '@/components/ContractRoleItem.vue'
import FpButton from '@/components/FpButton.vue'
import FpContainer from '@/components/FpContainer.vue'
import FpHeadingWithIconAndSubline from '@/components/FpHeadingWithIconAndSubline.vue'
import FpInfoButton from '@/components/FpInfoButton.vue'
import FpSelect from '@/components/FpSelect.vue'
import type { FpSelectOption } from '@/components/FpSelect.vue'
import FpSpinner from '@/components/FpSpinner.vue'
import InfoKeySlideIn from '@/components/InfoKeySlideIn.vue'
import type { InfoKeySlideInProps } from '@/components/InfoKeySlideIn.vue'
import type { IconType } from '@/types/IconType'
import type { ContractRoleDto, ContractRolePairDto, ContractTypeDto, LanguageDto, LawDto } from '@/types/api'

const { t } = useI18n()

/**
 * Dynamic user selected state
 */
const userSelectedRole = ref<ContractRoleDto | null>(null)
const counterPartSelectedRole = ref<ContractRoleDto | null>(null)
const selectedLanguage = ref<string>('da')
const selectedLaw = ref<number>(1)
const selectedContractType = ref<ContractTypeDto | null>(null)

/**
 * Slide in info
 */
const selectedSlideInInfoString = ref<string | null>(null)
const computedSlideInInfoContext = computed<InfoKeySlideInProps | null>(() => {
  if (!selectedSlideInInfoString.value) {
    return null
  }
  if (selectedSlideInInfoString.value === 'language') {
    return {
      heading: t('interview.language'),
      infoKey: 'language',
    }
  }
  if (selectedSlideInInfoString.value === 'law') {
    return {
      heading: t('interview.lawOptions'),
      infoKey: 'law',
    }
  }
  const role = roleOptions.value.find((role) => role.info === selectedSlideInInfoString.value)
  if (role) {
    return {
      heading: role.label!,
      infoKey: selectedSlideInInfoString.value,
    }
  }
  const contractType = contractOptions.value.find(
    (contractType) => contractType.info === selectedSlideInInfoString.value,
  )
  if (contractType) {
    return {
      heading: contractType.name!,
      infoKey: selectedSlideInInfoString.value,
    }
  }
  if (selectedSlideInInfoString.value) {
    return {
      heading: undefined,
      infoKey: selectedSlideInInfoString.value,
    }
  }
  return null
})

const { locale } = useI18n()

/**
 * The available roles, role pairings, law options and language options fetched from the backend.
 */
const languageOptions = ref<LanguageDto[]>([])
const lawOptions = ref<LawDto[]>([])
const roleOptions = ref<ContractRoleDto[]>([])
const rolePairing = ref<ContractRolePairDto[]>([])
const contractOptions = ref<ContractTypeDto[]>([])
const fetchInitialData = async () => {
  const response = await Promise.all([
    fetchContractRoles(),
    fetchRolePairings(),
    fetchLawOptions(),
    fetchLanguageOptions(),
    fetchContractTypes(),
  ])

  roleOptions.value = response[0]
  rolePairing.value = response[1]
  lawOptions.value = response[2]
  languageOptions.value = response[3]
  contractOptions.value = response[4]
}

watch(locale, fetchInitialData)

/**
 * Checks if the user and counterpart roles are a valid pairing.
 */
const isValidPairing = (
  user: ContractRoleDto,
  counterPart: ContractRoleDto,
  contract: ContractTypeDto | undefined = undefined,
): boolean => {
  if (!rolePairing.value) {
    return false
  }
  const userRole = rolePairing.value.find((rolePairing) => rolePairing.roleId === user.id)
  if (!userRole) {
    return false
  }
  const optionsForPairing = userRole.pair!.find(
    (counterPartRolePairing) => counterPartRolePairing.roleId === counterPart.id,
  )
  if (!contract) {
    return !!optionsForPairing
  }
  return !!optionsForPairing?.options?.find(
    (optionsForPairingContract) => optionsForPairingContract.contractTypeId === contract.contractId,
  )
}

/**
 * To avoid repeat tailwind styling for the same elements, we instead render them with a v-for based on this computed property.
 */
const languageAndLawSelects = computed<
  {
    type: 'language' | 'law'
    heading: string
    icon: IconType
    description: string
    options: FpSelectOption[]
    info: string
  }[]
>(() => {
  return [
    {
      type: 'language',
      heading: t('interview.language'),
      icon: 'sprog',
      description: t('interview.whichContractLanguage'),
      options: languageOptions.value?.map((option) => ({
        label: String(option.label),
        value: String(option.languageCode),
      })),
      info: 'language',
    },
    {
      type: 'law',
      heading: t('interview.lawOptions'),
      icon: 'lovvalg',
      description: t('interview.whichCountryLaw'),
      options: lawOptions.value.map((option) => ({
        label: String(option.label),
        value: String(option.id),
      })),
      info: 'law',
    },
  ]
})

const onRoleSelected = (role: ContractRoleDto) => {
  userSelectedRole.value = role
  document.getElementById('counterpart-role-heading')?.scrollIntoView({ behavior: 'smooth' })
}

const onCounterpartSelected = (role: ContractRoleDto) => {
  counterPartSelectedRole.value = role
  document.getElementById('contract-type-heading')?.scrollIntoView({ behavior: 'smooth' })
}

const onContractTypeSelected = (contractType: ContractTypeDto) => {
  selectedContractType.value = contractType
  document.getElementById('language-and-law')?.scrollIntoView({ behavior: 'smooth' })
}

fetchInitialData()
</script>

<style lang="scss">
@import '@/scss/theme.scss';

.fp-interview {
  &-pdf {
    width: 100%;
    height: 800px;
  }

  &__roles {
    position: relative;

    &-select-text {
      position: absolute;
      top: 72px;
      left: 0;
      width: 320px;
      font-weight: 300;
      font-size: 20px;
      line-height: 26px;
    }

    &-select {
      position: absolute;
      top: 80px;
      right: 20px;
      width: 200px;
    }

    &-btn {
      position: relative;
      width: 200px;
      margin-right: auto;
    }

    &-left {
      position: relative;
      width: calc(50% - 10px);
      height: 140px;
    }

    &-right {
      position: relative;
      width: calc(50% - 10px);
      height: 140px;
    }

    &-headline {
      text-transform: uppercase;
      color: $dark;
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    &-text {
      color: $dark;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      padding-bottom: 20px;
    }

    &-wrap {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      height: auto;
      column-gap: 20px;
      margin-bottom: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 240px;
    }

    &-wait {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 300px;
      height: 30px;
      margin-top: -15px;
      margin-left: -150px;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #9b9b9b;
    }

    &-option {
      position: relative;
      width: calc(25% - 15px);
      background-color: #ffffff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      height: 50px;
      margin-top: 5px;
      margin-bottom: 5px;
      border-radius: 5px;

      &-text {
        line-height: 46px;
        padding-left: 60px;
      }

      &-text2 {
        line-height: 46px;
        padding-left: 20px;
      }

      .svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 18px;
        height: 18px;
        cursor: pointer;

        .svg-1 {
          fill: none;
        }

        .svg-2 {
          fill: none;
          stroke: #000000;
        }
      }

      &-circle {
        position: absolute;
        top: 7px;
        left: 7px;
        width: 36px;
        height: 36px;
        border-radius: 40px;

        .svg {
          position: absolute;
          top: -2px;
          left: 2px;
          width: 40px;
          height: 40px;
        }
      }

      &-area {
        margin-right: 30px;
        height: 100%;
        cursor: pointer;
      }

      &-info {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        z-index: 10;
      }

      &-area2 {
        height: 100%;
        width: calc(100% - 28px);
        cursor: pointer;
      }
    }

    &-option--selected {
      background-color: #457fee;
      color: #ffffff;

      .fp-interview__roles-option-text {
        color: #ffffff;
      }
    }

    &-option--invalid {
      background-color: #f2f2f2;
      box-shadow: none;
      color: #b0b0b0;

      .fp-interview__roles-option-area,
      .fp-interview__roles-option-area2 {
        cursor: default;
      }

      .fp-interview__roles-option-text {
        color: #c4c4c4;
      }

      .fp-interview__roles-option-circle {
        opacity: 0.5;
      }
    }
  }

  &-options {
    position: relative;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-bottom: 100px;
  }

  &__wrap {
    position: relative;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    height: 680px;
    margin-bottom: 100px;
  }

  &-contract {
    background-color: #cad7e3;
    width: 200px;
    height: 100px;
    margin: 10px;
    line-height: 100px;
    text-align: center;
    cursor: pointer;
  }

  &-sum-headline {
    font-size: 20px;
    margin: 10px;
  }

  &-sum-list {
    margin: 10px;
    background-color: #cad7e3;
  }

  &_input-wrap {
    position: absolute;
    top: 320px;
    left: 50%;
    width: 500px;
    height: 50px;
    margin-left: -250px;
    transition: all 400ms ease-in-out;
    opacity: 0;

    .svg {
      position: absolute;
      top: -20px;
      right: 50px;
      width: 80px;
      height: 80px;
      cursor: pointer;
    }
  }

  &_input {
    margin-left: 100px;
    width: 280px;
    background-color: #cad7e3;
  }

  &_input-wrap.input-choice {
    opacity: 1;
  }

  &__highlight {
    font-size: 30px;
    font-weight: 600;
    z-index: 10;
  }

  &__box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    transition: left 400ms ease-in-out;

    &-wrapper {
      position: relative;
      width: 100%;
      height: 800px;
      overflow: hidden;
    }
  }

  &__status {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 100%;
    height: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    z-index: 20;
  }

  &__status-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__inner {
    position: relative;
    width: 33.33%;
    height: 100%;
  }
}

@media only screen and (max-width: 1160px) {
  .fp-interview__sum-category-wrap-inner {
    flex-wrap: wrap;
  }

  .fp-interview__sum-category-wrap {
    margin-left: 160px;
  }

  .fp-interview__sum-category-comments {
    margin-left: 160px;
  }

  .fp-interview__sum-category-headline {
    font-size: 20px;
    line-height: 24px;
    width: 140px;
  }

  .fp-interview__sum-category-answer {
    width: auto;
    height: auto;
    border-left: 0px solid #bfbfbf;
  }

  .fp-interview__roles-option {
    width: calc(50% - 10px);
  }
}

@media only screen and (max-width: 960px) {
  .fp-dashboard {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fp-interview__sum-category-icon {
    left: 0;
  }

  .fp-interview__sum-category-box {
    padding-top: 100px;
  }

  .fp-interview__sum-category-wrap {
    margin-left: 0;
  }

  .fp-interview__sum-category-comments {
    margin-left: 0;
  }

  .fp-interview__sum-category-headline {
    left: 72px;
    width: 400px;
    top: 36px;
  }
}
</style>
