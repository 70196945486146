<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="svg-1"
      style="fill: #a3d3c2"
      cx="20"
      cy="20"
      r="19"
    />
    <path
      class="svg-2"
      d="M 23.6 17.1 C 23.6 19.696 21.809 21.8 19.6 21.8 C 17.391 21.8 15.6 19.696 15.6 17.1 C 15.6 14.504 17.391 12.4 19.6 12.4 C 21.809 12.4 23.6 14.504 23.6 17.1 Z M 12.3 39 L 12.3 29.8 C 12.3 27 12 25 13.5 23.7 C 14.6 22.7 18.1 21.7 19.6 21.7 M 24 37.1 L 24.4 28.6 M 15.3 37.1 L 14.9 28.6 M 19.6 21.8 C 21.1 21.8 24.6 22.8 25.7 23.8 C 27.1 25.1 26.9 27.1 26.9 29.9 L 26.9 39 M 32.4 20 C 32.4 26.848 26.848 32.4 20 32.4 C 13.152 32.4 7.6 26.848 7.6 20 C 7.6 13.152 13.152 7.6 20 7.6 C 26.848 7.6 32.4 13.152 32.4 20 Z M 24.4 8.5 L 7.9 2.3 L 6.2 3.4 L 7.7 21.3 M 4.8 4.5 L 9.2 1.3"
      style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
    />
  </svg>
</template>
