<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 376 140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style="fill: #e8d2c7; :#E8D2C7 "
      d="M 375.915 10.75 C 362.825 24.05 342.595 38.08 315.075 38.87 L 347.375 106.48 C 357.225 103.92 366.795 100.36 375.915 95.85 L 375.915 10.75 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 54.94 92.63 C 62.47 95.18 71.61 98.1 79.04 99.56 C 79.04 99.56 70.59 91.49 70.89 67.2 C 71.19 42.91 72.63 19.49 72.63 19.49 L 0.08 17.67 L 0.08 50.66 C 9.48 59.43 20.1 69.05 27.7 75.13 C 42.76 87.15 47.01 89.26 54.94 92.63 Z"
    />
    <path
      style="fill: #e8d2c7"
      d="M 0.08 72.57 C 11.78 76.89 23.7 81.28 33.31 84.81 L 0.08 72.55 L 0.08 72.57 Z"
    />
    <path
      style="fill: #285a8c"
      d="M 54.94 92.63 C 47.01 89.26 42.76 87.15 27.7 75.12 C 20.1 69.05 9.48 59.42 0.08 50.65 L 0.08 72.55 L 33.31 84.82 C 43.36 88.51 50.87 91.25 52.88 91.93 C 53.55 92.16 54.25 92.39 54.94 92.63 Z"
    />
    <path
      style="fill: #5478bc"
      d="M 162.44 52.52 C 165.03 49.7 166.76 46.19 167.42 42.42 C 168.18 38.7 168.43 34.89 168.18 31.11 L 121.9 58.54 C 123.72 58.85 125.49 59.12 127.22 59.35 C 128.95 59.58 130.63 59.76 132.25 59.89 C 133.88 60.02 135.46 60.11 136.99 60.16 C 138.52 60.21 140 60.2 141.42 60.15 C 143.19 60.09 144.87 59.95 146.47 59.74 C 146.9 59.69 147.32 59.62 147.73 59.56 C 148.88 59.38 149.98 59.16 151.03 58.89 C 152.41 58.55 153.77 58.12 155.08 57.59 C 156.19 57.15 157.26 56.61 158.29 56.01 C 159.83 55.03 161.23 53.86 162.44 52.52 Z"
    />
    <path
      style="fill: #e9d3c8"
      d="M 158.31 56.01 C 158.16 56.11 158.02 56.21 157.86 56.3 L 159.03 55.59 L 158.64 55.83 C 158.53 55.89 158.42 55.94 158.31 56.01 Z"
    />
    <path
      style="fill: #e9d3c8"
      d="M 181.71 0 L 164.19 10.08 C 165.5 14.5 166.54 19 167.32 23.54 C 167.65 25.59 167.91 27.66 168.08 29.74 C 168.12 30.2 168.16 30.65 168.19 31.11 C 168.44 34.9 168.18 38.7 167.43 42.42 C 166.77 46.19 165.05 49.7 162.45 52.52 C 161.24 53.86 159.84 55.03 158.32 56 C 158.43 55.94 158.54 55.88 158.65 55.82 L 159.04 55.58 L 196.82 32.52 L 215.97 20.83 C 216.7 20.38 217.43 19.93 218.14 19.47 C 222.7 16.52 226.66 13.22 228.87 7.43 C 229.76 5.03 230.34 2.53 230.61 -0.02 L 181.71 0 Z"
    />
  </svg>
</template>
