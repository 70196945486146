<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 28.1310749 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Line_339_00000052102554906323296620000002716840480665478304_"
      d="M-2.288 27.148v1.781"
      style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
    />
    <g id="Group_2056_00000020382098876215787600000004769362017464049318_">
      <path
        id="Path_5472_00000116935830653179942380000012772709627558670466_"
        d="M15.165.613C8.391.613 2.906 5.169 2.906 10.788a8.753 8.753 0 0 0 1.3 4.537L.691 21.388l6.584-2.817a13.785 13.785 0 0 0 7.89 2.394c6.77 0 12.26-4.556 12.26-10.177S21.934.613 15.164.613z"
        style="fill: none; stroke: #000; stroke-width: 0.8; stroke-linecap: round; stroke-linejoin: round"
      />
      <path
        id="Line_330_00000093881210630368223720000012187483379047708089_"
        d="M7.556 12.531h13.941"
        class="st2"
      />
      <path
        id="Line_331_00000129898109678452644650000003514806339335901865_"
        d="M7.556 15.469h8.546"
        class="st2"
      />
      <path
        id="Line_332_00000060739449946962510940000010032648958405914541_"
        d="M7.556 9.5h12.125"
        class="st2"
      />
      <path
        id="Line_333_00000135691444981804612300000010575289679144377277_"
        d="M7.556 6.5H22.94"
        class="st2"
      />
    </g>
  </svg>
</template>
<style>
.st2 {
  fill: none;
  stroke: #000;
  stroke-width: 0.8;
  stroke-linejoin: round;
}
</style>
