<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="10.98 10.98 23.04 23.04"
  >
    <circle
      class="st0"
      cx="22.5"
      cy="22.5"
      r="11.01888"
    />
    <g>
      <circle
        class="st1"
        cx="22.5"
        cy="22.5"
        r="1.07138"
      />
      <circle
        class="st1"
        cx="28.26652"
        cy="22.5"
        r="1.07138"
      />
      <circle
        class="st1"
        cx="16.73348"
        cy="22.5"
        r="1.07138"
      />
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.st1 {
  fill: currentColor;
}
</style>
