<template>
  <div class="tw-fixed tw-z-[970] tw-bottom-12 tw-left-1/2 -tw-translate-x-1/2">
    <TransitionGroup name="pop">
      <div
        v-for="toast in toasts"
        :key="toast.uuid"
        class="tw-relative tw-p-5 tw-rounded-full tw-shadow-xl tw-text-light tw-h-14 tw-flex tw-items-center tw-w-[300px] tw-gap-2"
        :class="toast.status === 'success' ? 'tw-bg-successGreen' : 'tw-bg-dangerRed'"
        @click="removeToastWithId(toast.uuid)"
      >
        <Checkmark
          v-if="toast.status === 'success'"
          class="tw-w-5"
        />
        <XMarkFat
          v-else
          class="tw-w-5"
        />
        <div class="tw-mt-1">
          {{ toast.text }}
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useToastStore } from '@/stores/toast-store'
import Checkmark from '@/svg/Checkmark.vue'
import XMarkFat from '@/svg/XMarkFat.vue'

const { toasts } = storeToRefs(useToastStore())
const { removeToastWithId } = useToastStore()
</script>

<style lang="scss" scoped>
.pop-enter-active {
  animation: 300ms enter ease-out forwards;
}

.pop-leave-active {
  animation: 400ms leave forwards;
}

@keyframes leave {
  0% {
    opacity: 1;
    padding: 20px;
    margin-top: 15px;
  }
  50% {
    padding: 20px;
    margin-top: 15px;
    opacity: 0;
  }
  100% {
    opacity: 0;
    padding: 0;
    margin-top: 0;
  }
}

@keyframes enter {
  0% {
    padding: 0;
    margin-top: 0;
    left: -50px;
    opacity: 0;
  }
  50% {
    padding: 20px;
    opacity: 0;
    margin-top: 15px;
    left: -50px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
</style>
