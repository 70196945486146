<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style="fill: #273074"
      d="M 26.466 2.306 C 19.549 4.812 13.433 9.123 8.923 14.837 L 26.466 23.659 L 26.466 2.306 Z"
    />
    <path
      style="fill: #273074"
      d="M 8.321 64.362 C 12.933 70.376 19.248 75.088 26.567 77.695 L 26.567 55.238 L 8.321 64.362 Z"
    />
    <path
      style="fill: #273074"
      d="M 53.434 2.306 L 53.434 23.559 L 70.978 14.737 C 66.467 9.223 60.451 4.812 53.434 2.306 Z"
    />
    <path
      style="fill: #273074"
      d="M 53.434 77.594 C 60.752 74.988 66.967 70.276 71.679 64.261 L 53.434 55.138 L 53.434 77.594 Z"
    />
    <path
      style="fill: #ffffff"
      d="M 0.702 47.519 C 1.403 51.028 2.506 54.336 4.01 57.444 L 13.133 52.832 L 25.163 52.832 L 6.717 62.155 C 7.218 62.857 7.719 63.659 8.321 64.362 L 26.567 55.238 L 26.567 77.594 C 28.271 78.196 30.075 78.697 31.98 79.098 L 31.98 47.519 L 0.702 47.519 Z"
    />
    <path
      style="fill: #ffffff"
      d="M 31.88 0.802 C 30.075 1.203 28.271 1.704 26.466 2.306 L 26.466 23.559 L 8.923 14.837 C 7.218 16.943 5.714 19.248 4.411 21.654 L 13.033 25.965 L 2.506 25.965 C 1.805 27.669 1.303 29.474 0.902 31.379 L 31.88 31.379 L 31.88 0.802 Z"
    />
    <path
      style="fill: #ffffff"
      d="M 53.434 2.306 C 51.729 1.704 49.925 1.203 48.02 0.802 L 48.02 31.379 L 78.998 31.379 C 78.296 27.97 77.093 24.762 75.489 21.755 L 66.967 26.065 L 54.837 26.065 L 72.682 17.043 C 72.181 16.341 71.58 15.539 70.978 14.837 L 53.434 23.659 L 53.434 2.306 Z"
    />
    <path
      style="fill: #ffffff"
      d="M 48.121 79.098 C 49.925 78.697 51.729 78.196 53.534 77.594 L 53.534 55.238 L 71.78 64.362 C 73.384 62.256 74.887 59.95 76.091 57.444 L 66.967 52.933 L 77.895 52.933 C 78.497 51.228 78.998 49.424 79.299 47.619 L 48.121 47.619 L 48.121 79.098 Z"
    />
    <path
      style="fill: #bf0736"
      d="M 66.967 25.965 L 75.489 21.654 C 74.687 20.05 73.685 18.446 72.682 17.043 L 54.837 25.965 L 66.967 25.965 Z"
    />
    <path
      style="fill: #bf0736"
      d="M 4.411 21.654 C 3.709 23.058 3.007 24.461 2.506 25.965 L 13.033 25.965 L 4.411 21.654 Z"
    />
    <path
      style="fill: #bf0736"
      d="M 13.133 52.933 L 4.01 57.444 C 4.812 59.048 5.714 60.652 6.717 62.155 L 25.163 52.933 L 13.133 52.933 Z"
    />
    <path
      style="fill: #bf0736"
      d="M 75.99 57.444 C 76.692 55.94 77.293 54.436 77.895 52.933 L 66.867 52.933 L 75.99 57.444 Z"
    />
    <path
      style="fill: #bf0736"
      d="M 48.121 0.802 C 45.514 0.301 42.807 0 40 0 C 37.194 0 34.486 0.301 31.88 0.802 L 31.88 31.379 L 0.902 31.379 C 0.301 34.185 0 36.993 0 40 C 0 42.607 0.301 45.113 0.702 47.619 L 31.88 47.619 L 31.88 79.198 C 34.486 79.699 37.194 80 40 80 C 42.807 80 45.514 79.699 48.121 79.198 L 48.121 47.519 L 79.198 47.519 C 79.699 45.113 79.9 42.507 79.9 39.9 C 79.9 36.893 79.6 34.085 78.998 31.278 L 48.121 31.278 L 48.121 0.802 Z"
    />
  </svg>
</template>
