<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #cbddeb"
        />
        <path
          d="M22.9 32.2H10.4V8.5h18.2v15.4"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="14.7"
          cy="25.6"
          r="2"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="22.6"
          cy="21.7"
          r="2"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M24.6 21.6v-9.8l-7.9 2.7v11"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="3.6"
          cy="8.7"
          r="2.8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="3.9"
          cy="32.8"
          r="2.3"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="36.3"
          cy="7.6"
          r="2.7"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="m28.6 13.9 5.7-4.6M5.7 31.4l4.8-3.9M5.8 10l4.5 3"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="27.7"
          cy="29.3"
          r="5.5"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M29.9 31.3c-.6.6-1.4 1-2.3 1-1.7 0-3-1.4-3-3s1.4-3 3-3c.8 0 1.6.3 2.2.9"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
