<template>
  <div class="tw-bg-bgGrey">
    <FpContainer
      class="tw-py-4 md:tw-py-11 min-[2020px]:tw-pr-[80px] min-[1780px]:tw-pr-[220px] min-[1680px]:tw-pr-[320px] lg:tw-pr-[440px]"
    >
      <div
        class="tw-w-full interview-questions-bg tw-flex tw-flex-col tw-min-h-[calc(100dvh-92px)] md:tw-min-h-[calc(100dvh-148px)]"
      >
        <h1 class="tw-text-lg tw-uppercase">
          {{
            $t(
              'interviewQuestion.heading',
              {
                type: contract.contractType,
              },
              {
                locale: contract.languageCode!,
              },
            )
          }}
        </h1>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-justify-center">
          <FpHeadingWithIconAndSubline :icon="currentQuestion.icon!">
            {{ currentQuestion?.category }}
          </FpHeadingWithIconAndSubline>
          <h4 class="tw-text-xl tw-mt-4 tw-mb-2 tw-font-extralight">
            {{ currentQuestion.question }}
          </h4>
          <RouterView
            :question="currentQuestion"
            @update:question="updateCurrentQuestion"
          />
        </div>
        <ContractQuestionInfoButton
          class="tw-mt-8 tw-mb-14"
          :disabled="!currentQuestion.info"
          :active="isSlideInOpen"
          @click="isSlideInOpen = !isSlideInOpen"
        >
          {{ $t('interviewQuestion.moreInfo') }}
        </ContractQuestionInfoButton>
        <div class="tw-flex tw-justify-between">
          <button
            class="tw-flex tw-items-center enabled:hover:tw-opacity-80"
            :class="{ 'tw-opacity-50': !hasPreviousQuestion }"
            :disabled="!hasPreviousQuestion"
            @click="goToPreviousQuestion"
          >
            <ArrowCircleLeft class="tw-mb-0.5 tw-w-8 tw-mr-2" />
            {{ $t('interviewQuestion.previous') }}
          </button>
          <div>
            <span class="tw-hidden sm:tw-inline-block tw-mr-1">
              {{ $t('interviewQuestion.progress') }}
            </span>
            <span class="tw-text-2xl">
              {{ questionIndexAsNumber + 1 }}<sup>/{{ activeQuestions.length }}</sup>
            </span>
          </div>
          <button
            v-if="isFinalQuestion"
            :disabled="loading"
            class="tw-flex tw-items-center hover:tw-opacity-80"
            @click="goToNextQuestion"
          >
            {{ $t('interviewQuestion.goToResume') }}
            <ContractListIcon class="tw-mb-0.5 tw-w-8 tw-ml-2" />
          </button>
          <button
            v-else
            :disabled="loading"
            class="tw-flex tw-items-center hover:tw-opacity-80"
            @click="goToNextQuestion"
          >
            {{ $t('interviewQuestion.next') }}
            <ArrowCircleRight class="tw-mb-0.5 tw-w-8 tw-ml-2" />
          </button>
        </div>
      </div>
    </FpContainer>
    <InfoKeySlideIn
      v-if="isSlideInOpen"
      :info-key="currentQuestion.info"
      :lcid="contract.languageCode"
      @close="isSlideInOpen = false"
    >
    </InfoKeySlideIn>
  </div>
</template>
<script async setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { createContract, fetchContractQuestions } from '@/api/api'
import ContractQuestionInfoButton from '@/components/ContractQuestionInfoButton.vue'
import FpContainer from '@/components/FpContainer.vue'
import FpHeadingWithIconAndSubline from '@/components/FpHeadingWithIconAndSubline.vue'
import InfoKeySlideIn from '@/components/InfoKeySlideIn.vue'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'
import ArrowCircleLeft from '@/svg/ArrowCircleLeft.vue'
import ArrowCircleRight from '@/svg/ArrowCircleRight.vue'
import ContractListIcon from '@/svg/ContractListIcon.vue'
import type { QuestionDto } from '@/types/QuestionDto'
import type { ContractDto } from '@/types/api'

/**
 * Contains the entire interview for the specific contract. Handles navigation between questions.
 */
const props = defineProps<{
  contractTypeId: string
  roleId: string
  counterPartRoleId: string
  languageCode: string
  lawId: string
  questionIndex: string
}>()

const contract = ref<ContractDto>(
  await fetchContractQuestions(
    props.contractTypeId,
    props.roleId,
    props.counterPartRoleId,
    props.languageCode,
    props.lawId,
  ),
)

const loading = ref(false)

const isSlideInOpen = ref(false)

const { t } = useI18n()

const questionIndexAsNumber = computed<number>(() => {
  return parseInt(props.questionIndex, 10)
})

const currentQuestion = computed<QuestionDto>(() => {
  return activeQuestions.value[questionIndexAsNumber.value]
})

const updateCurrentQuestion = (question: QuestionDto) => {
  contract.value!.questions![contract.value!.questions!.findIndex((iQuestion) => iQuestion.id === question.id)] =
    question
}

/**
 * Returns all questions that are currently active (meaning their condition is met).
 */
const activeQuestions = computed<QuestionDto[]>(() => {
  return contract.value!.questions!.filter(isConditionFulfilled)
})

const isConditionFulfilled = (question: QuestionDto) => {
  if (!question.condition) {
    return true
  }
  return (
    /* @ts-ignore */ //Gives typescript error because not all question DTOs has a value, but we know the conditional ones always do, so it's not a problem right now
    contract.value!.questions!.find((iQuestion) => iQuestion.id === question.condition!.questionId)?.value ===
    question.condition.value
  )
}

const previousQuestionIndex = computed<number>(() => {
  return questionIndexAsNumber.value - 1
})

const hasPreviousQuestion = computed<boolean>(() => {
  return previousQuestionIndex.value >= 0
})

const nextQuestionIndex = computed<number>(() => {
  return questionIndexAsNumber.value + 1
})

const isFinalQuestion = computed<boolean>(() => {
  return nextQuestionIndex.value === activeQuestions.value.length
})

const router = useRouter()
const goToNextQuestion = async () => {
  if (isFinalQuestion.value) {
    loading.value = true
    const response = await createContract(contract.value)
    showConfirmModalOnRouteLeave.value = false
    await router.replace({
      name: 'Contract',
      params: { contractId: response.id },
    })
    return
  }
  await router.push({
    name: 'InterviewQuestion',
    params: {
      questionIndex: nextQuestionIndex.value,
    },
  })
}

const goToPreviousQuestion = () => {
  router.push({
    name: 'InterviewQuestion',
    params: {
      questionIndex: previousQuestionIndex.value,
    },
  })
}
const { showConfirmModal } = useConfirmModalStore()
const showConfirmModalOnRouteLeave = ref(true)
onBeforeRouteLeave((to, from, next) => {
  if (!showConfirmModalOnRouteLeave.value) {
    return next()
  }
  showConfirmModal({
    heading: t('interview.confirmLeaveModal.heading'),
    body: t('interview.confirmLeaveModal.body'),
    confirmCallback: () => {
      showConfirmModalOnRouteLeave.value = false
      next()
    },
  })
})
</script>

<style lang="scss">
.interview-questions-bg {
  background-image: url(../img/questions-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>
