import axios from 'axios'
import type { AxiosInstance, AxiosPromise } from 'axios'
import { authRequestInterceptor } from '@/api/interceptors/auth-request-interceptor'
import { authResponseInterceptor } from '@/api/interceptors/auth-response-interceptor'

class Http {
  instance: AxiosInstance

  constructor(baseURL: string, headers = {}, config = {}, timeout = 30000) {
    this.instance = axios.create({
      headers,
      timeout,
      baseURL,
      paramsSerializer: {
        indexes: null,
      },
      ...config,
    })

    this.instance.interceptors.request.use(authRequestInterceptor)
    this.instance.interceptors.response.use((response) => response, authResponseInterceptor)
  }

  get<T>(url: string, params = {}, headers = {}, config = {}): AxiosPromise<T> {
    return this.instance.get(url, {
      params,
      headers,
      ...config,
    })
  }

  patch<T>(url: string, data: object, params = {}, headers = {}, config = {}): AxiosPromise<T> {
    return this.instance.patch(url, data, {
      params,
      headers,
      ...config,
    })
  }

  put<T>(url: string, data: object, params = {}, headers = {}, config = {}): AxiosPromise<T> {
    return this.instance.put(url, data, {
      params,
      headers,
      ...config,
    })
  }

  post<T>(url: string, data: object, params = {}, headers = {}, config = {}): AxiosPromise<T> {
    return this.instance.post(url, data, {
      params,
      headers,
      ...config,
    })
  }

  delete<T>(url: string, params = {}, headers = {}, config = {}): AxiosPromise<T> {
    return this.instance.delete(url, {
      params,
      headers,
      ...config,
    })
  }
}

const http = new Http(import.meta.env.VITE_API_URL, {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
})

export const api = http
