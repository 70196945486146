import dayjs from 'dayjs'
import { currencyFormatter, numberFormatter } from '@/utils/formatterFactory'

export const formatNumber = (val: number, fractionDigits = 2, alternativeZeroValue = null) => {
  const formatter = numberFormatter(fractionDigits)
  return !val ? alternativeZeroValue || formatter.format(0) : formatter.format(val)
}

export const formatCurrency = (val: number, currency: string, alternativeZeroValue = null) => {
  const formatter = currencyFormatter(currency)
  return !val ? alternativeZeroValue || formatter.format(0) : formatter.format(val)
}

export const formatDateTimeMidFormat = (val: string) => {
  return dayjs(val).format('D MMM. YYYY - HH:mm')
}
