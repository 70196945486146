<template>
  <div class="tw-grid tw-grid-cols-12">
    <div class="tw-hidden xl:tw-block tw-col-span-2" />
    <div
      class="tw-border-inputBorderGrey tw-border tw-bg-light tw-rounded-lg tw-shadow-summaryQuestion tw-col-span-12 xl:tw-col-span-10"
    >
      <div
        class="tw-grid tw-grid-cols-12 tw-p-4 tw-rounded-t-md"
        :class="
          question.changedSinceLastSwitch
            ? 'tw-bg-yellowBackground'
            : question.answeredBy === 'FairPlay'
            ? 'tw-bg-lightBlue'
            : ''
        "
      >
        <div
          class="tw-col-span-12 md:tw-col-span-6 xl:tw-col-span-7 tw-flex tw-flex-col tw-justify-between tw-border-b md:tw-border-b-0 md:tw-border-r tw-border-inputBorderGrey tw-pb-4 tw-mb-4 md:tw-pb-0 md:tw-mb-0 md:tw-pr-4 md:tw-mr-4"
        >
          <div>
            <h6 class="tw-mb-1 tw-font-extralight">
              {{ $t('contractSummary.question') }} <span class="tw-text-muted">({{ question.number }})</span>
            </h6>
            {{ question.question }}
          </div>
          <ContractQuestionInfoButton
            class="tw-mt-4"
            size="small"
            :active="info === question.info"
            @click="$emit('update:info', question.info)"
          />
        </div>
        <div class="tw-col-span-12 md:tw-col-span-6 xl:tw-col-span-5">
          <h6 class="tw-mb-1 tw-font-extralight">
            {{ $t('contractSummary.answer') }}
            <span
              v-if="question.changedSinceLastSwitch"
              class="tw-italic"
            >
              {{ $t('contractSummary.answeredByCounterpart') }}
            </span>
            <span
              v-else-if="question.answeredBy === 'FairPlay'"
              class="tw-italic"
            >
              {{ $t('contractSummary.answeredByFairPlay') }}
            </span>
          </h6>
          <InterviewQuestion
            :question="question"
            :disabled="disabled"
            @update:question="$emit('update:question', $event)"
          />
        </div>
      </div>
      <CollapseHeightTransition>
        <div
          v-if="commentsOpen"
          class="tw-border-t tw-border-inputBorderGrey"
        >
          <div
            class="tw-py-4 tw-grid tw-grid-cols-12 tw-px-4"
            :class="
              question.comments.some((comment) => comment.changedSinceLastSwitch)
                ? 'tw-bg-yellowBackground'
                : 'tw-bg-bgGreyDark'
            "
          >
            <div
              class="tw-col-span-12 md:tw-col-span-6 xl:tw-col-span-7 md:tw-border-b-0 md:tw-border-r tw-border-inputBorderGrey md:tw-mb-0 md:tw-pr-4 md:tw-mr-4"
            >
              <div
                class="tw-p-4 tw-rounded md:tw-block"
                :class="question.comments.length > 0 ? 'tw-bg-light tw-block tw-mb-4' : 'tw-hidden'"
              >
                <div
                  v-for="comment in question.comments"
                  :key="comment.id"
                  class="[&:not(:last-child)]:tw-mb-4"
                >
                  <div
                    class="tw-flex tw-flex-col"
                    :class="{
                      'tw-items-end tw-text-right': currentUserId === comment.userId,
                    }"
                  >
                    <div class="tw-text-xs">
                      {{ formatDateTimeMidFormat(comment.date) }}
                    </div>
                    <div>{{ comment.user }}:</div>
                    <div
                      class="tw-py-2.5 tw-px-4 tw-rounded-b-2xl tw-w-fit tw-max-w-[80%]"
                      :class="
                        currentUserId === comment.userId
                          ? 'tw-bg-lightBlue tw-rounded-tl-2xl'
                          : 'tw-bg-primaryBlue tw-rounded-tr-2xl tw-text-light'
                      "
                    >
                      {{ comment.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-col-span-12 md:tw-col-span-6 xl:tw-col-span-5 tw-flex tw-items-start">
              <div class="tw-flex tw-items-center tw-w-full">
                <FpInput
                  v-model="commentInputField"
                  :disabled="loading || disabled"
                  @keydown.enter="addComment"
                />
                <button
                  class="tw-ml-2 enabled:hover:tw-opacity-80 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
                  :disabled="!canComment"
                  @click="addComment"
                >
                  <FpIconSend class="tw-w-[36px]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </CollapseHeightTransition>
      <div
        class="tw-px-4 tw-py-2 tw-bg-bgGreyDark tw-flex tw-items-center tw-cursor-pointer tw-border-t tw-border-inputBorderGrey tw-gap-2"
        @click="$emit('update:commentsOpen', !commentsOpen)"
      >
        <FpAngleDown
          class="tw-w-[12px] tw-transition-transform tw-mb-[2px]"
          :class="commentsOpen ? '-tw-rotate-180' : '-tw-rotate-90'"
        />
        {{ $t('contractSummary.commentHistory') }}
        <div
          v-if="question.comments.length > 0"
          class="tw-bg-dangerRed tw-rounded-full tw-text-light tw-w-6 tw-h-6 tw-text-center tw-pt-[1px]"
        >
          {{ question.comments.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { postCommentToQuestion } from '@/api/api'
import ContractQuestionInfoButton from '@/components/ContractQuestionInfoButton.vue'
import FpInput from '@/components/FpInput.vue'
import CollapseHeightTransition from '@/components/Transitions/CollapseHeightTransition.vue'
import InterviewQuestion from '@/pages/InterviewQuestion.vue'
import { useAuthStore } from '@/stores/auth-store'
import FpAngleDown from '@/svg/FpAngleDown.vue'
import FpIconSend from '@/svg/FpIconSend.vue'
import type { CommentDto, QuestionDto } from '@/types/QuestionDto'
import { formatDateTimeMidFormat } from '@/utils/formatters'

const props = defineProps<{
  contractId: number | string
  question: QuestionDto
  commentsOpen: boolean
  info: string | null
  disabled?: boolean
}>()

const emit = defineEmits<{
  'update:info': [string]
  'update:commentsOpen': [boolean]
  'update:question': [QuestionDto]
  'update:comments': [CommentDto[]]
}>()

const { currentUserId } = storeToRefs(useAuthStore())

const commentInputField = ref('')

const loading = ref(false)

const canComment = computed<boolean>(() => {
  return !loading.value && !props.disabled && !!commentInputField.value.trim().length
})

const addComment = async () => {
  if (!canComment.value) {
    return
  }
  loading.value = true
  const response = await postCommentToQuestion(props.contractId, props.question.id, commentInputField.value)
  emit('update:comments', props.question.comments.concat([response]))
  commentInputField.value = ''
  loading.value = false
}
</script>
