<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_5386_00000152239045516999157730000013818267171055974272_"
      d="M13 3 3 13v29h31V3H13z"
      style="fill: #bfd6e7"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #8cb6d3"
    />
    <path
      d="M17.54 11.76c2.02 0 3.657 1.932 3.657 4.315S19.56 20.42 17.54 20.42s-3.658-1.963-3.658-4.345 1.637-4.315 3.658-4.315zM9.5 37v-9.5c0-2.532.255-4.11 1.756-5.235 1.125-.938 4.69-1.845 6.284-1.845M21.573 34.645l.844-8.253M13.976 34.833l-.938-8.441"
      class="st2"
    />
    <path
      d="M17.446 20.42c1.5 0 5.158.907 6.284 1.751 1.5 1.126 1.77 2.797 1.77 5.329V37M28 7.5h13M28 9.5h9.892M28 11.5h13M28 13.5h13M28 15.5h8M28 17.5h12M28 19.5h10"
      class="st2"
    />
    <circle
      cx="25.7"
      cy="19.5"
      r=".469"
      class="st3"
    />
    <circle
      cx="25.7"
      cy="17.5"
      r=".469"
      class="st3"
    />
    <circle
      cx="25.7"
      cy="15.5"
      r=".469"
      class="st3"
    />
    <circle
      cx="25.7"
      cy="13.5"
      r=".469"
      class="st3"
    />
    <circle
      cx="25.7"
      cy="11.5"
      r=".469"
      class="st3"
    />
    <circle
      cx="25.7"
      cy="9.5"
      r=".469"
      class="st3"
    />
    <circle
      cx="25.7"
      cy="7.5"
      r=".469"
      class="st3"
    />
  </svg>
</template>
<style>
.st2 {
  fill: none;
  stroke: #1d1e1c;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #1d1e1c;
}
</style>
