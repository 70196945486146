<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="svg-1"
      fill="none"
      stroke="#1D1D1B"
      cx="25.03"
      cy="25.03"
      r="22.03"
      style="stroke-width: 1.5px"
    />
    <circle
      class="svg-2"
      fill="none"
      stroke="#1D1D1B"
      cx="25.051"
      cy="17.735"
      r="6.685"
      style="stroke-width: 1.5px"
    />
    <path
      class="svg-3"
      fill="none"
      stroke="#1D1D1B"
      d="M 13.657 36.675 C 13.657 30.369 18.765 25.282 25.051 25.282 C 31.336 25.282 36.444 30.39 36.444 36.675"
      style="stroke-width: 1.5px"
    />
  </svg>
</template>
