<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      style="fill: #f6b8b5"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #f28c88"
    />
    <path
      d="M18.06 23.92v3.554c0 3.37-2.741 6.11-6.11 6.11s-6.11-2.74-6.11-6.11V23.92"
      class="st2"
    />
    <path
      d="M11.95 31.507a4.033 4.033 0 0 1-4.033-4.033v-8.047a4.033 4.033 0 0 1 8.065 0v8.047a4.033 4.033 0 0 1-4.032 4.033zM5.839 24.866H18.06M11.95 31.507v5.792"
      class="st3"
    />
    <path
      d="M8.516 37.299h6.867"
      class="st2"
    />
    <path
      d="M39.494 28.752c-1.521 0-2 0-2 1 0 1.46-.57 3-1 3-.99 0-1.01-8-2-8s-1.01 10-2 10-1.01-12-2-12-1.01 12-2 12-1.01-10-2-10-1.01 8-2 8c-.382 0-1-1.743-1-3 0-1-.174-1-3-1"
      style="fill: none; stroke: #1d1e1c; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
    />
    <circle
      cx="25.248"
      cy="12.622"
      r="5.244"
      class="st3"
    />
    <circle
      cx="25.248"
      cy="12.622"
      r="2.312"
      style="fill: #1d1d1b"
    />
  </svg>
</template>
<style>
.st2 {
  stroke-linecap: round;
}
.st2,
.st3 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
