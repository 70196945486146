<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 31 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      style="fill: none; stroke: #000000"
      cx="15.5"
      cy="15.5"
      r="15"
    />
    <path
      style="fill: none; stroke: #000000"
      d="M 8.361 15.498 L 21.681 15.498"
      transform="matrix(-1, 0, 0, -1, 30.042, 30.996)"
    />
    <path
      style="fill: none; stroke: #000000"
      d="M 22.001 21.628 L 15.881 15.508 L 22.011 9.378"
      transform="matrix(-1, 0, 0, -1, 37.891999, 31.006001)"
    />
  </svg>
</template>
