<template>
  <VueDatepicker
    :model-value="modelValue"
    :clearable="!hideClearButton"
    :time-picker="mode === 'time'"
    :format="mode === 'date' ? 'dd/MM/yyyy' : 'HH:mm'"
    :model-type="mode === 'date' ? 'yyyy-MM-dd' : 'HH:mm'"
    :range="!!range"
    :enable-time-picker="mode === 'time'"
    :disabled="!!disabled"
    :inline="!!inline"
    :month-change-on-scroll="false"
    auto-apply
    @update:model-value="onDatepickerModelValueUpdate"
    @open="datepickerIsOpen = true"
    @closed="datepickerIsOpen = false"
  >
    <template #dp-input="{ value }">
      <FpInput
        class="tw-border tw-border-inputBorderGrey tw-bg-light tw-h-7"
        :has-error="hasError"
        :placeholder="placeholder"
        :disabled="disabled"
        :model-value="value"
        clickable
      />
    </template>
  </VueDatepicker>
</template>

<script setup lang="ts">
// Extends https://vue3datepicker.com/
import VueDatepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue'
import FpInput from '@/components/FpInput.vue'

type ModelValueType = null | string

const props = withDefaults(
  defineProps<{
    modelValue: ModelValueType
    mode?: 'date' | 'time'
    hasError?: boolean
    hideClearButton?: boolean
    range?: boolean
    placeholder?: string
    disabled?: boolean
    inline?: boolean
  }>(),
  {
    modelValue: null,
    mode: 'date',
    enableTimePicker: false,
    placeholder: undefined,
  },
)

const emit = defineEmits<{
  (e: 'update:model-value', value: typeof props.modelValue): void
  (e: 'enter', value: KeyboardEvent): void
}>()

const datepickerIsOpen = ref(false)

const onDatepickerModelValueUpdate = ($event: string | null) => {
  if (!$event) {
    emit('update:model-value', null)
    return
  }
  emit('update:model-value', $event)
}
</script>

<style scoped lang="scss">
@import '../scss/theme';

:deep(.dp__theme_light) {
  --dp-success-color: #{$success};
  --dp-primary-color: #{$success};
  --dp-primary-disabled-color: #{$secondary};
  --dp-success-color-disabled: #{$secondary};
  --dp-danger-color: #{$danger};
  --dp-marker-color: #{$danger};
  --dp-cell-border-radius: 50%;
}

:deep(.dp__time_input),
:deep(.dp__menu),
:deep(.dp__main),
:deep(.dp__calendar_wrap) {
  font-family: $font-primary;
}

:deep(.dp__cell_inner) {
  padding-top: 0.375rem; //Fix weird alignment of numbers in current date/selected date circles in datepicker
  align-items: flex-start;
}

:deep(.dp__menu),
:deep(.dp__main) {
  font-feature-settings: 'ss02' on, 'ss03' on;
}
</style>
