<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="6.06 9.12 32.36 27.82"
  >
    <g>
      <g>
        <g>
          <polyline
            class="st0"
            points="27.52187,15.61725 27.52187,9.62176 11.49895,9.62176 6.5625,14.59375 6.5625,31.44078     6.5625,33.44656 27.52187,33.44656 27.52187,30.04042    "
          />
          <polyline
            class="st1"
            points="11.49895,9.62176 11.49895,14.59375 6.5625,14.59375    "
          />
        </g>
        <g>
          <polyline
            class="st2"
            points="27.52187,15.61725 27.52187,9.62176 11.49895,9.62176 6.5625,14.59375 6.5625,31.44078     6.5625,33.44656 27.52187,33.44656 27.52187,30.04042    "
          />
          <polyline
            class="st2"
            points="11.49895,9.62176 11.49895,14.59375 6.5625,14.59375    "
          />
        </g>
      </g>
      <g>
        <g>
          <polyline
            class="st0"
            points="30.5625,15.27975 30.5625,12.5 27.52187,12.5    "
          />
          <polyline
            class="st0"
            points="9.54167,33.88919 9.54167,36.4375 30.5625,36.4375 30.5625,30.45562    "
          />
        </g>
        <g>
          <polyline
            class="st2"
            points="30.5625,15.27975 30.5625,12.5 27.52187,12.5    "
          />
          <polyline
            class="st2"
            points="9.54167,33.88919 9.54167,36.4375 30.5625,36.4375 30.5625,30.45562    "
          />
        </g>
      </g>
      <g>
        <circle
          class="st3"
          cx="30.33005"
          cy="22.86768"
          r="7.58794"
        />
      </g>
    </g>
    <g>
      <path
        class="st3"
        d="M27.1894,20.97161c0-1.56261,1.26675-2.82936,2.82936-2.82936c1.56261,0,2.82936,1.26675,2.82936,2.82936   c0,2.52625-2.82936,2.39154-2.82936,5.65837"
      />
      <circle
        class="st4"
        cx="30.00317"
        cy="28.10406"
        r="0.73342"
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.59896;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.60114;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.st4 {
  fill: currentColor;
  stroke: none;
}
</style>
