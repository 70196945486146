<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #a3d3c2"
        />
        <path
          d="M23.5 17.5c0 2.9-1.4 4.8-3.5 4.8s-3.5-1.9-3.5-4.8.5-4.9 3.5-4.9c2.8 0 3.5 2 3.5 4.9zM12.5 39v-8.9c0-2.7.5-5.3 2-6.6 1.2-.9 3.6-1.2 5.2-1.2M23.5 37.5l1-9M16.5 37.5l-1-9"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M19.7 22.3c1.6 0 5.4 1 6.5 1.9 1.5 1.2 1.3 3.2 1.3 5.9V39"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <ellipse
          cx="9.7"
          cy="17.9"
          rx="3.1"
          ry="3.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="m3.9 35.8-.4-8.1c0-2.1-.2-3.7 1-4.7.9-.7 3.9-1.5 5.2-1.5M9.7 21.5c1.3 0 4.3.8 5.2 1.5.1.1.2.1.2.2"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <ellipse
          cx="29.7"
          cy="17.9"
          rx="3.1"
          ry="3.6"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M24.2 23.2c.9-.7 4.2-1.7 5.5-1.7M29.7 21.5c1.3 0 4.3.8 5.2 1.5 1.2 1 1 2.5 1 4.7l-.3 8.1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="20.1"
          cy="20.1"
          r="15.7"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
