<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <circle
          cx="20"
          cy="20"
          r="19"
          style="fill: #f0d87b"
        />
        <path
          d="M26.9 29.5H5.8c-.5 0-.8-.4-.8-.8V15.1c0-.5.4-.8.8-.8h21.1c.5 0 .8.4.8.8v13.6c0 .4-.4.8-.8.8z"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
        <path
          d="m27.7 18.9 7.9-3.6c.3-.2.7 0 .7.3v12.7c0 .3-.4.5-.7.3L27.7 25"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
        <circle
          cx="14"
          cy="11.1"
          r="3.1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
        <circle
          cx="21.9"
          cy="9.5"
          r="4.8"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
        <path
          d="M7.7 17.1h7.2V21H7.7zM39.3 13.8V9.4h-4.6M5.3 9.4H.7v4.4M.7 29.2v4.1h4.6M34.7 33.3h4.6v-4.1"
          style="fill: none; stroke: #1d1d1b; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
