<template>
  <div>
    <FpDatepicker
      :model-value="computedDate"
      :disabled="disabled"
      inline
      @update:model-value="
        $emit('update:question', {
          ...question,
          value: $event,
        })
      "
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import FpDatepicker from '@/components/FpDatepicker.vue'
import type { DateRangeDto } from '@/types/QuestionDto'

const props = defineProps<{
  question: DateRangeDto
  disabled?: boolean
}>()

defineEmits<{
  'update:question': [question: DateRangeDto]
}>()

const computedDate = computed(() => {
  if (!props.question.from) {
    return null
  }
  return props.question.to?.substring(0, 8)
})
</script>
