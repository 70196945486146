import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { fetchCurrentUser } from '@/api/api'
import { goToLogOut } from '@/services/OAuthService'
import type { UserDto } from '@/types/api'

export const useAuthStore = defineStore('auth', () => {
  const user = ref<UserDto | null>(null)
  const token = useStorage<string | null>('token', null, localStorage)

  const fetchLoggedInUser = async () => {
    user.value = await fetchCurrentUser()
  }

  const isLoggedIn = computed(() => {
    return !!token.value
  })

  const logOut = async () => {
    token.value = null
    user.value = null
    await goToLogOut()
  }

  const handleExpiredToken = () => {
    token.value = null
    user.value = null
  }

  const currentUserId = computed<string | null>(() => {
    return user.value?.id || null
  })

  const fullName = computed<string | null>(() => {
    return `${user.value?.firstName} ${user.value?.lastName}`
  })

  return {
    currentUserId,
    fetchLoggedInUser,
    fullName,
    user,
    isLoggedIn,
    token,
    logOut,
    handleExpiredToken,
  }
})
