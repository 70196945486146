<template>
  <FpContainer>
    <h3 class="tw-text-3xl tw-font-light tw-mt-20 tw-mb-8">
      {{ $t('gdpr.heading') }}
    </h3>
    <i18n-t
      class="tw-whitespace-pre-wrap tw-mb-20"
      keypath="gdpr.body"
      scope="global"
      tag="p"
    >
      <template #email>
        <a
          class="tw-text-primaryBlue"
          :href="`mailto:${supportEmail}`"
          target="_blank"
        >
          {{ supportEmail }}
        </a>
      </template>
    </i18n-t>
  </FpContainer>
</template>
<script setup lang="ts">
import FpContainer from '@/components/FpContainer.vue'

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
</script>
