<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      fill="none"
      stroke-width="3"
      stroke="CurrentColor"
      x1="0"
      y1="0"
      x2="25"
      y2="25"
    />
    <line
      stroke-width="3"
      fill="none"
      stroke="currentColor"
      x1="0"
      y1="25"
      x2="25"
      y2="0"
    />
  </svg>
</template>
