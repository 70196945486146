<template>
  <FpContainer class="tw-mb-20">
    <FpHeadingWithContractIcon
      class="tw-mt-8"
      icon="list"
    >
      {{ $t(`contractSummary.heading.${contract.status}`) }}
    </FpHeadingWithContractIcon>
    <div class="tw-overflow-hidden">
      <ContractSummaryBanner class="tw-h-[93px]" />
    </div>
    <div
      class="tw-flex tw-items-center tw-justify-center tw-text-[30px] tw-font-medium tw-relative tw-top-[-46px] tw-h-0 tw-overflow-visible"
    >
      {{ $t(`contractType.${contract.contractTypeId}`) }}
      <ContractQuestionInfoButton
        size="small"
        class="tw-relative tw-top-[-15px]"
        :active="selectedInfoKey === contract.contractTypeInfo"
        @click="onInfoButtonClicked(contract.contractTypeInfo)"
      />
    </div>
    <ContractPartysSection
      v-model:email-of-counter-part="emailOfCounterPart"
      :invitation-status="invitationStatus"
      :contract="contract"
      :disabled="!isCurrentUsersTurn || contract.readOnly || !!pdfContract || contract.status === 'Canceled'"
      :email-has-error="emailFieldHasError"
    />
    <div class="tw-flex tw-justify-end tw-items-center tw-gap-2 tw-mt-4">
      <p class="tw-mt-1 tw-uppercase tw-font-semibold">{{ $t('contractSummary.show') }}</p>
      <FpSelect
        v-model="questionFilter"
        :disabled="!isCurrentUsersTurn || contract.status === 'Draft'"
        :options="[
          {
            value: 'all',
            label: $t('contractSummary.filter.allQuestions'),
          },
          {
            value: 'latest_changes',
            label: $t('contractSummary.filter.recentlyChangedQuestions'),
          },
        ]"
      />
    </div>
    <div
      v-for="(question, index) in filteredQuestions"
      :key="question.id"
      class="tw-mb-4"
    >
      <FpHeadingWithIconAndSubline
        v-if="index === 0 || question.categoryId !== filteredQuestions?.[index - 1].categoryId"
        :icon="question.icon"
        class="tw-mb-4"
      >
        {{ question.category }}
      </FpHeadingWithIconAndSubline>
      <ContractSummaryQuestion
        v-model:comments-open="openComments[question.number - 1]"
        :info="selectedInfoKey"
        :contract-id="contractId"
        :question="question"
        :disabled="!isCurrentUsersTurn || contract.readOnly || !!pdfContract || contract.status === 'Canceled'"
        @update:info="onInfoButtonClicked"
        @update:question="onQuestionUpdate($event, question.number - 1)"
        @update:comments="question.comments = $event"
      />
    </div>
    <div v-if="questionFilter === 'all'">
      <FpHeadingWithIconAndSubline
        icon="law"
        class="tw-mb-4"
      >
        {{ $t('contractSummary.headings.lawAndJurisdiction') }}
      </FpHeadingWithIconAndSubline>
      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-mb-4">
        <div class="tw-hidden xl:tw-block tw-col-span-2" />
        <div
          class="tw-border-inputBorderGrey tw-whitespace-pre-wrap tw-border tw-bg-light tw-rounded-lg tw-shadow-summaryQuestion tw-col-span-12 xl:tw-col-span-10 tw-p-4"
        >
          {{ $t(`contractSummary.lawAndJurisdiction.${contract.lawId}`) }}
        </div>
      </div>
    </div>
    <div class="tw-mb-4">
      <FpHeadingWithIconAndSubline
        icon="send_contract"
        class="tw-mb-4"
      >
        {{ $t('contractSummary.headings.negotiationAndComposition') }}
      </FpHeadingWithIconAndSubline>
      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-text-light">
        <div class="tw-hidden xl:tw-block tw-col-span-2" />
        <div
          class="tw-col-span-12 md:tw-col-span-6 xl:tw-col-span-5 tw-bg-successGreen tw-rounded-lg tw-p-5 tw-flex tw-flex-col tw-justify-between"
        >
          <div>
            <h4 class="tw-font-semibold tw-mb-5 tw-uppercase">{{ $t('contractSummary.headings.send') }}</h4>
            <p class="tw-whitespace-pre-wrap tw-mb-6">
              {{ $t('contractSummary.sendDescription') }}
            </p>
          </div>
          <div class="tw-flex tw-justify-between">
            <FpButton
              accent="grey"
              :disabled="
                invitationStatus?.status === 'InvitationIkkeBesvaret' ||
                loading ||
                !isCurrentUsersTurn ||
                contract.readOnly ||
                !!pdfContract ||
                contract.status === 'Canceled' ||
                generatingContract
              "
              @click="sendToCounterPart"
            >
              {{ $t('contractSummary.buttons.send') }}
            </FpButton>
            <FpCheckbox
              v-if="contract.counterpartNegotiatorId"
              v-model="readyForSignature"
              :disabled="!isCurrentUsersTurn || contract.readOnly || !!pdfContract || contract.status === 'Canceled'"
              checkbox-right
            >
              {{ $t('contractSummary.readyForContract') }}
            </FpCheckbox>
          </div>
        </div>
        <div
          class="tw-col-span-12 md:tw-col-span-6 xl:tw-col-span-5 tw-bg-primary tw-rounded-lg tw-flex tw-flex-col tw-justify-between tw-p-5"
        >
          <div>
            <h4 class="tw-font-semibold tw-mb-5 tw-uppercase">{{ $t('contractSummary.headings.legalDocument') }}</h4>
            <p class="tw-whitespace-pre-wrap tw-mb-6">
              {{ $t('contractSummary.createContractDescription') }}
            </p>
          </div>
          <div class="tw-flex tw-gap-4 tw-flex-wrap tw-items-center">
            <FpButton
              v-if="!pdfContract"
              accent="grey"
              :disabled="
                loading ||
                !isCurrentUsersTurn ||
                !otherPartIsReadyForContract ||
                hasMadeChangesSinceLoadingContract ||
                contract.readOnly ||
                contract.status === 'Canceled'
              "
              :loading="generatingContract"
              @click="generateContract"
            >
              {{ $t('contractSummary.buttons.createContract') }}
            </FpButton>
            <FpButton
              v-if="pdfContract"
              accent="grey"
              :disabled="contract.status === 'Canceled'"
              @click="openContractPdf"
            >
              {{ $t('contractSummary.buttons.seeContract') }}
            </FpButton>
            <FpButton
              v-if="pdfContract && !currentUserSignedAt && !justSigned"
              accent="grey"
              :disabled="contract.status === 'Canceled' || contract.status === 'Signed'"
              @click="showSignContractModal = true"
            >
              {{ $t('contractSummary.buttons.signContract') }}
            </FpButton>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="contract.status !== 'Canceled' && contract.status !== 'Signed'"
      class="tw-grid tw-grid-cols-12"
    >
      <div class="tw-hidden xl:tw-block tw-col-span-2" />
      <div class="tw-col-span-12 xl:tw-col-span-10">
        {{ $t('contractSummary.haveYouChangedYourMind') }}
        <div>
          <i18n-t
            keypath="contractSummary.youCanStillCancelContract"
            scope="global"
          >
            <template #link>
              <button
                class="tw-underline tw-text-primaryBlue"
                @click="confirmCancelContract"
              >
                {{ $t('contractSummary.cancelContract') }}
              </button>
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
    <InfoKeySlideIn
      v-if="selectedInfoKey"
      :info-key="selectedInfoKey"
      :lcid="contract.languageCode"
      @close="selectedInfoKey = null"
    />
    <SignContractModal
      v-if="showSignContractModal"
      :contract="contract"
      @contract-signed="onContractSigned"
      @close="showSignContractModal = false"
    />
    <MissingContractPartyInfoModal
      v-if="missingInfoModalContext"
      :missing-info="missingInfoModalContext"
      :contract="contract"
      @close="missingInfoModalContext = null"
      @contract-generated="onContractGenerated"
    />
  </FpContainer>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  cancelContract,
  changeContractNegotiationTurn,
  createPDFContract,
  fetchContract,
  fetchContractInvitationStatus,
  fetchPDFContract,
  getMissingPartyInfoForContractWithId,
  inviteCounterpartToContract,
  tryGeneratePDFContract,
  updateContractQuestion,
} from '@/api/api'
import ContractPartysSection from '@/components/Contract/ContractPartysSection.vue'
import SignContractModal from '@/components/Contract/SignContractModal.vue'
import ContractQuestionInfoButton from '@/components/ContractQuestionInfoButton.vue'
import ContractSummaryQuestion from '@/components/ContractSummaryQuestion.vue'
import FpButton from '@/components/FpButton.vue'
import FpCheckbox from '@/components/FpCheckbox.vue'
import FpContainer from '@/components/FpContainer.vue'
import FpHeadingWithContractIcon from '@/components/FpHeadingWithContractIcon.vue'
import FpHeadingWithIconAndSubline from '@/components/FpHeadingWithIconAndSubline.vue'
import FpSelect from '@/components/FpSelect.vue'
import InfoKeySlideIn from '@/components/InfoKeySlideIn.vue'
import MissingContractPartyInfoModal from '@/components/Modals/MissingContractPartyInfoModal.vue'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'
import { useToastStore } from '@/stores/toast-store'
import ContractSummaryBanner from '@/svg/ContractSummaryBanner.vue'
import type { MissingInfoDto } from '@/types/MissingInfoDto'
import type { QuestionDto } from '@/types/QuestionDto'
import type { ContractDto, CounterPartInvitationStatusDto } from '@/types/api'
import { celebrate } from '@/utils/confettiService'
import { isMissingInfoDtoMissingAnyInfo } from '@/utils/missingInfoService'

const props = defineProps<{
  contractId: string
}>()

const selectedInfoKey = ref<string | null>(null)

const questionFilter = ref<'all' | 'latest_changes'>('all')

const contract = ref<ContractDto>(await fetchContract(props.contractId))

const { locale, t } = useI18n()
locale.value = contract.value.languageCode!
const openComments = ref<boolean[]>(contract.value.questions.map((question) => question.comments.length > 0))

const { toast } = useToastStore()

const { showConfirmModal } = useConfirmModalStore()

const router = useRouter()
const confirmCancelContract = async () => {
  showConfirmModal({
    heading: t('contractSummary.confirms.cancelContract.heading'),
    body: t('contractSummary.confirms.cancelContract.body'),
    confirmCallback: async () => {
      try {
        await cancelContract(contract.value.id)
        toast(t('contractSummary.toasts.contractCancelled'), 'success')
        await router.replace('/dashboard')
      } catch (error) {
        toast(t('contractSummary.toasts.genericError', 'error'))
      }
    },
  })
}

/* Questions */
/**
 * Returns all questions that are currently active (meaning their condition is met).
 */
const filteredQuestions = computed<QuestionDto[]>(() => {
  return contract.value!.questions!.filter(isConditionFulfilled).filter(isQuestionOrCommentsChangedSinceLastTurn)
})

const isQuestionOrCommentsChangedSinceLastTurn = (question: QuestionDto) => {
  if (questionFilter.value === 'all') {
    return true
  }
  if (question.changedSinceLastSwitch) {
    return true
  }
  return question.comments.some((comment) => comment.changedSinceLastSwitch)
}

const isConditionFulfilled = (question: QuestionDto) => {
  if (!question.condition) {
    return true
  }
  return (
    /* @ts-ignore */ //Gives typescript error because not all question DTOs has a value, but we know the conditional ones always do, so it's not a problem right now
    contract.value!.questions!.find((iQuestion) => iQuestion.id === question.condition!.questionId)?.value ===
    question.condition.value
  )
}

const hasMadeChangesSinceLoadingContract = ref(false)
const updateQuestionWithIndex = async (index: number) => {
  hasMadeChangesSinceLoadingContract.value = true
  try {
    await updateContractQuestion(contract.value.id, contract.value.questions[index])
    toast(t('contractSummary.toasts.questionUpdated'), 'success')
  } catch (error) {
    toast(t('contractSummary.toasts.genericError'), 'error')
  }
}
/**
 * To make all the debounces work independently for different questions, we map a debounce function to each question
 */
const debounceFunctions = contract.value.questions.map((_, index) =>
  useDebounceFn(() => updateQuestionWithIndex(index), 500),
)
const onQuestionUpdate = async (question: QuestionDto, index: number) => {
  contract.value.questions[index] = question
  await debounceFunctions[index]()
}

/* Info */
const onInfoButtonClicked = (event: string) => {
  if (selectedInfoKey.value === event) {
    selectedInfoKey.value = null
    return
  }
  selectedInfoKey.value = event
}

/* Invitation */
const emailOfCounterPart = ref<string>('')
const invitationStatus = ref<CounterPartInvitationStatusDto | null>(null)
const loadInvitationStatus = async () => {
  try {
    invitationStatus.value = await fetchContractInvitationStatus(props.contractId)
  } catch (error) {
    invitationStatus.value = null
  }
}
await loadInvitationStatus()

/* Negotiation */
const loading = ref(false)
const emailFieldHasError = ref(false)
const sendToCounterPart = async () => {
  loading.value = true
  if (readyForSignature.value && (await contractHasInvalidAnswers())) {
    loading.value = false
    return
  }
  if (contract.value.counterpartNegotiatorId) {
    try {
      await changeContractNegotiationTurn(contract.value.id, { readyForSignature: readyForSignature.value })
      toast(t('contractSummary.toasts.turnChange'), 'success')
      await router.push('/dashboard')
    } catch (error) {
      toast(t('contractSummary.toasts.genericError'), 'error')
    } finally {
      loading.value = false
    }
    return
  }
  try {
    await inviteCounterpartToContract(contract.value.id, {
      email: emailOfCounterPart.value,
      readyForSignature: readyForSignature.value,
    })
    toast(t('contractSummary.toasts.invitationSent'), 'success')
    await router.push('/dashboard')
  } catch (error) {
    toast(t('contractSummary.toasts.invalidEmail'), 'error')
    emailFieldHasError.value = true
    window.scrollTo(0, 0)
  } finally {
    loading.value = false
  }
}

const isCurrentUsersTurn = computed(() => {
  return contract.value.turn === 'AwaitingMe'
})

const readyForSignature = ref(false)

/* Contract generation */

const authStore = useAuthStore()

const currentUserIsCounterpart = computed(() => {
  return authStore.user!.id !== contract.value.negotiatorId
})
const otherPartIsReadyForContract = computed(() => {
  if (currentUserIsCounterpart.value) {
    return contract.value.partyReadyToSign
  }
  return contract.value.counterpartReadyToSign
})

const generatingContract = ref(false)
const missingInfoModalContext = ref<MissingInfoDto | null>(null)

const contractHasInvalidAnswers = async (): Promise<boolean> => {
  const potentialErrorResponse = await tryGeneratePDFContract(contract.value.id)
  if (potentialErrorResponse.errorOcurred) {
    toast(t('contractSummary.toasts.couldNotGenerateContract'), 'error')
    return true
  }
  return false
}
const generateContract = async () => {
  generatingContract.value = true
  if (await contractHasInvalidAnswers()) {
    generatingContract.value = false
    return
  }
  const potentialMissingInfoResponse = await getMissingPartyInfoForContractWithId(contract.value.id)
  if (isMissingInfoDtoMissingAnyInfo(potentialMissingInfoResponse)) {
    missingInfoModalContext.value = potentialMissingInfoResponse
    generatingContract.value = false
    return
  }
  try {
    await createPDFContract(contract.value.id)
    await loadPDFContract()
  } catch (error) {
    toast('contractSummary.toasts.genericError', 'error')
  } finally {
    generatingContract.value = false
  }
}

const onContractGenerated = async () => {
  await loadPDFContract()
}
/* Contract */
const pdfContract = ref<any>(null)
const pdfUrl = ref<null | string>(null)
const loadPDFContract = async () => {
  try {
    pdfContract.value = await fetchPDFContract(props.contractId)
    pdfUrl.value = window.URL.createObjectURL(new Blob([pdfContract.value], { type: 'application/pdf' }))
  } catch (error) {
    pdfContract.value = null
  }
}
await loadPDFContract()
const openContractPdf = () => {
  window.open(pdfUrl.value!, '_blank')
}

const showSignContractModal = ref(false)
const justSigned = ref(false)
const onContractSigned = async () => {
  showSignContractModal.value = false
  justSigned.value = true
  if (otherUserSignedAt.value) {
    celebrate()
    toast(t('contractSummary.toasts.contractFinalized'), 'success')
  } else {
    toast(t('contractSummary.toasts.contractSigned'), 'success')
  }
  await loadPDFContract()
  contract.value = await fetchContract(props.contractId)
}

const currentUserSignedAt = computed(() => {
  if (currentUserIsCounterpart.value) {
    return contract.value.counterpartSignerSignedDate
  }
  return contract.value.signerSignedDate
})

const otherUserSignedAt = computed(() => {
  if (currentUserIsCounterpart.value) {
    return contract.value.signerSignedDate
  }
  return contract.value.counterpartSignerSignedDate
})
</script>
