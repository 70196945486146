<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      fill="none"
      stroke="currentColor"
      stroke-width="4"
      cx="25"
      cy="25"
      r="21"
    />
    <path
      stroke="currentColor"
      fill="currentColor"
      d="M 26.732 14.853 C 26.199 15.416 25.517 15.712 24.746 15.712 C 23.976 15.712 23.324 15.446 22.761 14.853 C 22.227 14.29 21.931 13.638 21.931 12.897 C 21.931 12.156 22.198 11.504 22.761 10.941 C 23.324 10.378 23.976 10.082 24.746 10.082 C 25.517 10.082 26.169 10.348 26.732 10.941 C 27.295 11.504 27.562 12.156 27.562 12.897 C 27.562 13.638 27.295 14.29 26.732 14.853 Z"
    />
    <path
      stroke="currentColor"
      fill="none"
      stroke-width="4"
      d="M 24.713 39.396 L 24.713 20.214"
    />
  </svg>
</template>
