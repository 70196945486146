<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style="fill: #c20f2f"
      d="M 79.698 45.268 L 33.023 45.268 L 33.023 79.397 C 35.332 79.797 37.641 80 40.05 80 C 60.326 80.1 77.088 64.943 79.698 45.268 Z"
    />
    <path
      style="fill: #c20f2f"
      d="M 0.4 45.268 C 2.108 58.419 10.239 69.561 21.481 75.482 L 21.481 45.268 L 0.4 45.268 Z"
    />
    <path
      style="fill: #c20f2f"
      d="M 79.598 33.726 C 76.486 14.554 60.025 0 40.05 0 C 37.641 0 35.332 0.201 33.023 0.603 L 33.023 33.626 L 79.598 33.626 Z"
    />
    <path
      style="fill: #c20f2f"
      d="M 21.38 4.618 C 10.439 10.339 2.509 21.079 0.502 33.726 L 21.38 33.726 L 21.38 4.618 Z"
    />
    <path
      style="fill: #ffffff"
      d="M 79.698 45.268 C 79.897 43.563 80.1 41.756 80.1 40.05 C 80.1 37.942 79.897 35.732 79.598 33.726 L 33.023 33.726 L 33.023 0.603 C 28.908 1.305 24.993 2.71 21.481 4.618 L 21.481 33.726 L 0.502 33.726 C 0.201 35.732 0 37.841 0 40.05 C 0 41.857 0.1 43.563 0.4 45.268 L 21.481 45.268 L 21.481 75.482 C 25.094 77.388 28.908 78.694 33.023 79.497 L 33.023 45.368 L 79.698 45.368 Z"
    />
  </svg>
</template>
