<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      d="M13 3 3 13v29h31V3z"
      class="st0"
    />
    <path
      id="Path_5387_00000093141198675718908550000013673687873916379317_"
      d="M3 13h10V3L3 13z"
      style="fill: #8289c4"
    />
    <circle
      cx="10.734"
      cy="18.386"
      r=".956"
      class="st2"
    />
    <path
      d="M12.292 23.767v2.292l-.843.843.962.962-.965.966.847.798v1.385l-1.599 1.636-1.598-1.636v-7.276a4.017 4.017 0 0 1 1.638-7.682 4.016 4.016 0 0 1 4.015 4.015c0 1.66-1.02 3.085-2.457 3.697zM23.5 7.5v30l14-2v-26zM23.5 9.5h-5v26h5M37.5 35.5h2M18.5 35.5H7M26.5 21.5V25"
      class="st2"
    />
  </svg>
</template>
<style lang="scss" scoped>
.st0 {
  fill: #b5b9dc;
}

.st2 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
