<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2.92 8.96 39.15 27.43"
  >
    <g>
      <g>
        <g>
          <rect
            x="5.53125"
            y="20.5321"
            class="st0"
            width="9"
            height="12.9679"
          />
          <circle
            class="st0"
            cx="10.03125"
            cy="23.32124"
            r="1.28838"
          />
          <circle
            class="st0"
            cx="10.03125"
            cy="28.63052"
            r="2.31759"
          />
        </g>
        <g>
          <rect
            x="30.4375"
            y="20.5321"
            class="st0"
            width="9.03125"
            height="12.9679"
          />
          <circle
            class="st0"
            cx="34.95312"
            cy="23.32124"
            r="1.28838"
          />
          <circle
            class="st0"
            cx="34.95312"
            cy="28.63052"
            r="2.31759"
          />
        </g>
        <polyline
          class="st0"
          points="41.57539,35.89231 41.57539,33.48174 3.42461,33.48174 3.42461,35.89231   "
        />
      </g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <line
                  class="st0"
                  x1="8.4622"
                  y1="11.82696"
                  x2="10.68415"
                  y2="20.53207"
                />
                <line
                  class="st0"
                  x1="24.25842"
                  y1="13.21344"
                  x2="9.69363"
                  y2="10.59552"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <line
                  class="st0"
                  x1="36.5378"
                  y1="11.82696"
                  x2="34.31584"
                  y2="20.5321"
                />
                <line
                  class="st0"
                  x1="20.74158"
                  y1="13.21344"
                  x2="35.30637"
                  y2="10.59552"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          class="st0"
          d="M32.67802,20.57641c-1.3493-4.33815-5.39577-7.48797-10.17792-7.48797    c-4.76567,0-8.80072,3.12815-10.16388,7.44316"
        />
      </g>
      <polyline
        class="st0"
        points="34.1721,9.46124 35.86394,11.15309 37.67208,12.96124  "
      />
      <polyline
        class="st0"
        points="10.81402,9.46124 9.12218,11.15309 7.31404,12.96124  "
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}
</style>
