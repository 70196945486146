<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1160 93"
  >
    <switch>
      <g>
        <g transform="translate(.5)">
          <path
            d="m3.5 0h1152c2.2 0 4 1.8 4 4v89h-1160v-89c0-2.2 1.8-4 4-4z"
            fill="#E7D2C7"
          />
        </g>

        <defs>
          <path
            id="a"
            d="m4 0h1152c2.2 0 4 1.8 4 4v89h-1160v-89c0-2.2 1.8-4 4-4z"
          />
        </defs>
        <clipPath id="b">
          <use xlink:href="#a" />
        </clipPath>

        <g
          transform="translate(.5)"
          clip-path="url(#b)"
        >
          <g
            transform="translate(-285.58 -28.624)"
            fill="#5DBFA2"
          >
            <path
              d="m622.1 97c-22.9 3.2-45.8 6.4-68.6 9.6-7.1-10.5-19.5-23.1-25.3-22.4l-38 4.3c-3.5-10-22.1-32.7-30-31.6-28.4 3.8-54.9 10.9-81.6 17.8-5.6-5.7-11.3-9.8-14.5-9.2l-28.3 5.5c-6.7-10.3-17.6-23.9-24.3-22.4-16.9 3.8-34 7-51.4 9.4-6.9-8.2-15.4-15.5-20-15-62.9 6.4-119.5 24.3-175.2 42.2-4.1 1.3 1.9 11.2 9.6 20.4-24.2 6.5-48.6 12.5-73.9 17.3-5 0.9 18.6 38.2 29.5 36.2 37.8-7.1 73.7-17.1 109.6-27.1 4.4 11 24.1 31.7 29.6 30.1 21-5.9 42.9-6.2 65-5.4 31.6 9.9 74.3 6 107.9 3.3 15.3-1.3 30.5-3 45.5-5 1.6 1.4 3.3 2.7 5 3.9 13.5 9.5 38.4 6 56.6 4.6 7.7-0.6 15.3-1.2 23-1.9 4.3 4.2 8.6 6.9 11.6 6.3 3.1-0.6 6.1-1.3 9.1-1.9 6.1 8.4 13.6 16 19.3 15.2 25.7-3.7 51.4-7.3 77.1-11 1.5-0.2-0.6-5.6-4.5-12.1 20.5-9.4 47.1-14.5 66.6-25 7.2-3.8-20.4-37.4-29.4-36.1z"
            />
            <path
              d="m1162.6 94.5c25.6-3.6 51.3-7.2 76.9-10.7 8 11.8 21.9 25.9 28.3 25.1l42.6-4.8c3.9 11.2 24.7 36.6 33.6 35.5 31.8-4.2 61.6-12.3 91.4-20 6.3 6.4 12.7 11 16.3 10.3l31.7-6.2c7.5 11.6 19.8 26.8 27.2 25.1 18.9-4.3 38.1-7.8 57.6-10.5 7.7 9.2 17.2 17.3 22.4 16.8 70.5-7.2 133.9-27.2 196.3-47.3 4.5-1.5-2.1-12.6-10.8-22.8 27.1-7.3 54.5-14 82.8-19.3 5.6-1-20.8-42.8-33-40.5-42.3 7.9-82.6 19.2-122.8 30.3-5-12.3-27-35.5-33.2-33.8-23.5 6.6-48.1 7-72.9 6-35.5-11.1-83.3-6.8-120.9-3.7-17.2 1.4-34.1 3.3-51 5.6-1.8-1.5-3.6-3-5.6-4.4-15.1-10.7-43-6.7-63.4-5.1-8.6 0.6-17.2 1.3-25.7 2.1-4.8-4.7-9.6-7.8-13-7.1s-6.8 1.4-10.2 2.1c-6.8-9.4-15.2-17.9-21.6-17-28.8 4.1-57.6 8.2-86.4 12.3-1.7 0.2 0.7 6.3 5.1 13.6-22.9 10.6-52.8 16.3-74.6 28-8.2 4.1 22.8 41.8 32.9 40.4z"
            />
          </g>
        </g>
      </g>
    </switch>
  </svg>
</template>
