<template>
  <svg
    version="1.1"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-miterlimit="10"
  >
    <path
      d="M 29.753 22.61 L 29.753 34.01 C 29.753 34.81 29.153 35.41 28.353 35.41 L 7.153 35.41 C 6.353 35.41 5.753 34.81 5.753 34.01 L 5.753 5.81 C 5.753 5.01 6.353 4.41 7.153 4.41 L 28.353 4.41 C 29.153 4.41 29.753 5.01 29.753 5.81 L 29.753 10.51"
    />
    <path d="M 10.053 18.11 L 18.653 26.71 L 35.453 9.91" />
  </svg>
</template>
