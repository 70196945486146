<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="17.403"
      cy="8.114"
      class="st0"
      rx="5.97"
      ry="7.051"
    />
    <path
      d="m28.392 33.48-.028-6.274c0-4.157.392-7.205-1.777-9.097-1.652-1.443-6.912-2.945-9.181-2.945M23.881 33.481l.443-7.339"
      class="st0"
    />
    <path
      d="M12.956 28.136c.916-1.009 1.637-1.768 1.794-2.498.08-.372-.752-1.401-.683-1.766.306-1.633 1.281-4.614 1.281-4.614.254-1.02.798-1.168 1.121-1.084.323.084.645.406.339 1.52-.106.387-.631 2.145-.631 2.145.685.035 2.654.529 2.72 1.373.065.845-.976 2.33-1.367 2.638 0 0-2.432 7.84-5.76 8.083-1.818.134-2.922-3.692-3.424-5.13-.518-1.483-2.231-8.802-.062-10.694 1.653-1.443 6.912-2.945 9.181-2.945"
      style="fill: none; stroke: currentColor; stroke-width: 0.8; stroke-linecap: round; stroke-miterlimit: 10"
    />
  </svg>
</template>
<style>
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
