<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="7.03 9.04 30.55 26.92"
  >
    <g>
      <g>
        <polyline
          class="st0"
          points="29.4895,32.31232 29.4895,35.46192 7.53125,35.46192 7.53125,11.46875 12.81429,11.46875   "
        />
        <polyline
          class="st0"
          points="23.59777,11.46875 29.4895,11.46875 29.4895,17.13645   "
        />
        <polygon
          class="st0"
          points="20.49191,11.46874 20.49191,9.53808 16.5625,9.53808 16.5625,11.46874 12.52083,11.46874     12.52083,14.53125 24.59777,14.53125 24.59777,11.46874   "
        />
      </g>
      <g>
        <line
          class="st0"
          x1="13.14069"
          y1="17.53125"
          x2="22.12348"
          y2="17.53125"
        />
        <line
          class="st0"
          x1="13.14069"
          y1="21.5295"
          x2="20.38672"
          y2="21.5295"
        />
        <line
          class="st0"
          x1="13.14069"
          y1="25.5"
          x2="21.90156"
          y2="25.5"
        />
        <line
          class="st0"
          x1="13.14069"
          y1="29.47358"
          x2="21.07916"
          y2="29.47358"
        />
        <circle
          class="st1"
          cx="10.97264"
          cy="17.5203"
          r="0.58986"
        />
        <circle
          class="st1"
          cx="10.97264"
          cy="21.5295"
          r="0.58986"
        />
        <circle
          class="st1"
          cx="10.97264"
          cy="25.51095"
          r="0.58986"
        />
        <circle
          class="st1"
          cx="10.97264"
          cy="29.49547"
          r="0.58986"
        />
      </g>
      <g>
        <circle
          class="st0"
          cx="29.4895"
          cy="24.72438"
          r="7.58794"
        />
      </g>
      <polyline
        class="st0"
        points="34.30027,21.5306 28.10608,27.72479 24.67872,24.29744  "
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
.st0 {
  fill: none;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #1d1d1b;
}
</style>
