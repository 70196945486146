<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="7.11 11 30.81 23.11"
  >
    <g>
      <g>
        <line
          class="st0"
          x1="12.57226"
          y1="15.53125"
          x2="12.57226"
          y2="11.5"
        />
        <line
          class="st0"
          x1="32.42892"
          y1="15.53125"
          x2="32.42892"
          y2="11.5"
        />
      </g>
      <line
        class="st1"
        x1="23.65009"
        y1="19.08344"
        x2="37.42828"
        y2="19.08344"
      />
      <line
        class="st1"
        x1="7.61574"
        y1="19.08344"
        x2="20.73262"
        y2="19.08344"
      />
      <g>
        <g>
          <circle
            class="st2"
            cx="11.51373"
            cy="22.47953"
            r="0.72997"
          />
          <circle
            class="st2"
            cx="16.52083"
            cy="22.47953"
            r="0.72997"
          />
        </g>
        <g>
          <circle
            class="st2"
            cx="11.51373"
            cy="26.45312"
            r="0.72997"
          />
          <circle
            class="st2"
            cx="16.52083"
            cy="26.45312"
            r="0.72997"
          />
        </g>
        <g>
          <circle
            class="st2"
            cx="11.51373"
            cy="30.46875"
            r="0.72997"
          />
          <circle
            class="st2"
            cx="16.52083"
            cy="30.46875"
            r="0.72997"
          />
        </g>
      </g>
      <g>
        <g>
          <circle
            class="st2"
            cx="28.52414"
            cy="22.47953"
            r="0.72997"
          />
          <circle
            class="st2"
            cx="33.53125"
            cy="22.47953"
            r="0.72997"
          />
        </g>
        <g>
          <circle
            class="st2"
            cx="28.52414"
            cy="26.45312"
            r="0.72997"
          />
          <circle
            class="st2"
            cx="33.53125"
            cy="26.45312"
            r="0.72997"
          />
        </g>
        <g>
          <circle
            class="st2"
            cx="28.52414"
            cy="30.46875"
            r="0.72997"
          />
          <circle
            class="st2"
            cx="33.53125"
            cy="30.46875"
            r="0.72997"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="st3"
            d="M24.27354,33.61347H36.5c0.51203,0,0.9271-0.41508,0.9271-0.92711V14.46148     c0-0.51203-0.41508-0.9271-0.9271-0.9271H24.35453l0,0l1.13605,3.23619l-2.15088,2.10547l2.47168,2.60645l-3.28076,3.4668     l2.89111,3.22949l-2.06006,1.6875L24.27354,33.61347z"
          />
          <path
            class="st3"
            d="M22.15017,33.61347l-1.00335-4.51869l1.39111-1.13867l-2.7251-3.04395l3.24365-3.42773l-2.52246-2.66113     l2.39502-2.34375l-1.5539-2.94517H8.54167c-0.51203,0-0.92711,0.41508-0.92711,0.9271v18.22488     c0,0.51203,0.41508,0.92711,0.92711,0.92711H22.15017z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts"></script>
<style scoped>
.st0 {
  stroke: currentColor;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st1 {
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.st2 {
  fill: currentColor;
}

.st3 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}
</style>
