<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <g>
          <path
            d="M39 20c0 10.5-8.5 19-19 19S1 30.5 1 20 9.5 1 20 1s19 8.5 19 19"
            style="fill: #f0d87b"
          />
          <path
            d="M39.4 12.6V8.3h-4.6M5.4 8.3H.8v4.4M.8 28.1v4.1h4.6M34.8 32.2h4.6v-4.1"
            style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-linecap: square; stroke-miterlimit: 10"
          />
          <path
            d="M25.6 14.5h4.7l-.1-2.7h-4.5l-.1 2.7z"
            style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-linejoin: round"
          />
          <path
            d="M28 38c.7 0 1.3-.6 1.3-1.3l1-22.2h-4.7l1 22.2c.1.7.7 1.3 1.4 1.3zM30.2 11.8c.5-1 .8-2.1.9-3.3 0-3.7-3.1-7.1-3.1-7.1-.6 3.1-3.1 4.3-3.1 7.1.1 1.1.4 2.3.9 3.3"
            style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-miterlimit: 10"
          />
          <path
            d="m12.9 31.1-3.5-6.2 5.3-6.8 5.3 6.8-3.5 6.2"
            style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-linejoin: round"
          />
          <circle
            cx="14.6"
            cy="24.9"
            r="1.7"
            style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-linejoin: round"
          />
          <path
            d="M14.6 27v4M17.4 34.2h-5.5l.9-3.2h3.8l.8 3.2zM9.5 15.9 15 4.5l4.8 11.2M8 15.9h3M12.1 4.5H15M11.4 11.8h6.8M18.3 15.9h3"
            style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-linejoin: round"
          />
        </g>
      </g>
    </switch>
  </svg>
</template>
