import { User, UserManager } from 'oidc-client-ts'
import { storeToRefs } from 'pinia'
import { generateRandomString } from '@/services/StringService'
import { useAuthStore } from '@/stores/auth-store'

export const userManager = new UserManager({
  authority: import.meta.env.VITE_IDENTITY_SERVER_URL,
  client_id: 'js',
  redirect_uri: `${window.location.origin}/login-callback`,
  automaticSilentRenew: true,
  response_type: 'code',
  post_logout_redirect_uri: `${window.location.origin}/logout`,
  response_mode: 'query',
  scope: 'scope2',
})

export type OAuthState = {
  redirectUrl: string
}

export const goToLogin = async (
  state: OAuthState | undefined = undefined,
  emailValidationToken: string | undefined = undefined,
) => {
  console.log({
    state,
    extraQueryParams: emailValidationToken ? { id: emailValidationToken } : undefined,
  })
  await userManager.signinRedirect({
    state,
    extraQueryParams: emailValidationToken ? { id: emailValidationToken } : undefined,
  })
}
export const goToRegister = (state?: OAuthState, emailValidationUuid: string | undefined = undefined) => {
  if (!state && !emailValidationUuid) {
    window.location.href = `${import.meta.env.VITE_IDENTITY_SERVER_URL}/Account/Register?returnUrl=${
      window.location.origin
    }/create-user-callback`
    return
  }
  if (emailValidationUuid && !state) {
    window.location.href = `${
      import.meta.env.VITE_IDENTITY_SERVER_URL
    }/Account/Register?invitationId=${emailValidationUuid}&returnUrl=${window.location.origin}/create-user-callback`
    return
  }
  const key = saveState(state!)
  window.location.href = `${
    import.meta.env.VITE_IDENTITY_SERVER_URL
  }/Account/Register?invitationId=${emailValidationUuid}&returnUrl=${
    window.location.origin
  }/create-user-callback?state=${key}`
}

const saveState = (state: OAuthState): string => {
  const key = generateRandomString()
  localStorage.setItem(key, JSON.stringify(state))
  return key
}

export const fetchAccessToken = async (): Promise<User> => {
  const { token } = storeToRefs(useAuthStore())
  const user = await userManager.signinCallback()
  token.value = user?.access_token || null
  if (!user) {
    throw new Error('User not found')
  }
  return user
}

export const fetchUser = async () => {
  const user = await userManager.getUser()
  return user
}

export const goToLogOut = async () => {
  await userManager.signoutRedirect()
}
