<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.873 17.399c0 2.327-1.598 4.213-3.568 4.213s-3.568-1.886-3.568-4.213.72-4.213 3.568-4.213c2.649 0 3.568 1.886 3.568 4.213z"
      class="st0"
    />
    <path
      d="M17.32 22.555c1.332-.532 3.066-.943 3.983-.943M27.547 27.682h3.86l.023-8.353V1.399H19.684L13.58 7.54v2.987"
      class="st0"
    />
    <path
      d="M21.267 21.612c1.357 0 4.214.898 5.201 1.76 1.296 1.13 1.062 2.952 1.062 5.436v3.825M5.699 34.747l-.342-5.661M10.696 20.617c2.544 0 4.606-2.435 4.606-5.439s-.929-5.439-4.606-5.439-4.605 2.435-4.605 5.44 2.062 5.438 4.605 5.438z"
      class="st0"
    />
    <path
      d="m19.391 34.747-.021-4.84c0-3.207.302-5.558-1.37-7.018-1.276-1.113-5.55-2.272-7.302-2.272h-.004c-1.75 0-5.808 1.16-7.083 2.272-1.673 1.46-1.37 3.811-1.37 7.017l-.022 4.841M16.035 34.747l.342-5.661M19.684 1.399V7.54H13.58"
      class="st0"
    />
  </svg>
</template>
<style>
.st0 {
  fill: none;
  stroke: #1e1f1d;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}
</style>
