<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <switch>
      <g>
        <path
          d="M39 20c0 10.5-8.5 19-19 19S1 30.5 1 20 9.5 1 20 1s19 8.5 19 19"
          style="fill: #97cbb5"
        />
        <path
          d="M4.5 5.5h21v27h-21z"
          style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M25.5 7.5h2v27h-21v-2"
          style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M30.6 12.5h2.8M32.8 30.2h-1.5c-.4 0-.8-.3-.8-.8V11.5l1.5-3 1.6 3v17.9c-.1.5-.4.8-.8.8z"
          style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-linejoin: round"
        />
        <path
          d="M30.4 27.5h3.1M8.9 22.7c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8z"
          style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <circle
          cx="17.7"
          cy="24.5"
          r="1.8"
          style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-miterlimit: 10"
        />
        <path
          d="M19.5 24.5v-14l-7-1v13M7 20.5h16M7 24.5h16M6.9 28.5H23M7 12.5h16M7 16.5h16"
          style="fill: none; stroke: #161615; stroke-width: 0.8; stroke-miterlimit: 10"
        />
      </g>
    </switch>
  </svg>
</template>
