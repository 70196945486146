<template>
  <div
    class="fp-slide-in tw-p-[26px]"
    :class="[active ? 'tw-w-[420px]' : 'tw-w-0']"
  >
    <div
      class="tw-w-[420px] tw-max-w-full tw-transition-opacity"
      :class="active ? 'tw-opacity-100' : 'tw-opacity-0'"
    >
      <h3 class="tw-pt-[34px] tw-text-lg tw-flex tw-items-center tw-mb-6">
        <ContractQuestionMark class="tw-h-[42px] tw-mr-4" />
        {{ heading }}
      </h3>
      <div class="tw-pt-0 tw-leading-7">
        <slot>
          <p>
            {{ body }}
          </p>
        </slot>
      </div>
      <div
        v-if="showCloseButton"
        class="tw-cursor-pointer hover:tw-opacity-80 tw-transition-opacity tw-flex tw-items-center tw-mt-6 tw-leading-none"
        @click="close"
      >
        <XMark class="tw-w-[20px] tw-h-[20px] tw-mr-2" />
        <p class="tw-mt-[2px] tw-uppercase">{{ $t('global.close') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import ContractQuestionMark from '@/svg/ContractQuestionMark.vue'
import XMark from '@/svg/XMark.vue'

export interface FpSlideInProps {
  heading?: string
  body?: string
  showCloseButton?: boolean
}

withDefaults(defineProps<FpSlideInProps>(), {
  heading: undefined,
  body: undefined,
  showCloseButton: true,
})

const emit = defineEmits<{
  close: []
}>()

const active = ref(false)

/**
 * A bit hacky, but necessary to make the slide in animation work
 */
setTimeout(() => {
  active.value = true
}, 1)

const close = () => {
  active.value = false
  setTimeout(() => {
    emit('close')
  }, 200)
}
</script>

<style scoped lang="scss">
.fp-slide-in {
  position: fixed;
  top: 60px;
  right: 0;
  overflow-y: scroll;
  height: calc(100% - 60px);
  background-color: #f5f5f5;
  z-index: 200;
  transition: width 150ms ease-in-out;
  box-shadow: 0 3px 10px #00000030;
  max-width: 100vw;
}
</style>
