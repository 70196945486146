<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:serif="http://www.serif.com/"
    version="1.1"
    xml:space="preserve"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 2"
    viewBox="2.33 4.95 54.46 50.1"
  >
    <g transform="matrix(1,0,0,1,32.5,46.4286)">
      <g transform="matrix(1,0,0,1,-43.5656,-87.8571)">
        <path
          d="M33.692,68.666L33.781,68.666"
          style="fill: none"
        />
      </g>
      <g transform="matrix(1,0,0,1,-43.5656,-87.8571)">
        <path
          d="M35.462,70.947C35.526,70.947 35.498,71.224 35.498,71.284C35.498,71.715 35.28,72.083 34.994,72.406C34.993,72.407 34.593,72.801 34.671,72.84C35.241,73.125 36.518,72.551 36.999,72.369C37.467,72.191 37.924,71.965 38.382,71.764C38.729,71.612 38.741,71.617 39.014,71.427C39.719,70.935 39.945,70.112 38.767,70.042C37.832,69.986 36.358,69.794 35.489,70.218C35.339,70.291 35.354,70.724 35.354,70.876C35.354,71.601 35.348,72.227 35.58,72.922"
          style="fill: none"
        />
      </g>
      <g transform="matrix(1,0,0,1,-9.95817,-41.3783)">
        <g id="Group_1377">
          <g>
            <g id="Group_1377-2">
              <path
                d="M5.698,30.108L12.6,49.5C12.6,49.5 31.9,47.8 33.6,43C33.7,42.8 33.7,42.6 33.7,42.5L33.7,42.3C33.7,41.9 33.6,41.7 33,41.4C24.9,37 7.9,36 7.9,36L11.4,11.476L0,14.1C0,14.1 1.028,16.988 1.756,19.035"
                style="fill: none; fill-rule: nonzero; stroke: black; stroke-width: 1px"
              />
              <path
                id="Path_4801"
                d="M13,0.4L7.9,36C7.9,36 24.8,37.1 33,41.4C33.6,41.7 33.7,42 33.7,42.4L33.7,41.1C33.7,38.8 33.8,8.5 33.7,6.7C33.7,5.2 33.9,4.1 31.3,2.8C26.1,0.3 13,0.4 13,0.4Z"
                style="fill: none; fill-rule: nonzero; stroke: black; stroke-width: 1px; stroke-linecap: butt"
              />
              <path
                id="Path_4802"
                d="M16.5,16.4C21,15.9 25.6,15.4 30.1,14.8C30.9,14.7 31.5,12.2 30.6,12.4C26.1,12.9 21.6,13.4 17,14C16.2,14.1 15.6,16.5 16.5,16.4"
                style="fill-rule: nonzero"
              />
              <path
                d="M14.6,14.6C14.6,14.5 14.5,14.5 14.5,14.4C14.4,14.3 14.3,14.3 14.2,14.3L13.9,14.3C13.8,14.3 13.8,14.3 13.7,14.4C13.6,14.5 13.5,14.6 13.4,14.7C13.3,14.8 13.2,15 13.2,15.1C13.1,15.3 13.1,15.4 13,15.6L13,15.9C13,16.1 13,16.2 13.1,16.4L13.2,16.6C13.3,16.7 13.4,16.7 13.5,16.7L13.8,16.7C13.9,16.7 13.9,16.7 14,16.6C14.1,16.5 14.2,16.4 14.3,16.3C14.4,16.2 14.5,16 14.5,15.9C14.6,15.7 14.6,15.6 14.7,15.4L14.7,15.1C14.7,14.9 14.7,14.7 14.6,14.6Z"
                style="fill-rule: nonzero"
              />
              <g>
                <path
                  d="M11.9,27.2L11.8,27.2L11.9,27.2Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M13,25C13,24.9 12.9,24.9 12.9,24.8C12.8,24.7 12.7,24.7 12.6,24.7L12.3,24.7C12.2,24.7 12.2,24.7 12.1,24.8C12,24.9 11.9,25 11.8,25.1C11.7,25.2 11.6,25.4 11.6,25.5C11.5,25.7 11.5,25.8 11.4,26L11.4,26.3C11.4,26.5 11.4,26.6 11.5,26.8L11.6,27C11.7,27.1 11.8,27.1 11.9,27.1L12.2,27.1C12.3,27.1 12.3,27.1 12.4,27C12.5,26.9 12.6,26.8 12.7,26.7C12.8,26.6 12.9,26.4 12.9,26.3C13,26.1 13,26 13.1,25.8L13.1,25.5C13.1,25.4 13.1,25.2 13,25Z"
                  style="fill-rule: nonzero"
                />
              </g>
              <g>
                <path
                  d="M13.8,19.8C13.8,19.7 13.7,19.7 13.7,19.6C13.6,19.5 13.5,19.5 13.4,19.5L13.1,19.5C13,19.5 13,19.5 12.9,19.6C12.8,19.7 12.7,19.8 12.6,19.9C12.5,20 12.4,20.2 12.4,20.3C12.3,20.5 12.3,20.6 12.2,20.8L12.2,21.1C12.2,21.3 12.2,21.4 12.3,21.6L12.4,21.8C12.5,21.9 12.6,21.9 12.7,21.9L13,21.9C13.1,21.9 13.1,21.9 13.2,21.8C13.3,21.7 13.4,21.6 13.5,21.5C13.6,21.4 13.7,21.2 13.7,21.1C13.8,20.9 13.8,20.8 13.9,20.6L13.9,20.3C13.8,20.1 13.8,19.9 13.8,19.8Z"
                  style="fill-rule: nonzero"
                />
              </g>
              <path
                d="M12.1,30.2C12.1,30.1 12,30.1 12,30C11.9,29.9 11.8,29.9 11.7,29.9L11.4,29.9C11.3,29.9 11.3,29.9 11.2,30C11.1,30.1 11,30.2 10.9,30.3C10.8,30.4 10.7,30.6 10.7,30.7C10.6,30.9 10.6,31 10.5,31.2L10.5,31.5C10.5,31.7 10.5,31.8 10.6,32L10.7,32.2C10.8,32.3 10.9,32.3 11,32.3L11.3,32.3C11.4,32.3 11.4,32.3 11.5,32.2C11.6,32.1 11.7,32 11.8,31.9C11.9,31.8 12,31.6 12,31.5C12.1,31.3 12.1,31.2 12.2,31L12.2,30.7C12.2,30.5 12.2,30.3 12.1,30.2Z"
                style="fill-rule: nonzero"
              />
              <path
                id="Path_4807"
                d="M16,21.6C21.1,21.1 26.1,20.5 31.1,19.7C31.9,19.6 32.5,17.1 31.6,17.3C26.6,18.1 21.5,18.7 16.5,19.2C15.6,19.2 15,21.7 16,21.6"
                style="fill-rule: nonzero"
              />
              <path
                id="Path_4808"
                d="M14.9,27.1C19.7,26.7 24.5,26.1 29.3,25.5C30.1,25.4 30.7,22.9 29.8,23.1C25,23.7 20.2,24.3 15.4,24.7C14.5,24.7 13.9,27.2 14.9,27.1"
                style="fill-rule: nonzero"
              />
              <path
                id="Path_4809"
                d="M13.8,32.2C19,31.9 24.2,31.4 29.4,30.8C30.2,30.7 30.8,28.2 29.9,28.4C24.7,29.1 19.5,29.6 14.3,29.8C13.5,29.8 12.8,32.2 13.8,32.2"
                style="fill-rule: nonzero"
              />
            </g>
          </g>
        </g>
      </g>
      <g transform="matrix(1,0,0,1,-11.2506,-41.4286)">
        <g transform="matrix(1.20137,0,0,1.20137,-19.1034,13.0343)">
          <path
            d="M0.57,0.56L4.01,9.96L21.46,9.96L0.57,0.56Z"
            style="
              fill: none;
              fill-rule: nonzero;
              stroke: rgb(2, 2, 0);
              stroke-width: 1px;
              stroke-linecap: butt;
              stroke-miterlimit: 10;
            "
          />
        </g>
        <g transform="matrix(1.20137,0,0,1.20137,-19.1034,13.0343)">
          <path
            d="M0.57,19.36L4.01,9.96L21.46,9.96L0.57,19.36Z"
            style="
              fill: none;
              fill-rule: nonzero;
              stroke: rgb(2, 2, 0);
              stroke-width: 1px;
              stroke-linecap: butt;
              stroke-miterlimit: 10;
            "
          />
        </g>
      </g>
    </g>
  </svg>
</template>
