<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1.51 9.29 41.46 25.68"
  >
    <g>
      <g>
        <polyline
          class="st0"
          points="4.59375,34.46875 4.59375,15.40326 22.5,9.78778 34.46817,14.21708 34.46817,16.81524   "
        />
        <line
          class="st0"
          x1="22.5"
          y1="9.78778"
          x2="22.5"
          y2="19.18386"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              class="st1"
              d="M38.79101,16.52478l-19.04962,2.67595c-0.11957,1.25317-0.94311,2.33861-2.12007,2.78921l0.92982,6.61923      c1.40163,0.12159,2.60151,1.12345,2.94365,2.51806l18.89645-2.65443c-0.05437-1.41096,0.7937-2.68435,2.07118-3.20489      l-0.93468-6.65384C40.3016,18.48139,39.24252,17.67467,38.79101,16.52478z"
            />
            <line
              class="st1"
              x1="26.85299"
              y1="28.22107"
              x2="27.14964"
              y2="30.33288"
            />
            <line
              class="st1"
              x1="26.10856"
              y1="22.92161"
              x2="26.50237"
              y2="25.72513"
            />
            <line
              class="st1"
              x1="25.47278"
              y1="18.39563"
              x2="25.76887"
              y2="20.50341"
            />

            <rect
              x="27.94824"
              y="19.53013"
              transform="matrix(0.99028 -0.13911 0.13911 0.99028 -2.65646 4.71952)"
              class="st1"
              width="8.97184"
              height="3.66666"
            />
            <line
              class="st2"
              x1="28.43638"
              y1="25.68612"
              x2="36.04214"
              y2="24.61772"
            />
            <line
              class="st2"
              x1="28.77339"
              y1="27.55104"
              x2="34.40736"
              y2="26.75962"
            />
          </g>
        </g>
      </g>
      <line
        class="st0"
        x1="22.5"
        y1="30.65237"
        x2="22.5"
        y2="34.46875"
      />
      <line
        class="st0"
        x1="34.46817"
        y1="28.98805"
        x2="34.46817"
        y2="34.46875"
      />
      <line
        class="st3"
        x1="1.98827"
        y1="34.46875"
        x2="38.40849"
        y2="34.46875"
      />
      <polyline
        class="st0"
        points="7.46875,34.46875 7.46875,23.28229 14.53125,21.88302 14.53125,34.46875  "
      />
    </g>
  </svg>
</template>
<style scoped lang="scss">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: square;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.96572;
  stroke-miterlimit: 10;
}
</style>
