<template>
  <div class="tw-border tw-border-greyBorder tw-shadow tw-rounded-lg">
    <div
      class="tw-h-12 tw-overflow-clip tw-rounded-t-[0.425rem] tw-flex tw-items-center tw-text-md tw-px-4 tw-border-greyBorder tw-uppercase"
      :class="[
        status === 'AwaitingMe'
          ? 'tw-bg-dangerRed tw-text-light'
          : status === 'AwaitingOther'
          ? 'tw-bg-successGreen tw-text-light'
          : 'tw-bg-bgGreyDarker',
        response?.data.length === 0 ? 'tw-rounded-b-[0.425rem]' : 'tw-border-b',
      ]"
    >
      <IconPicker
        :icon="
          status === 'AwaitingMe'
            ? 'me'
            : status === 'AwaitingOther'
            ? 'you'
            : status === 'Canceled'
            ? 'x_mark_circle'
            : status === 'Signed'
            ? 'check_mark_circle'
            : 'paper_with_pencil'
        "
        :class="{ 'tw-text-dangerRed': status === 'Canceled', 'tw-text-successGreen': status === 'Signed' }"
        class="tw-h-full tw-py-2 tw-mr-2"
      />
      {{ $t(`dashboard.sectionHeadings.${status}`, { count: response?.pagination?.totalRecords || 0 }) }}
    </div>
    <div
      v-if="showPreloader"
      class="tw-py-10 tw-flex tw-items-center tw-justify-center tw-relative"
    >
      <FpSpinner />
    </div>
    <div v-else-if="response?.data.length">
      <div
        class="tw-flex tw-items-center tw-gap-1 lg:tw-gap-4 tw-px-1 lg:tw-px-5 tw-border-b tw-border-lightGreyBorder tw-text-sm tw-py-0.5 tw-font-medium tw-uppercase"
      >
        <div class="tw-w-4/12 lg:tw-w-4/12" />
        <div class="tw-w-3/12 lg:tw-w-2/12 tw-text-right sm:tw-text-left">
          {{ $t('dashboard.tableHeadings.changed') }}
        </div>
        <div class="tw-hidden lg:tw-block tw-w-2/12">{{ $t('dashboard.tableHeadings.created') }}</div>
        <div class="tw-w-2/12 tw-text-center">{{ $t('dashboard.tableHeadings.duplicate') }}</div>
        <div class="tw-w-3/12 sm:tw-w-2/12 tw-text-center">
          {{
            status === 'AwaitingMe' || status === 'AwaitingOther'
              ? $t('dashboard.tableHeadings.cancel')
              : $t('dashboard.tableHeadings.delete')
          }}
        </div>
      </div>
      <div class="tw-px-1 lg:tw-px-5">
        <div
          v-for="item in response?.data"
          :key="item.id"
          class="tw-flex tw-items-center tw-py-1 tw-gap-1 lg:tw-gap-4 tw-h-14"
        >
          <RouterLink
            :to="`/contracts/${item.id}`"
            class="tw-w-4/12 lg:tw-w-4/12 tw-group"
          >
            <div class="tw-flex tw-items-center">
              <IconPicker
                class="tw-hidden lg:tw-block tw-w-10 tw-h-10 tw-mr-1"
                :icon="getIconForContractType(item.contractTypeId)"
              />
              <div>
                <div class="tw-font-medium group-hover:tw-underline">
                  {{ $t('contractSummary.typeHeading', { type: item.contractType }) }}
                </div>
                <div class="tw-font-extralight group-hover:tw-underline">
                  {{ getOtherPartySection(item) }}
                </div>
              </div>
            </div>
          </RouterLink>
          <div class="tw-w-3/12 lg:tw-w-2/12 tw-text-right sm:tw-text-left">
            {{ dayjs(item.lastChange).format('DD MMM YY') }}
          </div>
          <div class="tw-hidden lg:tw-block tw-w-2/12">
            {{ dayjs(item.created).format('DD MMM YY') }}
          </div>
          <div class="tw-w-2/12">
            <div class="tw-flex tw-justify-center">
              <FpIconPlusWithAccent
                class="tw-h-6 tw-w-6 tw-cursor-pointer hover:tw-opacity-80"
                @click="onDuplicateContract(item)"
              />
            </div>
          </div>
          <div class="tw-w-3/12 sm:tw-w-2/12">
            <div class="tw-flex tw-justify-center">
              <XMarkCircle
                class="tw-w-6 tw-cursor-pointer hover:tw-opacity-80"
                @click="onCancelOrDeleteContract(item)"
              />
            </div>
          </div>
        </div>
        <FpPager
          class="tw-mb-3 tw-mt-4"
          :pagination-info="response!.pagination"
          @update:page="paginate"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { cancelContract, deleteContract, duplicateContract, fetchContractsList } from '@/api/api'
import FpPager from '@/components/FpPager.vue'
import FpSpinner from '@/components/FpSpinner.vue'
import IconPicker from '@/components/IconPicker.vue'
import router from '@/router'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'
import { useToastStore } from '@/stores/toast-store'
import FpIconPlusWithAccent from '@/svg/FpIconPlusWithAccent.vue'
import XMarkCircle from '@/svg/XMarkCircle.vue'
import type { ContractListDto, ContractStatus, ContractTurn, PaginationResponse } from '@/types/api'
import { getIconForContractType } from '@/utils/ContractTypeIconService'

const props = defineProps<{
  status: ContractStatus | ContractTurn
}>()

const emit = defineEmits<{
  totalRecords: [number]
  cancel: []
}>()

const authStore = useAuthStore()

const pageSize = 3

const getFetchPromise = (page: number): (() => Promise<PaginationResponse<ContractListDto>>) => {
  switch (props.status) {
    case 'Draft':
      return () =>
        fetchContractsList({
          status: 'Draft',
          turn: 'AwaitingMe',
          page,
          pageSize,
        })
    case 'AwaitingMe':
      return () =>
        fetchContractsList({
          status: ['Negotiating', 'WaitingForSignature'],
          turn: 'AwaitingMe',
          page,
          pageSize,
        })
    case 'AwaitingOther':
      return () =>
        fetchContractsList({
          status: ['Negotiating', 'Draft', 'WaitingForSignature'],
          turn: 'AwaitingOther',
          page,
          pageSize,
        })
    case 'Signed':
      return () =>
        fetchContractsList({
          status: 'Signed',
          page,
          pageSize,
        })
    case 'Canceled':
      return () =>
        fetchContractsList({
          status: 'Canceled',
          page,
          pageSize,
        })
    default: {
      throw new Error('Invalid status')
    }
  }
}

const showPreloader = ref(false)
const fetch = async (page: number) => {
  showPreloader.value = true
  response.value = await getFetchPromise(page)()
  emit('totalRecords', response.value.pagination.totalRecords)
  showPreloader.value = false
}

onMounted(async () => {
  await fetch(1)
})

const response = ref<PaginationResponse<ContractListDto> | null>(null)
const { locale } = useI18n()
watch(locale, async () => await fetch(1))

const paginate = async (newPage: number) => {
  await fetch(newPage)
}
const getOtherPartySection = (item: ContractListDto): string | null | undefined => {
  if (item.negotiatorId === authStore.user!.id) {
    return item.counterPartyName
  }
  return item.partyName
}

const { t } = useI18n()

const onDuplicateContract = async (contract: ContractListDto) => {
  try {
    const response = await duplicateContract(contract.id)
    toast(t('dashboard.toasts.contractDuplicated'), 'success')
    await router.push(`/contracts/${response.id}`)
  } catch (error) {
    toast(t('global.genericError'), 'error')
  }
}

const { showConfirmModal } = useConfirmModalStore()
const { toast } = useToastStore()
const onCancelOrDeleteContract = (contract: ContractListDto) => {
  if (props.status === 'Draft' || props.status === 'Canceled' || props.status === 'Signed') {
    showConfirmModal({
      heading: t('dashboard.confirms.cancelContract.heading'),
      body: t('dashboard.confirms.cancelContract.body'),
      confirmCallback: async () => {
        try {
          await deleteContract(contract.id)
          toast(t('dashboard.toasts.contractDeleted'), 'success')
          await paginate(1)
        } catch (error) {
          toast(t('global.genericError'), 'error')
        }
      },
    })
    return
  }
  showConfirmModal({
    heading: t('dashboard.confirms.deleteContract.heading'),
    body: t('dashboard.confirms.deleteContract.body'),
    confirmCallback: async () => {
      try {
        await cancelContract(contract.id)
        toast(t('dashboard.toasts.contractCancelled'), 'success')
        emit('cancel')
        await paginate(1)
      } catch (error) {
        toast(t('global.genericError'), 'error')
      }
    },
  })
}
</script>
