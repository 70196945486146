<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    class="svg"
    viewBox="5.3 2.31 17.74 25.34"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
      d="M 11.397 8.376 L 5.797 9.606 L 12.047 27.146 C 12.047 27.146 21.597 26.286 22.457 23.926 C 22.497 23.846 22.537 23.696 22.537 23.516"
    />
    <path
      stroke="currentColor"
      fill="none"
      stroke-miterlimit="10"
      d="M 12.216 2.808 L 9.896 20.308 C 9.896 20.308 18.026 20.908 22.076 23.078 C 22.226 23.148 22.526 23.228 22.526 23.658 L 22.526 22.698 C 22.526 21.558 22.556 6.848 22.526 5.958 C 22.506 5.228 22.616 4.658 21.336 4.038 C 18.756 2.788 12.216 2.808 12.216 2.808 Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.3"
      d="M 17.448 9.857 C 16.268 10.117 15.158 10.607 14.178 11.297 C 13.898 11.487 13.708 11.497 13.568 11.337 C 13.418 11.167 13.478 10.997 13.758 10.807 C 14.978 9.937 16.388 9.377 17.868 9.177 C 18.348 9.117 18.828 9.107 19.308 9.137 C 19.698 9.117 20.058 9.327 20.218 9.677 C 20.338 10.027 20.208 10.417 19.898 10.627 C 19.568 10.907 19.228 11.167 18.868 11.397 C 18.238 11.827 17.588 12.227 16.958 12.657 C 16.738 12.817 16.538 12.997 16.348 13.197 C 16.038 13.497 15.818 13.877 15.728 14.297 C 15.698 14.477 15.518 14.607 15.338 14.577 C 15.328 14.577 15.328 14.577 15.318 14.577 C 15.148 14.557 15.028 14.407 15.048 14.237 C 15.048 14.227 15.048 14.217 15.058 14.207 C 15.168 13.657 15.448 13.157 15.858 12.767 C 16.388 12.257 16.968 11.817 17.608 11.447 C 18.058 11.167 18.498 10.887 18.938 10.597 C 19.108 10.487 19.258 10.357 19.398 10.207 C 19.668 9.927 19.628 9.787 19.218 9.757 C 18.878 9.727 18.528 9.727 18.188 9.757 C 17.938 9.767 17.698 9.817 17.448 9.857 Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.3"
      d="M 15.528 16.487 C 15.608 16.787 15.458 17.107 15.178 17.247 C 14.948 17.307 14.718 17.167 14.658 16.937 C 14.658 16.937 14.658 16.937 14.658 16.927 C 14.578 16.627 14.728 16.307 15.018 16.177 C 15.238 16.127 15.468 16.257 15.528 16.487 C 15.528 16.477 15.528 16.487 15.528 16.487 Z"
    />
  </svg>
</template>
