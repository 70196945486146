import findIndex from 'lodash/findIndex'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ref } from 'vue'

export type FpToastStatus = 'success' | 'error'

export interface FpToast {
  uuid: string
  text: string
  status: FpToastStatus
}

export const useToastStore = defineStore('toast', () => {
  const toasts = ref<FpToast[]>([])

  const toast = (text: string, status: FpToastStatus = 'success', duration = 2500): void => {
    const id = uuid()

    toasts.value.shift()

    toasts.value.push({
      text,
      uuid: id,
      status,
    })

    setTimeout(() => {
      removeToastWithId(id)
    }, duration)
  }

  const removeToastWithId = (id: string): void => {
    const index = findIndex<FpToast>(toasts.value, (toast: FpToast) => toast.uuid === id)

    if (toasts.value[index]) {
      toasts.value.splice(index, 1)
    }
  }

  return {
    toasts,
    toast,
    removeToastWithId,
  }
})
