<template>
  <svg
    version="1.1"
    class="svg"
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="11.41"
      cy="7.216"
      class="st0"
      rx="5.233"
      ry="6.181"
    />
    <path
      d="m4.593 31.691-.189-7.949M11.469 13.396c-2.144 0-7.658 1.42-9.22 2.781C.202 17.965.573 20.843.573 24.77v8.496"
      class="st0"
    />
    <path
      d="M11.412 13.396c2.144 0 5.557.65 7.61 1.451 1.68.657 3.368 1.779 6.121 2.887.554.232 1.272-.971 1.75-.938s2.42.286 3.04.389c.62.103 1.147.32 1.147.924 0 .686-.699.738-1.37.739-.378 0-1.246-.068-1.246-.068.046.647-.203 2.306-.987 2.466-.784.16-1.614-.649-1.977-.947 0 0-4.055-.38-7.127-.706l-1.275 12.999"
      class="st0"
    />
    <ellipse
      cx="27.579"
      cy="8.658"
      class="st1"
      rx="3.47"
      ry="4.341"
    />
    <path
      d="M34.28 29.307v-8.894c0-2.56.228-4.436-1.032-5.601-.961-.888-4.348-1.813-5.667-1.813M21.246 19.993v9.314"
      class="st1"
    />
    <path
      d="M27.615 12.999c-1.319 0-4.376.925-5.336 1.813a2.84 2.84 0 0 0-.773 1.22"
      class="st1"
    />
    <ellipse
      cx="27.579"
      cy="8.658"
      class="st2"
      rx="3.47"
      ry="4.341"
    />
    <path
      d="M34.28 29.307v-8.894c0-2.56.228-4.436-1.032-5.601-.961-.888-4.348-1.813-5.667-1.813M21.246 19.993v9.314"
      class="st2"
    />
    <path
      d="M27.615 12.999c-1.319 0-4.376.925-5.336 1.813a2.84 2.84 0 0 0-.773 1.22"
      class="st2"
    />
  </svg>
</template>
<style>
.st0,
.st1,
.st2 {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.8;
  stroke-miterlimit: 10;
}

.st1,
.st2 {
  stroke-width: 0.4676959;
}

.st2 {
  stroke-width: 0.7772598;
}
</style>
