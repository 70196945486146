<template>
  <div
    class="fp-select"
    :class="[
      {
        'fp-select--focused': focused,
      },
    ]"
  >
    <select
      ref="select"
      class="fp-select__select"
      :value="modelValue === null ? NULL_PLACEHOLDER : modelValue"
      :disabled="disabled"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
    >
      <slot>
        <template v-if="options">
          <option
            v-for="option in options"
            :key="`fp-select-option--${option.value}`"
            :value="option.value === null ? NULL_PLACEHOLDER : option.value"
            :disabled="option.disabled"
          >
            {{ option.label }}
          </option>
        </template>
      </slot>
    </select>
    <div class="fp-select__icon tw-p-0.5">
      <FpAngleDown />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import FpAngleDown from '@/svg/FpAngleDown.vue'

export interface FpSelectOption {
  value: string | number | null
  label: string
  disabled?: boolean
}

export interface FpSelectProps {
  modelValue: string | number | null
  options: FpSelectOption[]
  disabled?: boolean
}

defineProps<FpSelectProps>()

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])

const NULL_PLACEHOLDER = 'NULL_PLACEHOLDER'
const onChange = (event: any) => {
  const target = event.target as HTMLSelectElement
  if (target.value === NULL_PLACEHOLDER) {
    emit('update:modelValue', null)
    return
  }
  emit('update:modelValue', target.value)
}

const focused = ref(false)

const onFocus = () => {
  focused.value = true
  emit('focus')
}
const onBlur = () => {
  focused.value = false
  emit('blur')
}

const select = ref<HTMLSelectElement | null>(null)
const focus = () => {
  select.value!.focus()
}
defineExpose({ focus })
</script>

<style lang="scss" scoped>
@import '@/scss/theme.scss';

.fp-select {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 400;

  &__select {
    margin-right: 1.75rem;
    padding: 8px 16px 5px;
    border: none;
    width: 100%;
    height: 100%;
    appearance: none;
    background: transparent;
    outline: none;
    display: block;
    flex: none;
    box-shadow: none;
    border-radius: 8px;
    cursor: pointer;

    &:disabled {
      opacity: 0.6;
      background-color: #d1d1d1;
      cursor: not-allowed;
    }
  }

  &--focused {
    outline: 1px solid #457fee;
    border: 1px solid #457fee;
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 16px;
    color: #999;
    /* UI Properties */
    opacity: 1;
  }
}
</style>
